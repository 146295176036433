export const mutations = {
  /**
   * @param {UIState} state
   * @param {string} value
   */
  setStickyBannerHeight(state, value) {
    state.stickyBannerHeight = value;
  },
  /**
   * @param {UIState} state
   */
  resetStickyBannerHeight(state) {
    state.stickyBannerHeight = null;
  },
};
