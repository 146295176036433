<template>
  <section
    :class="{ 'is-comparing': hasComparison }"
    :style="[fontColorStyles, containerStyles, borderStyles, radiusStyles, radiantStyles]"
    data-cy="rectangle-container"
  >
    <div class="value" v-if="isLoadingValue" :style="[calculatedSize]">
      <p class="subtitle" data-cy="loader">
        <span>{{ $t('Loading...') }}</span>
      </p>
    </div>
    <div v-else class="big-number-container" :style="[calculatedSize]">
      <div class="card-label">
        <div
          class="card-label"
          :style="
            getFontSizeStyle(
              DataLabelMultiplierSquare.MIN,
              DataLabelMultiplierSquare.MAX,
              MaxFontSizes.METRIC_LABEL
            )
          "
          data-cy="label"
          :class="showMetricName"
        >
          {{ label }}

          <b-tooltip
            v-if="this.isRankingMetric && !this.isReportStudioWidget"
            class="tooltip-label"
            :label="
              $t(
                'Y Axis is reversed as this is a ranking metric. An upward graph indicates improvement in rank and a downward one indicates a drop.'
              )
            "
            type="is-dark"
            position="is-bottom"
            :multilined="true"
          >
            <template>
              <b-icon custom-class="icon icon-info-circle"></b-icon>
            </template>
          </b-tooltip>
        </div>
      </div>
      <div class="value-container">
        <div class="value">
          <span
            :style="[
              fontStyles,
              getFontSizeStyle(DataFontMultiplierSquare.MIN, DataFontMultiplierSquare.MAX),
            ]"
            data-cy="value"
            :title="emptyDataMessage"
          >
            <b-tooltip
              :active="!!isFormattedEllipsisActive()"
              :label="isFormattedEllipsisActive()"
              type="is-dark"
              size="is-small"
            >
              <div class="overflow-value" ref="formattedTooltip">
                {{ formattedValue }}
              </div>
            </b-tooltip>
          </span>
        </div>
        <div v-if="hasComparison && showComparisonArrows && !canShowSparkLineComparison">
          <div
            :style="
              getFontSizeStyle(
                DataComaprisonMultiplierSquare.MIN,
                DataComaprisonMultiplierSquare.MAX
              )
            "
          >
            <span data-cy="delta-value">{{ formattedDeltaValue }}</span>
            <span v-if="!compareAsValue">%</span>
            <svg
              v-if="isExportingReportStudio"
              class="delta-value"
              :class="[deltaIconClass, deltaValueClasses]"
              :style="
                getIconSizeStyle(
                  DataComaprisonMultiplierSquare.MIN,
                  DataComaprisonMultiplierSquare.MAX
                )
              "
            >
              <use xlink:href="" :href="`#${deltaIconClass}`"></use>
            </svg>
            <b-icon
              v-else
              pack="fa"
              class="delta-value"
              :class="deltaValueClasses"
              :custom-class="iconClass"
              :style="
                getFontSizeStyle(
                  DataComaprisonMultiplierSquare.MIN,
                  DataComaprisonMultiplierSquare.MAX
                )
              "
              data-cy="delta-icon"
            ></b-icon>
          </div>
          <span
            class="subtitle"
            :style="[
              getFontSizeStyle(
                DataComaprisonFontMultiplierSquare.MIN,
                DataComaprisonFontMultiplierSquare.MAX
              ),
            ]"
            data-cy="delta-subtitle"
          >
            {{ $t('vs') }}
            <span>{{ formattedComparisonValue }}</span>
            {{ $t('prev.') }}
          </span>
        </div>
      </div>
      <div class="line-chart" :style="{ height: chartHeight }">
        <div :style="[getLineChartDivStyle]">
          <component
            v-show="sparklineData.length"
            :is="chartComponent"
            :key="componentKey"
            :config="getChartConfig()"
          />
        </div>
        <div
          id="sparkline-comparison-values"
          v-if="canShowSparkLineComparison"
          :style="finalInitialComparisonMargin"
        >
          <span id="sparkline-values-difference" :class="finalInitialComparisonClasses">
            {{ differenceValue }}%
            <b-icon
              pack="fa"
              class="delta-value"
              :class="comparisonDeltaValueClasses"
              :custom-class="comparisonDeltaClass"
              data-cy="delta-icon"
            ></b-icon>
            <br />
          </span>
          <span
            class="sparkline-comparison-subtitle"
            data-cy="delta-sparkline-comparison-subtitle"
            :style="[finalInitialComparisonColor]"
          >
            {{ finalValue }}
            {{ $t('vs') }}
            {{ initialValue }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ScoreCardMixin } from '@/modules/core/scorecard/mixins/ScoreCardMixin';
import LineChart from '@/modules/core/charts/am5/line/components/LineChart.vue';
import { LineChartConfig } from '@/modules/core/charts/am5/line/models/LineChartConfig';
import BarChart from '@/modules/core/charts/am5/bar/components/BarChart.vue';
import { BarChartConfig } from '@/modules/core/charts/am5/bar/models/BarChartConfig';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { SeriesType, ChartType, ChartAdapters } from '@/modules/core/charts/chart.constants';
import { SparkLineComparisonType } from '@/modules/core/scorecard/scorecard.constants';
import NumeralService from '@/modules/core/app/services/NumeralService';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export default {
  name: 'SparkLineCard',
  mixins: [ScoreCardMixin],
  components: { BarChart, LineChart },
  props: {
    sparklineData: {
      type: [Array, Object],
      default: () => [],
    },
    field: {
      type: String,
      required: true,
    },
    categoryField: {
      type: String,
      default: 'log_date',
    },
    widgetId: {
      type: [Number, String],
      default: 0,
    },
    sparklineType: {
      type: String,
      default: SeriesType.LINE,
    },
    comparisonType: {
      type: String,
      default: SparkLineComparisonType.PRIOR_DATE_RANGE,
    },
    isOverridingDateRange: {
      type: Boolean,
      default: false,
    },
    finalInitialComparisonColor: {
      type: Object,
      default: () => ({}),
    },
    isReportStudioWidget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      finalValue: null,
      initialValue: null,
      comparisonDeltaValue: 0,
      differenceValue: 0,
      isComparisonValueLoaded: false,
      componentKey: 0,
    };
  },
  computed: {
    calculatedSize() {
      const width = `${this.wrapSize * 3}px`;
      const height = `${this.wrapSize * 1.6}px`;
      return { width, height };
    },
    radiusStyles() {
      return { 'border-radius': `${this.cornerRadius}px` };
    },
    chartHeight() {
      return `${this.wrapSize / 3}px`;
    },
    // Dynamically compute the chart component based on sparklineType
    chartComponent() {
      return this.sparklineType === SeriesType.COLUMN ? ChartType.BAR_CHART : ChartType.LINE_CHART;
    },
    canShowSparkLineComparison() {
      return (
        this.isOverridingDateRange &&
        this.hasComparison &&
        this.showComparisonArrows &&
        this.isComparisonValueLoaded &&
        this.comparisonType === SparkLineComparisonType.FINAL_INITIAL_VALUE
      );
    },
    getLineChartDivStyle() {
      const styles = {};
      if (this.canShowSparkLineComparison) {
        styles.width = '80%';
      } else {
        styles.width = '100%';
      }

      return styles;
    },
    sparkLineComparisonData() {
      return this.sparklineData;
    },
    comparisonDeltaClass() {
      return this.comparisonDeltaValue >= 0 ? 'fa-caret-up' : 'fa-caret-down';
    },
    comparisonDeltaValueClasses() {
      const classes = [];

      if (!this.showDefaultComparisonArrowColor || this.comparisonDeltaValue === 0) {
        classes.push('neutral-delta');
      } else if (this.comparisonDeltaValue > 0) {
        // eslint-disable-next-line tap/no-raw-text-js
        classes.push(this.getPlusOrMinusDelta('up'));
      } else if (this.comparisonDeltaValue < 0) {
        // eslint-disable-next-line tap/no-raw-text-js
        classes.push(this.getPlusOrMinusDelta('down'));
      }

      return classes;
    },
    finalInitialComparisonMargin() {
      if (
        this.finalValue.length >= 5 ||
        this.initialValue.length >= 5 ||
        this.differenceValue.length >= 5
      ) {
        return { 'margin-top': this.isReportStudioWidget ? '-20px' : '-10px' };
      }

      return { 'margin-top': '5px' };
    },
    sparkLineMetricData() {
      if (this.isRankingMetricLineChart) {
        return this.sparklineData.map((v) => ({
          ...v,
          [this.sparklineLineChartField()]: this.sparklineLineChartMaxValue(),
        }));
      }

      return this.sparklineData;
    },
    isRankingMetricLineChart() {
      return (
        this.isRankingMetric &&
        this.sparklineType === SeriesType.LINE &&
        this.sparklineData.length > 0
      );
    },
    finalInitialComparisonClasses() {
      if (this.differenceValue.length <= 6) {
        return ['font-size-10'];
      }
      return ['font-size-12'];
    },
  },
  methods: {
    sparklineLineChartField() {
      return `${this.field}_${Constant.OPEN_VALUE_FIELD_NAME}`;
    },
    sparklineLineChartMaxValue() {
      return Math.max(...this.sparklineData.map((o) => o[this.field]));
    },
    getChartConfig() {
      const rankingLineSeriesProps = this.isRankingMetricLineChart
        ? {
            openValueYField: this.sparklineLineChartField(),
            strictMinMax: true,
            max: this.sparklineLineChartMaxValue(),
          }
        : {};

      const commonConfig = {
        series: [
          {
            fill: this.fontProperties.text_color,
            labelFill: this.containerStyles.background,
            fillOpacity: this.sparklineType === SeriesType.COLUMN ? 0.5 : 0.3,
            opacity: 1,
            strokeOpacity: 0.5,
            tension: 0.9,
            isCircularBullets: false,
            value: this.field,
            category: this.categoryField,
            tooltipText: '',
            name: this.label,
            color: this.fontProperties.text_color,
            dataItemFormat: ColumnFormat.FORMAT_INTEGER,
          },
        ],
        categoryAxis: [
          {
            category: this.categoryField,
            showLabels: false,
            hideGridLines: true,
            minGridDistance: 30,
            dataItemFormat: ColumnFormat.FORMAT_STRING,
          },
        ],
        valueAxis: [
          {
            axisType: ChartAdapters.VALUE,
            hideGridLines: true,
            showLabels: true,
            dataItemFormat: ColumnFormat.FORMAT_INTEGER,
            isRankingMetric: this.isRankingMetric,
            ...rankingLineSeriesProps,
          },
        ],
        legend: {
          active: false,
        },
        data: this.sparkLineMetricData,
        isSparkLine: true,
        hasTooltip: true,
        isRotated: false,
        hasRankingMetrics: this.isRankingMetric,
        fillAreaAboveLine: this.isRankingMetricLineChart,
      };

      if (this.sparklineType === SeriesType.COLUMN) {
        return new BarChartConfig({
          ...commonConfig,
          series: [
            {
              ...commonConfig.series[0],
              seriesType: SeriesType.COLUMN,
              isRankingMetric: this.isRankingMetric,
            },
          ],
        });
      }
      if (!this.isComparisonValueLoaded) {
        this.loadSparkLineComparisonData();
      }
      return new LineChartConfig({
        ...commonConfig,
        series: [
          {
            ...commonConfig.series[0],
            seriesType: SeriesType.LINE,
            isRankingMetric: this.isRankingMetric,
          },
        ],
      });
    },
    loadSparkLineComparisonData() {
      if (this.sparkLineComparisonData.length > 0) {
        const dataLength = this.sparkLineComparisonData.length;
        const initialValueObj = this.sparkLineComparisonData[0];
        const finalValueObj = this.sparkLineComparisonData[dataLength - 1];
        if (initialValueObj) {
          this.initialValue = parseFloat(initialValueObj[this.field]).toFixed(2);
        }
        if (finalValueObj) {
          this.finalValue = parseFloat(finalValueObj[this.field]).toFixed(2);
        }
        this.differenceValue = this.getComparisonValue();
        this.finalValue = NumeralService.formatValue(
          Math.abs(this.finalValue),
          ColumnFormat.FORMAT_INTEGER,
          2,
          true,
          this.currencySymbol
        );
        this.initialValue = NumeralService.formatValue(
          Math.abs(this.initialValue),
          ColumnFormat.FORMAT_INTEGER,
          2,
          true,
          this.currencySymbol
        );
        this.isComparisonValueLoaded = true;
      }
    },
    getComparisonValue() {
      this.initialValue = parseFloat(this.initialValue);
      this.finalValue = parseFloat(this.finalValue);
      this.comparisonDeltaValue = this.getSparklineComparisonDeltaValue();

      return NumeralService.formatValue(
        Math.abs(this.comparisonDeltaValue),
        ColumnFormat.FORMAT_INTEGER,
        2,
        true,
        this.currencySymbol
      );
    },
    getSparklineComparisonDeltaValue() {
      return NumeralService.calculateDelta(
        this.finalValue,
        this.initialValue,
        this.compareAsValue,
        this.format
      );
    },
  },
};
</script>

<style scoped lang="scss">
/* Apply styles to the first <section> element */
section {
  position: relative;
  padding: 0 rem(15px);
  border-radius: $radius-small;
}

.value {
  min-width: 50%;
}

.big-number-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .value-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
  }

  .card-label {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .line-chart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(10px);

    :deep(section) {
      width: 100%;
      padding: 0;
    }

    .sparkline-comparison-subtitle {
      font-size: rem(9px);
      color: #aab8c7;
    }

    #sparkline-comparison-values {
      width: 20%;
      margin-top: rem(5px);
      font-size: rem(8px);
    }
  }

  .tooltip-label {
    display: inline-block;
    top: rem(2px);
  }

  .font-size-12 {
    font-size: rem(12px);
  }

  .font-size-10 {
    font-size: rem(10px);
  }
}

.delta-value {
  &.positive-delta {
    color: $green;
  }

  &.negative-delta {
    color: $red;
  }
}

.b-tooltip {
  display: block;
}

.overflow-value {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
