import { commits } from '@/modules/core/app/helpers/store';

export const actions = {
  setStickyBannerHeight({}, value) {
    commits.ui.setStickyBannerHeight(value);
  },
  resetStickyBannerHeight() {
    commits.ui.resetStickyBannerHeight();
  },
};
