export class FormSelectOption {
  /**
   * @var {string}
   */
  placeholder;

  /**
   * @var {string}
   */
  primaryKey;

  /**
   * @var {string}
   */
  valueKey;

  /**
   * @var {boolean}
   */
  groupSelect;

  /**
   * @var {boolean}
   */
  isRequired;

  /**
   * @var {string|null}
   */
  tooltip;

  /**
   * @var {boolean|null}
   */
  allowNullValue;

  /**
   * Flag for the frontend to allow an empty selection in a form, where it implicitly
   * selects all values in the dropdown.
   *
   * @var {boolean}
   */
  canClearSelection;

  /**
   * @var {boolean|null}
   */
  valueIsObject;

  /**
   * @var {BaseService}
   */
  service;

  /**
   * @var {boolean}
   */
  isTaggable;

  /**
   * Data structure returned from fetching values
   * Initial value is null until data is returned
   */
  values;

  /**
   * Should FormSelect re process options while watching
   */
  skipProcessOptions;

  /**
   * When false, will get inactive users emails in scheduledreports
   */
  fromSendEmailModal;

  notSetValue;

  constructor(config = {}) {
    this.placeholder = config.placeholder;
    this.primaryKey = config.primaryKey || 'key';
    this.valueKey = config.valueKey || 'value';
    this.groupSelect = config.groupSelect || false;
    this.isRequired = config.isRequired || false;
    this.tooltip = config.tooltip || null;
    this.allowNullValue = config.allowNullValue ?? true;
    this.valueIsObject = config.valueIsObject ?? true;
    this.canClearSelection = config.canClearSelection ?? true;
    this.isTaggable = config.isTaggable || false;
    this.values = config.values ?? null;
    this.skipProcessOptions =
      config.skipProcessOptions === undefined ? false : config.skipProcessOptions;
    this.fromSendEmailModal = config.fromSendEmailModal ?? false;
    this.inactive = config.inactive ?? true;
    this.notSetValue = config.notSetValue;
    if (config.getProcessedTag) {
      this.getProcessedTag = config.getProcessedTag;
    }
    if (config.getGroupValues) {
      this.getGroupValues = config.getGroupValues;
    }
    if (config.getGroupLabel) {
      this.getGroupLabel = config.getGroupLabel;
    }

    // Registers the service used to fetch options in getData method
    this.service = config.service;

    if (config.getCustomLabel) {
      this.getCustomLabel = config.getCustomLabel;
    }

    if (config.getData) {
      this.getData = config.getData;
    }
  }

  setValues(values) {
    this.values = values;
  }

  /**
   * @param value
   */
  setValueIsObject(value) {
    this.valueIsObject = value;
  }

  /**
   * @returns {*}
   */
  getTrackBy() {
    return this.primaryKey;
  }

  /**
   * @returns {boolean}
   */
  getHideSelected() {
    return false;
  }

  /**
   * @returns {null|String}
   */
  getGroupValues() {
    return null;
  }

  /**
   * @returns {null|String}
   */
  getGroupLabel() {
    return null;
  }

  /**
   * For custom behaviour this method can overriden
   * @param tag
   */
  getProcessedTag(tag) {
    return {
      [this.primaryKey]: tag,
    };
  }
}
