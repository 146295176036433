import { render, staticRenderFns } from "./EmbedSparkline.vue?vue&type=template&id=247c53ca&scoped=true&"
import script from "./EmbedSparkline.vue?vue&type=script&lang=js&"
export * from "./EmbedSparkline.vue?vue&type=script&lang=js&"
import style0 from "./EmbedSparkline.vue?vue&type=style&index=0&id=247c53ca&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247c53ca",
  null
  
)

export default component.exports