'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('orders.services', ['core.iohelper'])
    .factory('OrderFactory', OrderFactory)
    .factory('OrdersService', OrdersService)
    .factory('OrdersFactory', OrdersFactory)
    .factory('OrdersDataGridFactory', OrdersDataGridFactory)
    .factory('StatusService', StatusService)
    .factory('StatusFactory', StatusFactory)
    .factory('MediaService', MediaService)
    .factory('MediaFactory', MediaFactory);

/**
 * @ngInject
 */
function OrderFactory(
    Restangular,
    AppConfig,
    OrderColumns,
    ColumnValueType
) {
    var orders = Restangular.all('orders');
    var vars = orders.one('vars');
    var values = orders.one('values');

    /**
     *
     * @param queryParams
     * @returns {*|ICollectionPromise<T>|ICollectionPromise<any>|{method, params, headers}}
     */
    function getColumns(queryParams) {
        return orders.getList(_.extend({
            metadata: true,
            sort: 'label'
        }, queryParams));
    }

    /**
     * Get all selectable values for specific fields (enum values)
     * @param fields (fields can be undefined to fetch all)
     * @returns {*}
     */
    function getColumnValues(fields) {
        return values.one(ColumnValueType.SET).get(_.isUndefined(fields) ? '' : { fields: fields });
    }

    /**
     * Get all distinct values for specific fields
     * @param fields (fields can be undefined to fetch all)
     * @dataSource unused
     * @queryParams any additional query params
     * @returns {*}
     */
    function getFieldValues(fields, dataSource, queryParams) {
        return values.one(ColumnValueType.DISTINCT).get(
            _.extend(_.isUndefined(fields) ? {} : {fields: fields}, queryParams || {}));
    }

    /**
     * Get data for this resource
     * @param queryParams
     * @param method
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    function getData(queryParams) {
        return orders.getList(queryParams);
    }

    return {
        orders: orders,
        vars: vars,
        getColumns: getColumns,
        getColumnValues: getColumnValues,
        getFieldValues: getFieldValues,
        getData: getData
    }
}

/**
 * @ngInject
 */
function OrdersDataGridFactory(
    IODefaultLinkColumns,
    IODefaultLinkColumnsRenderer,
    OrderColumns
) {
    return {
        getDTOptions: getDTOptions
    };

    /**
     * Return service specific datatable options
     * @returns {{}}
     */
    function getDTOptions() {
        var dtOptions = {};
        dtOptions.customRenders = {};
        dtOptions.customRenders[OrderColumns.ID] = IODefaultLinkColumnsRenderer.renderIoOrderIdLinkColumn;
        return dtOptions;
    }
}

/**
 * @ngInject
 */
function OrdersService(
    OrdersFactory,
    $q,
    $rootScope,
    Upload,
    AppConfig,
    $http
) {
    var service = {};
    service.ordersList = null;
    service.order = null;
    service.warnings = null;
    service.statuses = null;
    service.types = null;
    service.lineItem = null;
    service.flight = null;
    service.flightId = null;
    service.flightAttrs = null;
    service.lineItems = null;
    service.lineItemActivity = null;
    service.buySheet = null;
    service.orderId = null;
    service.lineItemId = null;
    service.newLineItemId = null;
    service.creativeActivity = null;
    service.orderActivity = null;
    service.flightActivity = null;
    service.orderNotificationUsers = null;
    service.errors = null;
    service.cancelReasons = null;
    service.dfpValues = null;
    service.lineItemAttrs = null;
    service.addNewLineItemAttributes = null;
    service.getLineItemFileFieldAttrs = null;
    service.removeLineItemFileAttrsAndCreatives = null;
    service.addNewLineItemTaskAttributes = null;
    service.getLineItemTaskFileFieldAttrs = null;
    service.getFlightTaskFileFieldAttrs = null;
    service.removeLineItemTaskFileAttrsAndCreatives = null;
    service.lineItemWatchersList = null;
    service.flights = null;
    service.orderWatchersList = null;
    service.bulkRollupOrders = null;
    service.getLineItemPdfDownloadUrl = function(){
        return AppConfig.API_URL + 'lineitems/export/htmlpdf';
    }

    service.isValidJSONSting = function(json_string){
        try {
            JSON.parse(json_string);
        } catch (e) {
            return false;
        }
        return true;
    }

    //get all values for dfp
    service.getDfpValuesByKey = function(keyId, searchKey, clusterId){
        var deferred = $q.defer();
        var params = {
            'id': keyId,
            'searchKey': searchKey,
            'clusterId': clusterId
        };
        OrdersFactory.dfpValues().get(params).$promise.then(function(data) {
            service.dfpValues = data.values;
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });
        return deferred.promise;
    }

    service.getDfpValuesById = function(searchIds,clusterId,model){
        var deferred = $q.defer();
        var params = {
            'cluster_id': clusterId,
            'value_id[]': searchIds,
            'model' : model
        };
        OrdersFactory.dfpValuesById().get(params).$promise.then(function(response) {
            service.valuesByIds = response.data;
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });
        return deferred.promise;
    }

    // Get all orders for a cluster - heavy call!
    service.getOrders = function(clusterIds, salespersonId=null, filter = null) {
        let deferred = $q.defer();
        let params = {
            'clusterIds[]': clusterIds
        };
        if (salespersonId !== null) {
            params.salespersonId = salespersonId;
        }
        if (filter !== null) {
            params.filter = filter;
        }
        //TEMP FIX FOR DOUBLE CALLS SHOULD BE REMOVED AFTER THE CLUSTER RE-WORK
        $rootScope.isCallSent = true;
        OrdersFactory.orders().get(params).$promise.then(function(data) {
            if (data.orders == null) {
                service.ordersList = [];
            } else {
                service.ordersList = data.orders;
            }
            $rootScope.isCallSent = false;
            deferred.resolve();
        }, function(response) {
            $rootScope.isCallSent = false;
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getOrderFlightsCounts = function(clusterIds, salespersonId= null) {
        let deferred = $q.defer();
        let params = {
            'clusterIds[]': clusterIds
        };
        if (salespersonId !== null) {
            params.salespersonId = salespersonId;
        }
        OrdersFactory.ordersFlightsCounts().get(params).$promise.then(function(data) {
            let ordersFlightsCounts = [];
            if (data.ordersFlightsCounts != null) {
                ordersFlightsCounts = data.ordersFlightsCounts;
            }
            deferred.resolve(ordersFlightsCounts);
        }, function() {
            console.log("Error getting order flight counts");
            deferred.reject();
        });
        return deferred.promise;
    };

    service.getOrderPlacementProducts = function(orderId) {
        let deferred = $q.defer();
        const params = {
            'orderId': orderId
        };
        OrdersFactory.orderPlacementProducts().get(params).$promise.then(function(data) {
            let products = [];
            if (data.products != null) {
                products = data.products;
            }
            deferred.resolve(products);
        }, function() {
            console.log("Error getting order placement products");
            deferred.reject();
        });
        return deferred.promise;
    };

    /**
     * Placement data is the line items and flights and they current or historical association.
     * Basically, which flights are in which line item, or were in the past.
     *
     * @param orderId
     * @param productId
     * @returns {*}
     */
    service.getPlacementDataForOrderAndProduct = function(orderId, productId) {
        let deferred = $q.defer();
        const params = {
            'orderId': orderId,
            'productId': productId
        };
        OrdersFactory.placementDataForOrderAndProduct().get(params).$promise.then(function(data) {
            let associations = [];
            if (data.associations != null) {
                associations = data.associations;
            }
            deferred.resolve(associations);
        }, function() {
            console.log('Error getting placement data for order ' + orderId + ' and product ' + productId);
            deferred.reject();
        });
        return deferred.promise;
    };

    service.getLineItemDataForOrderAndProduct = function(orderId, productId) {
        let deferred = $q.defer();
        const params = {
            'orderId': orderId,
            'productId': productId
        };
        OrdersFactory.lineItemDataForOrderAndProduct().get(params).$promise.then(function(data) {
            let lineItems = [];
            if (data.lineItems != null) {
                lineItems = data.lineItems;
            }
            deferred.resolve(lineItems);
        }, function() {
            console.log('Error getting line item data for order ' + orderId + ' and product ' + productId);
            deferred.reject();
        });
        return deferred.promise;
    };

    service.assignPlacements = function(checkedLineItemIds, checkedFlightIds) {
        let deferred = $q.defer();
        const params = {
            'checkedLineItemIds': checkedLineItemIds,
            'checkedFlightIds': checkedFlightIds
        };
        OrdersFactory.assignPlacements().update(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function() {
            console.log('Error assigning placements');
            deferred.reject();
        });
        return deferred.promise;
    };

    // Get all orders for a cluster - heavy call!
    service.getOrdersWithPagination = function(params) {
        var deferred = $q.defer();
        //TEMP FIX FOR DOUBLE CALLS SHOULD BE REMOVED AFTER THE CLUSTER RE-WORK
        $rootScope.isCallSent = true;
        OrdersFactory.ordersWithPagination().get(params).$promise.then(function(data) {
            if (data.orders == null) {
                service.ordersList = [];
            } else {
                service.ordersList = data.orders;
            }
            $rootScope.isCallSent = false;
            deferred.resolve(data);
        }, function(response) {
            $rootScope.isCallSent = false;
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get open orders for a cluster
    service.getOpenOrders = function(clusterIds) {
        var deferred = $q.defer();
        var params = {
            'clusterIds[]': clusterIds
        };
        //TEMP FIX FOR DOUBLE CALLS SHOULD BE REMOVED AFTER THE CLUSTER RE-WORK
        $rootScope.isCallSent = true;

        OrdersFactory.openOrders().get(params).$promise.then(function(data) {
            if (data.orders == null) {
                service.ordersList = [];
            } else {
                service.ordersList = data.orders;
            }
            $rootScope.isCallSent = false;
            deferred.resolve();
        }, function(response) {
            $rootScope.isCallSent = false;
            deferred.reject();

        });

        return deferred.promise;
    };

    // Get open orders Workflow Steps for a cluster
    service.getOpenOrdersWorkflowSteps = function(clusterIds) {
        var deferred = $q.defer();
        var params = {
            'clusterIds[]': clusterIds
        };
        OrdersFactory.openOrdersWorkflowSteps().get(params).$promise.then(function(data) {
            if (data.ordersWorkflowSteps == null) {
                service.ordersWorkflowSteps = [];
            } else {
                service.ordersWorkflowSteps = data.ordersWorkflowSteps;
            }
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });
        return deferred.promise;
    };

    // Get all orders Workflow Steps for a cluster
    service.getOrdersWorkflowSteps = function(clusterIds) {
        var deferred = $q.defer();
        var params = {
            'clusterIds[]': clusterIds
        };
        OrdersFactory.ordersWorkflowSteps().get(params).$promise.then(function(data) {
            if (data.ordersWorkflowSteps == null) {
                service.ordersWorkflowSteps = [];
            } else {
                service.ordersWorkflowSteps = data.ordersWorkflowSteps;
            }
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });
        return deferred.promise;
    };

    // Get archived orders for a cluster
    service.getArchivedOrders = function(clusterIds) {
        var deferred = $q.defer();
        var params = {
            'clusterIds[]': clusterIds
        };

        OrdersFactory.archivedOrders().get(params).$promise.then(function(data) {
            if (data.orders == null) {
                service.ordersList = [];
            } else {
                service.ordersList = data.orders;
            }
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get all orders for a specific salesperson
    service.getOrdersForSalesperson = function(userId) {
        var deferred = $q.defer();
        var params = {
            'userId': userId
        };

        OrdersFactory.ordersForSalesperson().get(params).$promise.then(function(data) {
            if (data.orders == null) {
                service.ordersList = [];
            } else {
                service.ordersList = data.orders;
            }
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get open orders for a specific salesperson
    service.getOpenOrdersForSalesperson = function(userId) {
        var deferred = $q.defer();
        var params = {
            'userId': userId
        };
        //TEMP FIX FOR DOUBLE CALLS SHOULD BE REMOVED AFTER THE CLUSTER RE-WORK
        $rootScope.isCallSent = true;
        OrdersFactory.openOrdersForSalesperson().get(params).$promise.then(function(data) {
            if (data.orders == null) {
                service.ordersList = [];
            } else {
                service.ordersList = data.orders;
            }
            $rootScope.isCallSent = false;
            deferred.resolve();
        }, function(response) {
            $rootScope.isCallSent = false;
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get open orders for a specific client
    service.getOpenOrdersForClient = function(clientId) {
        var deferred = $q.defer();
        var params = {
            'clientId': clientId
        };
        //TEMP FIX FOR DOUBLE CALLS SHOULD BE REMOVED AFTER THE CLUSTER RE-WORK
        $rootScope.isCallSent = true;
        OrdersFactory.openOrdersForClient().get(params).$promise.then(function(data) {
            if (data.orders == null) {
                service.ordersList = [];
            } else {
                service.ordersList = data.orders;
            }
            $rootScope.isCallSent = false;
            deferred.resolve();
        }, function(response) {
            $rootScope.isCallSent = false;
            deferred.reject();
        });

        return deferred.promise;
    };

    //Get workflow steps for a specific salesperson
    service.getOpenOrdersWorkflowStepsForSalesperson = function(userId) {
        var deferred = $q.defer();
        var params = {
            'userId': userId
        };
        OrdersFactory.openOrdersWorkflowStepsForSalesperson().get(params).$promise.then(function(data) {
            if (data.ordersWorkflowSteps == null) {
                service.ordersWorkflowStepsForSalesperson = [];
            } else {
                service.ordersWorkflowStepsForSalesperson = data.ordersWorkflowSteps;
            }
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });
        return deferred.promise;
    };

    // Get archived orders for a specific salesperson
    service.getArchivedOrdersForSalesperson = function(userId) {
        var deferred = $q.defer();
        var params = {
            'userId': userId
        };

        OrdersFactory.archivedOrdersForSalesperson().get(params).$promise.then(function(data) {
            if (data.orders == null) {
                service.ordersList = [];
            } else {
                service.ordersList = data.orders;
            }
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get archived orders for a specific client
    service.getArchivedOrdersForClient = function(clientId) {
        var deferred = $q.defer();
        var params = {
            'clientId': clientId
        };
        //TEMP FIX FOR DOUBLE CALLS SHOULD BE REMOVED AFTER THE CLUSTER RE-WORK
        $rootScope.isCallSent = true;
        OrdersFactory.archivedOrdersForClient().get(params).$promise.then(function(data) {
            if (data.orders == null) {
                service.ordersList = [];
            } else {
                service.ordersList = data.orders;
            }
            $rootScope.isCallSent = false;
            deferred.resolve();
        }, function(response) {
            $rootScope.isCallSent = false;
            deferred.reject();
        });

        return deferred.promise;
    };

    // Search all orders for a cluster based on one criterion
    service.searchOrders = function(clusterId, searchField, searchString, pageName) {
        var deferred = $q.defer();
        var params = {
            'clusterIds[]': clusterId,
        };
        params.pageName = pageName;
        if (searchField == 'client') {
            params.client = searchString
        } else if (searchField == 'product') {
            params.product = searchString
        } else if (searchField == 'client billing id') {
            params.billingId = searchString
        } else if (searchField == 'order id') {
            params.orderId = searchString
        } else if (searchField == 'line item id') {
            params.lineItemId = searchString
        } else if (searchField == 'flight id') {
            params.flightId = searchString
        } else if (searchField == 'submitter') {
            params.submitter = searchString
        } else if (searchField == 'creator') {
            params.creator = searchString
        } else if (searchField == 'client crm id') {
            params.customerCrmId = searchString
        } else if (searchField == 'task #') {
            params.taskAssignmentId = searchString
        } else if (searchField == 'flight name') {
            params.flightName = searchString
        } else if (searchField == 'order serving platform id') {
            params.orderServingPlatformId = searchString
        } else if (searchField == 'line item serving platform id') {
            params.lineItemServingPlatformId = searchString
        } else if (searchField == 'flight serving platform id') {
            params.flightServingPlatformId = searchString
        } else if (searchField == 'line item start date') {
            params.lineItemStartDate = searchString
        } else if (searchField == 'line item end date') {
            params.lineItemEndDate = searchString
        } else if (searchField == 'flight start date') {
            params.flightStartDate = searchString
        } else if (searchField == 'flight end date') {
            params.flightEndDate = searchString
        }

        OrdersFactory.search().get(params).$promise.then(function(data) {
            if (data.errors) {
                deferred.reject(data.errors[0]);
            }
            service.ordersList = data.orders;
            deferred.resolve();
        }, function(response) {
            console.log("Error searching orders");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Search line items for a cluster based on one criterion
    service.searchLineItems = function(orderId, searchField, searchString) {
        const deferred = $q.defer();
        const params = {
            'orderId': orderId,
        };
        if (searchField == 'flight id') {
            params.flightId = searchString
        } else if (searchField == 'task #') {
            params.taskAssignmentId = searchString
        } else if (searchField == 'flight name') {
            params.flightName = searchString
        } else if (searchField == 'line item serving platform id') {
            params.lineItemServingPlatformId = searchString
        } else if (searchField == 'flight serving platform id') {
            params.flightServingPlatformId = searchString
        } else if (searchField == 'flight start date') {
            params.flightStartDate = searchString
        } else if (searchField == 'flight end date') {
            params.flightEndDate = searchString
        }

        OrdersFactory.searchLineItems().get(params).$promise.then(function(data) {
            if (data.errors) {
                deferred.reject(data.errors[0]);
            }
            deferred.resolve(data.lineItems);
        }, function(response) {
            console.log("Error in searchLineItems: " + response);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get Orders for a client
    service.getOrderForClient = function(clientId) {
        var deferred = $q.defer();
        var params = {
            'clientId': clientId
        };

        OrdersFactory.openOrdersForClient().get(params).$promise.then(function(data) {
            deferred.resolve(data.orders);
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get a single order by Id
    service.getOrder = function(orderId, excludeLineItems=false, taskAssignmentId = null) {
        var deferred = $q.defer();
        var params = {
            'id': orderId
        };

        if (excludeLineItems) {
            params['excludeLineItems'] = 1;
        }

        if (taskAssignmentId !== null) {
            params.taskAssignmentId = taskAssignmentId;
        }

        OrdersFactory.order().get(params).$promise.then(function(data) {
            service.order = data.order;
            deferred.resolve();
        }, function(response) {
            console.log("Error getting order data");
            deferred.reject({message: response?.data?.message, code: response?.status});
        });

        return deferred.promise;
    };

    // Get a workflow steps for order by Id
    service.getStepsForOrder = function(orderId) {
        var deferred = $q.defer();
        var params = {
            'orderId': orderId
        };

        OrdersFactory.stepsForOrder().get(params).$promise.then(function(data) {
            deferred.resolve(data.workflowSteps);
        }, function(response) {
            console.log("Error getting steps for order");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get a workflow steps for flight by Id
    service.getStepsForFlight = function(flightId) {
        var deferred = $q.defer();
        var params = {
            'flightId': flightId
        };

        OrdersFactory.stepsForFlight().get(params).$promise.then(function(data) {
            deferred.resolve(data.workflowSteps);
        }, function(response) {
            console.log("Error getting steps for flight");
            deferred.reject(response);
        });

        return deferred.promise;
    };

    // Get all statuses related to orders
    service.getOrderStatuses = function() {
        var deferred = $q.defer();

        OrdersFactory.statuses().get().$promise.then(function(data) {
            service.statuses = data.statuses;
            deferred.resolve();
        }, function(response) {
            console.log("Error getting order statuses");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get all types related to orders
    service.getOrderTypes = function() {
        var deferred = $q.defer();

        OrdersFactory.orderTypes().get().$promise.then(function(data) {
            service.types = data.orderTypes;
            deferred.resolve(data.orderTypes);
        }, function(response) {
            console.log("Error getting order types");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get all cancellation reasons related to orders, lineitems, and flights
    service.getCancelReasons = function(type) {
        var deferred = $q.defer();
        var params = {
            'type': type
        };

        OrdersFactory.cancelReasons().get(params).$promise.then(function(data) {
            service.cancelReasons = data.cancelReasons;
            deferred.resolve(data.cancelReasons);
        }, function(response) {
            console.log("Error getting cancel reasons");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get notification users for a specified order
    service.getOrderNotificationUsers = function(orderId) {
        var deferred = $q.defer();
        var params = {
            'orderId': orderId
        };

        OrdersFactory.orderNotificationUsers().get(params).$promise.then(function(data) {
            service.orderNotificationUsers = data.orderNotificationUsers;
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get a single order by Id
    service.getLineItems = function(orderId) {
        var deferred = $q.defer();
        var params = {
            'orderId': orderId
        };

        OrdersFactory.lineItems().get(params).$promise.then(function(data) {
            service.lineItems = data.lineItems;
            deferred.resolve(data.lineItems);
        }, function(response) {
            console.log("Error getting line items for order "+orderId);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get a single line item by Id
    service.getLineItem = function(lineItemId, calculateTaskCounts = false, getPreviousAndNext = false) {
        var deferred = $q.defer();
        var params = {
            'id': lineItemId,
            'calculateTaskCounts': calculateTaskCounts ? '1' : '0',
            'getPreviousAndNext': getPreviousAndNext ? '1' : '0'
        };

        OrdersFactory.lineItem().get(params).$promise.then(function(data) {
            service.lineItem = data.lineItem;
            service.lineItemAttrs = (data.lineItemAttrs)?data.lineItemAttrs:null;
            deferred.resolve();
        }, function(response) {
            console.log("Error getting line item "+lineItemId);
            deferred.reject({message: response.data.message, code: response.status});
        });

        return deferred.promise;
    };

    service.getFormattedEOAPreviews = function(original__eoa_previews) {
        // This is specific to the BridgeCorp integration allowing them to build and use audiences.
        // We use predefined form/product field names to store some data provided by BridgeCorp, and in the
        // case of __eoa_previews, we fetch data (including image URL) from a 3rd-party site, Email On Acid, and
        // format the results as HTML for use inside the textarea (this assumes the field is a textarea).
        const deferred = $q.defer();
        const params = {
            '__eoa_previews': original__eoa_previews
        };

        OrdersFactory.formatOEAPreview().get(params).$promise.then(function(data) {
            if (data.formatted) {
                deferred.resolve(data.formatted);
            } else {
                console.log('Could not format email previews, please contact support');
                if (data.errors) {
                    console.log(data.errors[0]);
                }
                deferred.reject();
            }
        }, function(response) {
            console.log('Could not format email previews, please contact support');
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getLineItemSteps = function(lineItemId) {
        var deferred = $q.defer();
        var params = {
            'lineItemId': lineItemId
        };

        OrdersFactory.lineItemSteps().get(params).$promise.then(function(data) {
            deferred.resolve(data.workflowSteps);
        }, function(response) {
            console.log("Error getting line item steps for line item "+lineItemId);
            deferred.reject();
        });

        return deferred.promise;
    };
    //Get Line Item Status
    service.getLineItemStatuses = function() {
        var deferred = $q.defer();

        OrdersFactory.lineItemStatus().get().$promise.then(function(data) {
            deferred.resolve(data.statuses);
        }, function(response) {
            console.log("Error getting line item statuses");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.lineItemHasAsynchronousAttrUpdates = function(lineItemId, attrs) {
        let deferred = $q.defer();
        let params = {
            'lineItemId': lineItemId,
        };
        Object.entries(attrs).forEach(function([key,value]) {
            params[key] = value;
        });

        OrdersFactory.lineItemHasAsynchronousAttrUpdates().save(params).$promise.then(function(data) {
            if (data) {
                deferred.resolve(data.hasUpdates);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.flightHasAsynchronousAttrUpdates = function(flightId, attrs) {
        let deferred = $q.defer();
        let params = {
            'flightId': flightId,
        };
        Object.entries(attrs).forEach(function([key,value]) {
            params[key] = value;
        });

        OrdersFactory.flightHasAsynchronousAttrUpdates().save(params).$promise.then(function(data) {
            if (data) {
                deferred.resolve(data.hasUpdates);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    //Get Line Item Field Attrs
    service.getLineItemFileFieldAttrs = function(lineItemId, fieldId) {
        let deferred = $q.defer();
        let params = {
            'lineItemId': lineItemId,
            'productFieldId': fieldId,
        };

        OrdersFactory.lineItemFileFieldAttrs().get(params).$promise.then(function(data) {
            if (data && data.fieldInfo) {
                deferred.resolve(data.fieldInfo);
            } else {
                deferred.reject();
            }
        }, function(response) {
            console.log("Error getting line item file field attributes for line item "+lineItemId+" and field "+fieldId);
            deferred.reject();
        });

        return deferred.promise;
    };

    //Get Line Item Task Field Attrs
    service.getOrderTaskFileFieldAttrs = function(orderTaskAttrObj) {
        let deferred = $q.defer();
        OrdersFactory.orderTaskFileFieldAttrs().get(orderTaskAttrObj).$promise.then(function(data) {
            if (data && data.fieldInfo) {
                deferred.resolve(data.fieldInfo);
            } else {
                deferred.reject();
            }
        }, function(response) {
            console.log("Error getting order task file field attributes");
            deferred.reject();
        });

        return deferred.promise;
    };

    //Get Line Item Task Field Attrs
    service.getLineItemTaskFileFieldAttrs = function(lineItemTaskAttrObj) {
        let deferred = $q.defer();
        OrdersFactory.lineItemTaskFileFieldAttrs().get(lineItemTaskAttrObj).$promise.then(function(data) {
            if (data && data.fieldInfo) {
                deferred.resolve(data.fieldInfo);
            } else {
                deferred.reject();
            }
        }, function(response) {
            console.log("Error getting line item task file field attributes");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getFlightFileFieldAttrs = function(flightId, fieldId) {
        let deferred = $q.defer();
        let params = {
            'flightId': flightId,
            'formFieldId': fieldId,
        };
        OrdersFactory.flightFileFieldAttrs().get(params).$promise.then(function(data) {
            if (data && data.fieldInfo) {
                deferred.resolve(data.fieldInfo);
            } else {
                deferred.reject();
            }
        }, function(response) {
            console.log("Error getting flight file field attributes");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getFlightTaskFileFieldAttrs = function(flightTaskAttrObj) {
        let deferred = $q.defer();
        OrdersFactory.flightTaskFileFieldAttrs().get(flightTaskAttrObj).$promise.then(function(data) {
            if (data && data.fieldInfo) {
                deferred.resolve(data.fieldInfo);
            } else {
                deferred.reject();
            }
        }, function(response) {
            console.log("Error getting flight task file field attributes");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get activity log based on type and Id
    service.getActivity = function(type, id) {
        var deferred = $q.defer();
        var params = {
            'tableRowId': id
        };
        OrdersFactory.entityActivity(type).get(params).$promise.then(function(data) {
            if (type === 'creative') {
                service.creativeActivity = data.activityLogEntries;
            } else if (type === 'line_item') {
                service.lineItemActivity = data.activityLogEntries;
            } else if (type === 'line_item_task_attr') {
                service.lineItemTaskActivity = data.activityLogEntries;
            } else if (type === 'order') {
                service.orderActivity = data.activityLogEntries;
            } else if (type === 'flight') {
                service.flightActivity = data.activityLogEntries;
            } else {
                console.log("Error getting activity log entries for type "+type);
                deferred.reject();
            }
            deferred.resolve();
        }, function(response) {
            console.log("Error getting "+type+" activity log entries");
            deferred.reject();
        });
        return deferred.promise;
    };

    // Add a new order
    service.addOrder = function(orderData) {
        var deferred = $q.defer();

        OrdersFactory.order().save(orderData).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            } else {
                service.order = data.order;
            }
            deferred.resolve();
        }, function(response) {
            console.log("Error adding order");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Update an existing order
    service.updateOrder = function(orderData) {
        var deferred = $q.defer();

        OrdersFactory.order().update(orderData).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            } else {
                service.order = data.order;
                service.warnings = data.warnings;
            }
            deferred.resolve();
        }, function(response) {
            console.log("Error updating order");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Add a new line item
    service.addLineItem = function(lineItemData) {
        var deferred = $q.defer();

        OrdersFactory.lineItem().save(lineItemData).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            } else {
                service.lineItem = data.lineItem;
                service.lineItemAttrs = (data.lineItemAttrs)?data.lineItemAttrs:null;
            }
            deferred.resolve();
        }, function(response) {
            console.log("Error adding line item");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.downloadGpt = function (params) {
        var deferred = $q.defer();
        $http.get(AppConfig.API_URL + 'adunitgpt/values/csv?' + 'linpid=' + params.linpid + '&' + 'networkId=' + params.networkId).then(function (response) {
            deferred.resolve(response);
        }, function (error) {
            deferred.reject();
        });
        return deferred.promise;
    };

    // Add a new flight
    service.addFlight = function(flightData) {
        var deferred = $q.defer();

        OrdersFactory.flight().save(flightData).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            } else {
                service.flight = data.flight;
                service.flightAttrs = (data.flightAttrs) ? data.flightAttrs : null;
            }
            deferred.resolve();
        }, function(response) {
            console.log("Error adding flight");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Add new flight task attributes
    service.addFlightTaskAttributes = function(flightTaskData) {
        var deferred = $q.defer();

        OrdersFactory.flightTaskAttrs().save(flightTaskData).$promise.then(function(data) {
            if (data.errors) {
                deferred.reject(data);
            } else {
                service.flight = data.flight;
                deferred.resolve(data)
            }
        }, function(response) {
            console.log("Error adding flight task attributes");
            deferred.reject(response);
        });

        return deferred.promise;
    };

    // Add new client task attributes
    service.addClientTaskAttributes = function(clientTaskData) {
        var deferred = $q.defer();

        OrdersFactory.clientTaskAttrs().save(clientTaskData).$promise.then(function(data) {
            service.client = data.client;
            deferred.resolve();
        }, function(response) {
            console.log("Error adding client task attributes");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Add new line item task attributes
    service.addLineItemTaskAttributes = function(lineItemTaskData) {
        var deferred = $q.defer();

        OrdersFactory.lineItemTaskAttrs().save(lineItemTaskData).$promise.then(function(data) {
            if (data.errors) {
                deferred.reject(data);
            } else {
                service.lineItem = data.lineItem;
                deferred.resolve();
            }
        }, function(response) {
            console.log("Error adding line item task attributes");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getOrderFileFieldAttrs = function(orderId, fieldId) {
        const deferred = $q.defer();
        const params = {
            'orderId': orderId,
            'formFieldId': fieldId,
        };

        OrdersFactory.orderFileFieldAttrs().get(params).$promise.then(function(data) {
            if (data && data.fieldInfo) {
                deferred.resolve(data.fieldInfo);
            } else {
                deferred.reject();
            }
        }, function(response) {
            console.log("Error getting order file field attributes for order "+orderId);
            deferred.reject();
        });

        return deferred.promise;
    }

    service.addOrderAttributes = function(orderData) {
        var deferred = $q.defer();
        OrdersFactory.orderAttrs().save(orderData).$promise.then(function(data) {
            if(data.order){
                service.order = data.order;
                deferred.resolve(data.order);
            }else{
                deferred.reject(data);
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    service.addOrderAttribute = function(orderData) {
        var deferred = $q.defer();
        OrdersFactory.addOrderAttr().save(orderData).$promise.then(function(data) {
            if(data.orderAttr){
                deferred.resolve();
            }else{
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // delete order file attribute and creatives
    service.removeOrderFileAttrsAndCreatives = function(orderAttrIds) {
        let deferred = $q.defer();
        OrdersFactory.removeOrderFileAttrsAndCreatives().delete(orderAttrIds).$promise.then(function(data) {
            if (data && data.ids) {
                deferred.resolve(data.ids);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    service.addOrderTaskAttributes = function(orderTaskData) {
        var deferred = $q.defer();
        OrdersFactory.orderTaskAttrs().save(orderTaskData).$promise.then(function(data) {
            if(data.order){
                service.order = data.order;
                deferred.resolve(data.order);
            }else{
                deferred.reject(data);
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // update line item attributes
    service.addLineItemAttributes = function(lineItemAttrData) {
        let deferred = $q.defer();
        OrdersFactory.lineItemAttrs().save(lineItemAttrData).$promise.then(function(data) {
            if (data && !data.errors) {
                deferred.resolve(data.lineItemAttr);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // update line item attributes
    service.updateLineItemAttributes = function(lineItemAttrData) {
        let deferred = $q.defer();
        OrdersFactory.lineItemAttrs().update(lineItemAttrData).$promise.then(function(data) {
            service.lineItemAttr = data.lineItemAttr;
            deferred.resolve(data.lineItemAttr);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // Let the backend know that all file uploads are completed for this line item.
    service.lineItemFileUploadCompleted = function(lineItemId, fileUploadKey, fieldInfo) {
        let deferred = $q.defer();
        let params = {
            'lineItemId': lineItemId,
            'fileUploadKey': fileUploadKey,
            'fieldInfo': fieldInfo,
        };
        OrdersFactory.lineItemFileUploadCompleted().update(params).$promise.then(function(data) {
            deferred.resolve(data.lineItem);
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    }

    // Let the backend know that all file uploads are completed for this flight.
    service.flightFileUploadCompleted = function(flightId, fileUploadKey, fieldInfo) {
        let deferred = $q.defer();
        let params = {
            'flightId': flightId,
            'fileUploadKey': fileUploadKey,
            'fieldInfo': fieldInfo,
        };
        OrdersFactory.flightFileUploadCompleted().update(params).$promise.then(function(data) {
            deferred.resolve(data.flight);
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    }

    // add new line item attribute
    service.addNewLineItemAttributes = function(lineItemAttrData) {
        let deferred = $q.defer();
        OrdersFactory.newLineItemAttrs().save(lineItemAttrData).$promise.then(function(data) {
            if (data && !data.errors) {
                deferred.resolve(data.lineItemAttr);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // add new flight attribute
    service.addNewFlightAttributes = function(flightAttrData) {
        let deferred = $q.defer();
        OrdersFactory.newFlightAttrs().save(flightAttrData).$promise.then(function(data) {
            if (data && !data.errors) {
                deferred.resolve(data.flightAttr);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // delete line item file attribute and creatives
    service.removeLineItemFileAttrsAndCreatives = function(lineItemAttrIds) {
        let deferred = $q.defer();
        OrdersFactory.removeLineItemFileAttrsAndCreatives().delete(lineItemAttrIds).$promise.then(function(data) {
            if (data && data.ids) {
                deferred.resolve(data.ids);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // delete flight file attribute and creatives
    service.removeFlightFileAttrsAndCreatives = function(flightAttrIds) {
        let deferred = $q.defer();
        OrdersFactory.removeFlightFileAttrsAndCreatives().delete(flightAttrIds).$promise.then(function(data) {
            if (data && data.ids) {
                deferred.resolve(data.ids);
            } else {
                deferred.reject(data.errors);
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // add new line item task attribute
    service.addOrderTaskAttribute = function(orderTaskAttrData) {
        let deferred = $q.defer();
        OrdersFactory.orderTaskAttr().save(orderTaskAttrData).$promise.then(function(data) {
            if (data && !data.errors) {
                deferred.resolve(data.orderTaskAttr);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // add new line item task attribute
    service.addNewLineItemTaskAttributes = function(lineItemTaskAttrData) {
        let deferred = $q.defer();
        OrdersFactory.newLineItemTaskAttrs().save(lineItemTaskAttrData).$promise.then(function(data) {
            if (data && !data.errors) {
                deferred.resolve(data.lineItemTaskAttr);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // add new flight task attribute
    service.addNewFlightTaskAttributes = function(flightTaskAttrData) {
        let deferred = $q.defer();
        OrdersFactory.newFlightTaskAttrs().save(flightTaskAttrData).$promise.then(function(data) {
            if (data && !data.errors) {
                deferred.resolve(data.flightTaskAttr);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // delete line item task file attribute and creatives
    service.removeOrderTaskFileAttrsAndCreatives = function(orderTaskAttrIds) {
        var deferred = $q.defer();
        OrdersFactory.removeOrderTaskFileAttrsAndCreatives().delete(orderTaskAttrIds).$promise.then(function(data) {
            if (data && data.ids) {
                deferred.resolve(data.ids);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // delete line item task file attribute and creatives
    service.removeLineItemTaskFileAttrsAndCreatives = function(lineItemTaskAttrIds) {
        var deferred = $q.defer();
        OrdersFactory.removeLineItemTaskFileAttrsAndCreatives().delete(lineItemTaskAttrIds).$promise.then(function(data) {
            if (data && data.ids) {
                deferred.resolve(data.ids);
            } else {
                deferred.reject();
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // delete flight task file attribute and creatives
    service.removeFlightTaskFileAttrsAndCreatives = function(flightTaskAttrIds) {
        let deferred = $q.defer();
        OrdersFactory.removeFlightTaskFileAttrsAndCreatives().delete(flightTaskAttrIds).$promise.then(function(data) {
            if (data && data.ids) {
                deferred.resolve(data.ids);
            } else {
                deferred.reject(data?.errors);
            }
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // update flight attributes
    service.updateFlightAttributes = function(flightAttrData) {
        var deferred = $q.defer();
        OrdersFactory.flightAttrs().update(flightAttrData).$promise.then(function(data) {
            service.flightAttrs = data.flightAttrs;
            deferred.resolve(data.lineItemAttr);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    // Add a campaign manager to a line item
    service.addCampaignManager = function(lineItemId, userId) {
        var deferred = $q.defer();
        var params = {
            lineItemId: lineItemId,
            userId: userId
        };

        OrdersFactory.addCampaignManager().save(params).$promise.then(function(data) {
            service.lineItem = data.lineItem;
            deferred.resolve();
        }, function(response) {
            console.log("Error adding campaign manager for line item "+lineItemId);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Add a campaign manager to a line item
    service.removeCampaignManager = function(lineItemId, userId) {
        var deferred = $q.defer();
        var params = {
            lineItemId: lineItemId,
            userId: userId
        };

        OrdersFactory.removeCampaignManager().save(params).$promise.then(function(data) {
            service.lineItem = data.lineItem;
            deferred.resolve(data.lineItem);
        }, function(response) {
            console.log("Error removing campaign manager for line item "+lineItemId);
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getFlights = function(lineItemId) {
        var deferred = $q.defer();
        var params = {
            'lineItemId': lineItemId
        };

        OrdersFactory.getFlights().get(params).$promise.then(function(data) {
            service.flights = data.flights;
            deferred.resolve(data.flights);
        }, function(response) {
            console.log("Error getting flights for line item "+lineItemId);
            deferred.reject(response);
        });

        return deferred.promise;
    };

    // Get a single flight by Id
    service.getFlight = function(flightId, taskAssignmentId = null) {
        let deferred = $q.defer();
        let params = {
            'id': flightId
        };
        if (taskAssignmentId !== null) {
            params.taskAssignmentId = taskAssignmentId;
        }

        OrdersFactory.flight().get(params).$promise.then(function(data) {
            service.flight = data.flight;
            service.flightAttrs = (data.flightAttrs)?data.flightAttrs:null;
            deferred.resolve();
        }, function(response) {
            console.log("Error getting flight "+flightId);
            deferred.reject({message: response.data.message, code: response.status});
        });

        return deferred.promise;
    };

    service.getflightSteps = function(flightId) {
        let deferred = $q.defer();
        let params = {
            'flightId': flightId
        };

        OrdersFactory.flightSteps().get(params).$promise.then(function(data) {
            deferred.resolve(data.workflowSteps);
        }, function(response) {
            console.log("Error getting flight steps for flight "+flightId);
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getFlightStatuses = function() {
        let deferred = $q.defer();

        OrdersFactory.flightStatus().get().$promise.then(function(data) {
            deferred.resolve(data.statuses);
        }, function(response) {
            console.log("Error getting flight statuses: " + response);
            deferred.reject(response);
        });

        return deferred.promise;
    };

    service.getClientStatuses = function() {
        let deferred = $q.defer();

        OrdersFactory.clientStatus().get().$promise.then(function(data) {
            deferred.resolve(data.statuses);
        }, function(response) {
            console.log("Error getting client statuses: " + response);
            deferred.reject(response);
        });

        return deferred.promise;
    };

    service.addOrderNotificationUsers = function(params) {
        var deferred = $q.defer();

        OrdersFactory.orderNotificationUsers().save(params).$promise.then(function(data) {
            service.orderNotificationUsers = data.orderNotificationUsers;
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

     // Add watchers for specified order
    service.addOrderWatchersList = function (orderId, userIds) {
        var deferred = $q.defer();
        var params = {
            'orderId': orderId,
            'userIds': userIds
        };

        OrdersFactory.addOrderWatchers().save(params).$promise.then(function (data) {
            if (data.orderWatchers) {
                deferred.resolve(data.orderWatchers);
            } else {
                deferred.reject();
            }
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Update watchers for specified order
    service.updateOrderWatchersList = function (orderId, userIds) {
        var deferred = $q.defer();
        var params = {
            'orderId': orderId,
            'userIds': userIds
        };

        OrdersFactory.updateOrderWatchers().save(params).$promise.then(function (data) {
            if (data.orderWatchers) {
                deferred.resolve(data.orderWatchers);
            } else {
                deferred.reject();
            }
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getOrderWatchersList = function (orderId) {
        var deferred = $q.defer();
        var params = {
            'orderId': orderId
        };

        OrdersFactory.getOrderWatchers().get(params).$promise.then(function (data) {
            if (data.orderWatchers) {
                deferred.resolve(data.orderWatchers);
            } else {
                deferred.reject();
            }
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.deleteOrderWatchersList = function (orderId, userIds) {
        var deferred = $q.defer();
        var params = {
            'orderId': orderId,
            'userIds': userIds
        };

        OrdersFactory.deleteOrderWatchers().delete(params).$promise.then(function (data) {
            deferred.resolve(data);
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.addOrderWatcher = function (orderId, userId) {
        var deferred = $q.defer();
        var params = {
            'orderId': orderId,
            'userId': userId
        };

        OrdersFactory.orderWatcher().save(params).$promise.then(function (data) {
            if (data.orderWatcher) {
                deferred.resolve(data.orderWatcher);
            } else {
                deferred.reject();
            }
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.deleteOrderWatcher = function (orderWatcherId) {
        var deferred = $q.defer();

        OrdersFactory.orderWatcher().delete({id: orderWatcherId}).$promise.then(function (data) {
            if (data.id) {
                deferred.resolve(data.id);
            } else {
                deferred.reject();
            }
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

      // add watchers for specified lineItem
      service.addLineItemWatchersList = function(lineItemId, userIds) {
        var deferred = $q.defer();
        var params = {
            'lineItemId': lineItemId,
            'userIds':userIds
        };

        OrdersFactory.addLineItemWatchers().save(params).$promise.then(function (data) {
            if (data.lineItemWatchers) {
                deferred.resolve(data.lineItemWatchers);
            } else {
                deferred.reject();
            }
        }, function (response) {
            deferred.reject();
        });
        return deferred.promise;
    };


     // Update watchers for specified Line Item
     service.updateLineItemWatchersList = function (lineItemId, userIds) {
        var deferred = $q.defer();
        var params = {
            'lineItemId': lineItemId,
            'userIds': userIds
        };
        OrdersFactory.updateLineItemWatchers().save(params).$promise.then(function (data) {
            if (data.lineItemWatchers) {
                deferred.resolve(data.lineItemWatchers);
            } else {
                deferred.reject();
            }
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getLineItemWatchersList = function(lineItemId) {
        var deferred = $q.defer();
        var params = {
            'lineItemId': lineItemId
        };

        OrdersFactory.getLineItemWatchers().get(params).$promise.then(function(data) {
            if(data.lineItemWatchers){
                deferred.resolve(data.lineItemWatchers);
            }else{
                deferred.reject();
            }
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.deleteLineItemWatchersList = function(lineItemId, userIds) {
        var deferred = $q.defer();
        var params = {
            'lineItemId': lineItemId,
            'userIds':userIds
        };
        OrdersFactory.deleteLineItemWatchers().delete(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.addLineItemWatcher = function (lineItemId, userId) {
        var deferred = $q.defer();
        var params = {
            'lineItemId': lineItemId,
            'userId': userId
        };

        OrdersFactory.lineItemWatcher().add(params).$promise.then(function (data) {
            if (data.lineItemWatcher) {
                deferred.resolve(data.lineItemWatcher);
            } else {
                deferred.reject();
            }
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.deleteLineItemWatcher = function (lineItemWatcherId) {
        var deferred = $q.defer();

        OrdersFactory.lineItemWatcher().delete({id: lineItemWatcherId}).$promise.then(function (data) {
            if (data.id) {
                deferred.resolve(data.id);
            } else {
                deferred.reject();
            }
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.copyOrder = function(orderId, options) {
        const deferred = $q.defer();
        const params = {
            id: orderId,
            isCopyingLineItem: options.isCopyingLineItem,
            copyArchivedLineItems: options.copyArchivedLineItems,
            isCopyingFlight: options.isCopyingFlight,
            copyArchivedFlights: options.copyArchivedFlights,
            clearTotalBudget: options.clearTotalBudget,
        };

        OrdersFactory.copyOrder().save(params).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            }

            deferred.resolve();
        }, function(response) {
            console.log(response.data.errors);
            deferred.reject(response.data.errors);
        });

        return deferred.promise;
    };

    service.orderSupportsFlights = function(orderId) {
        let deferred = $q.defer();
        let params = {orderId: orderId};

        OrdersFactory.orderSupportsFlights().get(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log(response.data.errors);
            deferred.reject(response.data.errors);
        });
        return deferred.promise;
    }

    // Copy a Line Item
    service.copyLineItem = function(lineItemId, orderId, clearTotalBudget) {
        var deferred = $q.defer();
        var params = {
            id: lineItemId,
            orderId: orderId,
            clearTotalBudget: clearTotalBudget
        };

        OrdersFactory.copyLineItem().save(params).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            } else {
                service.newLineItemId = data.newLineItemId;
            }

            deferred.resolve();
        }, function() {
            console.log("Error copying line item "+lineItemId);
            deferred.reject();
        });

        return deferred.promise;
    };

    service.copyToLineItem = function(originalLineItemId, targetLineItemId, clearTotalBudget) {
        var deferred = $q.defer();
        var params = {
            id: originalLineItemId,
            targetId: targetLineItemId,
            clearTotalBudget: clearTotalBudget
        };

        OrdersFactory.copyToLineItem().update(params).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            }

            deferred.resolve();
        }, function(response) {
            if (response.data.errors) {
                service.errors = response.data.errors;
            } else {
                console.log("Error copying to line item");
            }
            deferred.reject();
        });

        return deferred.promise;
    };

    // copy lineItem flights
    service.copyFlightsToLineItem = function(srcLineItemId, destLineItemId, copyArchivedFlights, clearTotalBudget) {
        var deferred = $q.defer();
        var params = {
            srcId: srcLineItemId,
            destId: destLineItemId,
            copyArchivedFlights: copyArchivedFlights,
            clearTotalBudget: clearTotalBudget
        };

        OrdersFactory.copyFlightsToLineItem().save(params).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            }

            deferred.resolve();
        }, function(response) {
            if (response.data.errors) {
                service.errors = response.data.errors;
            } else {
                console.log("Error copying flights to line item");
            }
            deferred.reject();
        });

        return deferred.promise;
    };

    // Copy a Flight
    service.copyFlight = function(flightId, clearTotalBudget) {
        var deferred = $q.defer();
        var params = {
            id: flightId,
            clearTotalBudget: clearTotalBudget
        };

        OrdersFactory.copyFlight().save(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function() {
            console.log("Error copying flight "+flightId);
            deferred.reject();
        });

        return deferred.promise;
    };

    service.moveFlight = function(flightIds, lineItemId, statusId, workflowStepId, workflowActionId) {
        var deferred = $q.defer();
        var params = {
            ids: flightIds,
            lineItemId: lineItemId,
            statusId: statusId,
            workflowStepId: workflowStepId,
            workflowActionId: workflowActionId
        };

        OrdersFactory.moveFlight().save(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function() {
            console.log("Error moving flights");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.autoCreateFlightsDryRun = function(lineItemId) {
        let deferred = $q.defer();
        let params = {
            lineItemId: lineItemId
        };

        OrdersFactory.autoCreateFlightsDryRun().get(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(error) {
            console.log("Error with autoCreateFlightsDryRun: " + error);
            deferred.reject(error);
        });

        return deferred.promise;
    };

    service.autoCreateFlights = function(lineItemId) {
        let deferred = $q.defer();
        let params = {
            lineItemId: lineItemId
        };

        OrdersFactory.autoCreateFlights().save(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(error) {
            console.log("Error with autoCreateFlights: " + error);
            deferred.reject(error);
        });

        return deferred.promise;
    };

    service.redistributeValuesDryRun = function(lineItemId) {
        let deferred = $q.defer();
        let params = {
            lineItemId: lineItemId
        };

        OrdersFactory.redistributeValuesDryRun().save(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(error) {
            console.log("Error with redistributeValuesDryRun: " + error);
            deferred.reject(error);
        });

        return deferred.promise;
    };

    service.redistributeValues = function(lineItemId) {
        let deferred = $q.defer();
        let params = {
            lineItemId: lineItemId
        };

        OrdersFactory.redistributeValues().save(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(error) {
            console.log("Error with redistributeValues: " + error);
            deferred.reject(error);
        });

        return deferred.promise;
    };

    service.regenerateFlightsDryRun = function(lineItemId) {
        let deferred = $q.defer();
        let params = {
            lineItemId: lineItemId
        };

        OrdersFactory.regenerateFlightsDryRun().get(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(error) {
            console.log("Error with regenerateFlightsDryRun: " + error);
            deferred.reject(error);
        });

        return deferred.promise;
    };

    service.regenerateFlights = function(lineItemId) {
        let deferred = $q.defer();
        let params = {
            lineItemId: lineItemId
        };

        OrdersFactory.regenerateFlights().save(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(error) {
            console.log("Error with regenerateFlights: " + error);
            deferred.reject(error);
        });

        return deferred.promise;
    };

    // Update an existing line item
    service.updateLineItem = function(lineItemData) {
        var deferred = $q.defer();

        OrdersFactory.lineItem().update(lineItemData).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            } else {
                service.lineItem = data.lineItem;
                service.lineItemAttrs = (data.lineItemAttrs)?data.lineItemAttrs:null;
            }
            deferred.resolve();
        }, function(response) {
            console.log("Error updating line item");
            deferred.reject(response);
        });

        return deferred.promise;
    };

    // Update an existing flight
    service.updateFlight = function(flightData) {
        var deferred = $q.defer();

        OrdersFactory.flight().update(flightData).$promise.then(function(data) {
            if (data.errors) {
                service.errors = data.errors;
            } else {
                service.flight = data.flight;
                service.flightAttrs = (data.flightAttrs) ? data.flightAttrs : null;
            }
            deferred.resolve();
        }, function(response) {
            console.log("Error updating flight");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Close an existing line item
    service.closeLineItem = function(lineItemId) {
        var deferred = $q.defer();
        var params = {
            'id': lineItemId
        };

        OrdersFactory.closeLineItem().update(params).$promise.then(function(data) {
            service.lineItem = data.lineItem;
            deferred.resolve();
        }, function(response) {
            console.log("Error closing line item "+lineItemId);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Close an existing flight
    service.closeFlight = function(flightId) {
        var deferred = $q.defer();
        var params = {
            'id': flightId
        };

        OrdersFactory.closeFlight().update(params).$promise.then(function(data) {
            service.flight = data.id;
            deferred.resolve();
        }, function(response) {
            console.log("Error closing flight "+flightId);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Cancel an existing order
    service.cancelOrder = function(orderId, cancelReason, cancelReasonText) {
        var deferred = $q.defer();
        var params = {
            'id': orderId,
            'cancelReason': cancelReason,
            'cancelReasonText': cancelReasonText
        };

        OrdersFactory.cancelOrder().update(params).$promise.then(function(data) {
            service.orderId = data.id;
            deferred.resolve();
        }, function(response) {
            console.log("Error canceling order "+orderId);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Edit a cancellation reason for a cancelled / submitted for cancellation order, lineitem, or flight
    service.editCancelReason = function(id, type, cancelReason, cancelReasonText) {
        var deferred = $q.defer();
        var params = {
            'id': id,
            'type': type,
            'cancelReason': cancelReason,
            'cancelReasonText': cancelReasonText
        };

        if (type == 'order') {
            OrdersFactory.editOrderCancelReason().update(params).$promise.then(function(data) {
                service.orderId = data.id;
                deferred.resolve();
            }, function(response) {
                console.log("error editing order cancel reason: " + response);
                deferred.reject();
            });
        } else if (type == 'lineitem') {
            OrdersFactory.editLineitemCancelReason().update(params).$promise.then(function(data) {
                service.lineItemId = data.id;
                deferred.resolve();
            }, function(response) {
                console.log("error editing line item cancel reason: " + response);
                deferred.reject();
            });
        } else if (type == 'flight') {
            OrdersFactory.editFlightCancelReason().update(params).$promise.then(function(data) {
                deferred.resolve(data.id);
            }, function(response) {
                console.log("error editing flight cancel reason: " + response);
                deferred.reject();
            });
        }
        return deferred.promise;
    };

    //add a cancel reason - admin
    service.addCancelReason = function(params) {
        var deferred = $q.defer();

        OrdersFactory.cancelReason().save(params).$promise.then(function(data) {
            service.cancelReason = data.cancelReason;
            deferred.resolve();
        }, function(response) {
            console.log("error saving cancel reason");
            deferred.reject();
        });

        return deferred.promise;
    };

    //update a cancel reason - admin
    service.updateCancelReason = function(params) {
        var deferred = $q.defer();

        OrdersFactory.cancelReason().update(params).$promise.then(function(data) {
            service.cancelReason = data.cancelReason;
            deferred.resolve();
        }, function(response) {
            console.log("error updating cancel reason");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.exportCancellationReasons = function() {
        const deferred = $q.defer();
        $http.get(AppConfig.IO_API_URL + 'orders/exportCancellationReasons', {}).then(function(response) {
            deferred.resolve(response);
        }, function(error) {
            console.log("error exporting cancellation reason");
            deferred.reject(error);
        });
        return deferred.promise;
    };

    service.importCancellationReasons = function(customFile) {
        const deferred = $q.defer();
        Upload.upload({
            url: AppConfig.IO_API_URL + 'orders/importCancellationReasons',
            file: customFile
        }).success(function(data, status, headers, config) {
            if (data.result){
                deferred.resolve(data.result);
            }else{
                deferred.reject();
            }
        }).error(function(error) {
            deferred.reject(error);
        });
        return deferred.promise;
    };

    // Cancel an existing line item
    service.cancelLineItem = function(lineItemId, cancelReason, cancelReasonText, isCancelRebook = false) {
        var deferred = $q.defer();
        var params = {
            'id': lineItemId,
            'cancelReason': cancelReason,
            'cancelReasonText': cancelReasonText
        };

        if (isCancelRebook) {
            params.isCancelRebook = 1;
        }

        OrdersFactory.cancelLineItem().update(params).$promise.then(function(data) {
            service.lineItemId = data.id;
            deferred.resolve();
        }, function(response) {
            console.log("Error canceling line item");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Cancel an existing flight
    service.cancelFlight = function(flightId, cancelReason, cancelReasonText) {
        var deferred = $q.defer();
        var params = {
            'id': flightId,
            'cancelReason': cancelReason,
            'cancelReasonText': cancelReasonText
        };

        OrdersFactory.cancelFlight().update(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("Error cancelling flight: " + response);
            deferred.reject(response);
        });

        return deferred.promise;
    };

    // Get an order buy sheet
    service.getBuySheet = function(orderId) {
        var deferred = $q.defer();
        OrdersFactory.buySheet().get({
            'id': orderId
        }).$promise.then(function(data) {
            service.buySheet = data.buy_sheet;
            deferred.resolve(data);
        }, function(response) {
            deferred.reject(response);

        });

        return deferred.promise;
    };

    service.updateOrderStatus = function(param) {
        var deferred = $q.defer();

        OrdersFactory.updateOrderStatus().update(param).$promise.then(function(data) {
            deferred.resolve(data.order);
        }, function(response) {
            console.log("Error updating order status");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkOrderStatus = function(param) {
        var deferred = $q.defer();

        OrdersFactory.updateBulkOrderStatus().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("Error bulk updating order status");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkLineItemStatus = function(param) {
        var deferred = $q.defer();

        OrdersFactory.updateBulkLineItemStatus().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("Error bulk updating line item status");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkFlightStatus = function(param) {
        let deferred = $q.defer();

        OrdersFactory.updateBulkFlightStatus().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("Error bulk updating flight status");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkClientStatus = function(param) {
        let deferred = $q.defer();

        OrdersFactory.updateBulkClientStatus().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("Error bulk updating client status");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkTaskStatus = function(param) {
        var deferred = $q.defer();

        OrdersFactory.updateBulkTaskStatus().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("Error bulk updating task status");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkSubmitUserForOrders = function(param) {
        let deferred = $q.defer();

        OrdersFactory.updateBulkSubmitUserForOrders().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("Error in bulk submit user for orders");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkCreateOrderUserForOrders = function(param) {
        let deferred = $q.defer();

        OrdersFactory.updateBulkCreateOrderUserForOrders().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkFinanceUserForOrders = function(param) {
        let deferred = $q.defer();

        OrdersFactory.updateBulkFinanceUserForOrders().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkSubmitUserForLineItems = function(param) {
        let deferred = $q.defer();

        OrdersFactory.updateBulkSubmitUserForLineItems().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkCreateOrderUserForLineItems = function(param) {
        let deferred = $q.defer();

        OrdersFactory.updateBulkCreateOrderUserForLineItems().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkCampaignManagerForLineItems = function(param) {
        let deferred = $q.defer();

        OrdersFactory.updateBulkCampaignManagerForLineItems().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.clearBulkCampaignManagerForLineItems = function(param) {
        let deferred = $q.defer();

        OrdersFactory.clearBulkCampaignManagerForLineItems().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function() {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateBulkDefaultValues = function(param) {
        let deferred = $q.defer();

        OrdersFactory.updateBulkDefaultValues().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.completeBulkOrder = function(param) {
        var deferred = $q.defer();

        OrdersFactory.completeBulkOrder().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.cancelBulkOrder = function(param) {
        var deferred = $q.defer();

        OrdersFactory.cancelBulkOrder().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.resetWorkflowBulkOrder = function(param) {
        var deferred = $q.defer();

        OrdersFactory.resetWorkflowBulkOrder().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.ThrowActionOrder = function(param) {
        var deferred = $q.defer();

        OrdersFactory.ThrowActionOrder().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.completeBulkLineItem = function(param) {
        var deferred = $q.defer();

        OrdersFactory.completeBulkLineItem().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.completeBulkFlight = function(param) {
        var deferred = $q.defer();

        OrdersFactory.completeBulkFlight().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.cancelBulkLineItem = function(param) {
        var deferred = $q.defer();

        OrdersFactory.cancelBulkLineItem().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.cancelBulkFlight = function(param) {
        var deferred = $q.defer();

        OrdersFactory.cancelBulkFlight().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.resetWorkflowLineItem = function(param) {
        var deferred = $q.defer();

        OrdersFactory.resetWorkflowLineItem().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.resetWorkflowFlight = function(param) {
        var deferred = $q.defer();

        OrdersFactory.resetWorkflowFlight().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.resetWorkflowClient = function(param) {
        var deferred = $q.defer();

        OrdersFactory.resetWorkflowClient().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.ThrowActionLineItem = function(param) {
        var deferred = $q.defer();

        OrdersFactory.ThrowActionLineItem().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.ThrowActionFlight = function(param) {
        var deferred = $q.defer();

        OrdersFactory.ThrowActionFlight().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.ThrowActionClient = function(param) {
        var deferred = $q.defer();

        OrdersFactory.ThrowActionClient().save(param).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateLineItemStatus = function(param) {
        var deferred = $q.defer();

        OrdersFactory.updateLineItemStatus().update(param).$promise.then(function(data) {
            deferred.resolve(data.lineItem);
        }, function(response) {
            console.log("Error updating line item status");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateFlightStatus = function(param) {
        var deferred = $q.defer();

        OrdersFactory.updateFlightStatus().update(param).$promise.then(function(data) {
            deferred.resolve(data.flight);
        }, function(response) {
            console.log("Error updating flight status");
            deferred.reject();
        });

        return deferred.promise;
    };

    service.updateCascadingField = function(lineItemId, fieldName, fieldValue) {
        var deferred = $q.defer();
        var params = {
            'id': lineItemId,
            'fieldName': fieldName,
            'fieldValue': fieldValue
        };

        OrdersFactory.updateCascadingField().update(params).$promise.then(function(data) {
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.workflowReset = function(workflowTypeId, entityId) {
        var deferred = $q.defer();
        OrdersFactory.workflowReset().update({ 'workflowTypeId': workflowTypeId, "entityId": entityId }, {}).$promise.then(function(data) {
            deferred.resolve();
        }, function(response) {
            deferred.reject(response.data.data);
        });

        return deferred.promise;
    };

    service.bulkLineItemStatus = function(reqData) {
        var deferred = $q.defer();
        OrdersFactory.bulkLineItemStatus().update(reqData).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getGoalsForLineItem = function(lineItemId) {
        var deferred = $q.defer();
        var params = {
            'id': lineItemId
        };

        OrdersFactory.goalsForLineItem().get(params).$promise.then(function(data) {
            deferred.resolve(data.goals);
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getGoalsForOrder = function(orderId) {
        var deferred = $q.defer();
        var params = {
            'id': orderId
        };

        OrdersFactory.goalsForOrder().get(params).$promise.then(function(data) {
            deferred.resolve(data.goals);
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    service.getWorkflowLogs = function(type, id) {
        let deferred = $q.defer();
        switch (type) {
            case 'client':
                OrdersFactory.getAllForClient().get({'clientId': id}).$promise.then(function(data) {
                    deferred.resolve(data.workflow_logs);
                }, function(response) {
                    deferred.reject();
                });
                break;
            case 'order':
                OrdersFactory.getAllForOrder().get({'orderId': id}).$promise.then(function(data) {
                    deferred.resolve(data.workflow_logs);
                }, function(response) {
                    deferred.reject();
                });
                break;
            case 'line_item':
                OrdersFactory.getAllForLineItem().get({'lineItemId': id}).$promise.then(function(data) {
                    deferred.resolve(data.workflow_logs);
                }, function(response) {
                    deferred.reject();
                });
                break;
            case 'flight':
                OrdersFactory.getAllForFlight().get({'flightId': id}).$promise.then(function(data) {
                    deferred.resolve(data.workflow_logs);
                }, function(response) {
                    deferred.reject();
                });
                break;
            default:
                console.log('Unknown workflow log type: ' + type);
                deferred.reject();
        }
        return deferred.promise;
    }

    service.getTaskAssignmentHistoryForEntity = function(type, id) {
        let deferred = $q.defer();
        switch (type) {
            case 'client':
                OrdersFactory.getAllTaskAssignmentHistoryForClient().get({'clientId': id}).$promise.then(function(data) {
                    deferred.resolve(data.history);
                }, function(response) {
                    deferred.reject();
                });
                break;
            case 'order':
                OrdersFactory.getAllTaskAssignmentHistoryForOrder().get({'orderId': id}).$promise.then(function(data) {
                    deferred.resolve(data.history);
                }, function(response) {
                    deferred.reject();
                });
                break;
            case 'line_item':
                OrdersFactory.getAllTaskAssignmentHistoryForLineItem().get({'lineItemId': id}).$promise.then(function(data) {
                    deferred.resolve(data.history);
                }, function(response) {
                    deferred.reject();
                });
                break;
            case 'flight':
                OrdersFactory.getAllTaskAssignmentHistoryForFlight().get({'flightId': id}).$promise.then(function(data) {
                    deferred.resolve(data.history);
                }, function(response) {
                    deferred.reject();
                });
                break;
            default:
                console.log('Unknown entity type for task assignment history: ' + type);
                deferred.reject();
        }
        return deferred.promise;
    }

    service.bulkRollupClients = function(clusterIds) {
        const deferred = $q.defer();
        const params = {
            'clusterIds': clusterIds
        };
        OrdersFactory.bulkRollupClients().update(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            deferred.reject();
        });
        return deferred.promise;
    };

    service.bulkRollupOrders = function(orderIds, clusterIds = []) {
        const deferred = $q.defer();
        let params = {
            'orderIds': orderIds
        };
        if (orderIds.length == 0) {
            params = {
                'clusterIds': clusterIds
            };
        }

        OrdersFactory.bulkRollupOrders().update(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    service.bulkRollupLineItems = function(productIds) {
        const deferred = $q.defer();
        const params = {
            'productIds': productIds
        };
        OrdersFactory.bulkRollupLineItems().update(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    service.bulkRollupFlights = function(productIds) {
        const deferred = $q.defer();
        const params = {
            'productIds': productIds
        };
        OrdersFactory.bulkRollupFlights().update(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    };

    service.getLookupTypesForContentKey = function(contentKey) {
        const deferred = $q.defer();
        const params = {
            'contentKey': contentKey
        };
        OrdersFactory.getLookupTypesForContentKey().get(params).$promise.then(function(data) {
            deferred.resolve(data);
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };

    return service;

}

/**
 * @ngInject
 */
function OrdersFactory($resource, AppConfig) {

    let factory = {};

    factory.dfpValues = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/getDfpValuesByKey', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.getLookupTypesForContentKey = function() {
        return $resource(AppConfig.IO_API_URL + 'lookups/typesForContentKey', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.orders = function() {
        return $resource(AppConfig.IO_API_URL + 'orders', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.ordersFlightsCounts = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/ordersFlightsCounts', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.orderPlacementProducts = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/orderPlacementProducts', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.placementDataForOrderAndProduct = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/placementDataForOrderAndProduct', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.lineItemDataForOrderAndProduct = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/lineItemDataForOrderAndProduct', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.assignPlacements = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/assignPlacements', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.ordersWithPagination = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/sparseOrders', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.openOrders = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/openOrders', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.openOrdersWorkflowSteps = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/openOrdersWorkflowSteps', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.ordersWorkflowSteps = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/ordersWorkflowSteps', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.archivedOrders = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/archivedOrders', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.ordersForSalesperson = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/ordersForSalesperson', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.openOrdersForSalesperson = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/openOrdersForSalesperson', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.openOrdersWorkflowStepsForSalesperson = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/openOrdersWorkflowStepsForSalesperson', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.archivedOrdersForSalesperson = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/archivedOrdersForSalesperson', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };


    factory.openOrdersForClient = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/openOrdersForClient', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.archivedOrdersForClient = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/archivedOrdersForClient', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.order = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/order', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.cancelReason = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/cancelReason', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.stepsForOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'workflows/workflowStepsForOrder', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.stepsForFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'workflows/workflowStepsForFlight', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.orderAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/orderAttrs', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.addOrderAttr = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/addOrderAttr', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
        });
    };

    factory.removeOrderFileAttrsAndCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/removeOrderFileAttrsAndCreatives', null, {
            'delete': {
                method: 'DELETE',
                isArray: false
            },
        });
    };

    factory.orderFileFieldAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/orderFileFieldAttrs', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.orderTaskAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/orderTaskAttrs', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.lineItemAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/lineItemAttr', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.lineItemFileUploadCompleted = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/fileUploadCompleted', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.flightFileUploadCompleted = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/fileUploadCompleted', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.flightAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/flightAttr', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.lineItemAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/lineItemAttr', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.newLineItemAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/addNewLineItemAttr', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
        });
    };

    factory.newFlightAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/addNewFlightAttr', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
        });
    };

    factory.lineItemFileFieldAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/lineItemFileFieldAttrs', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.flightHasAsynchronousAttrUpdates = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/hasAsynchronousAttrUpdates', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.lineItemHasAsynchronousAttrUpdates = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/hasAsynchronousAttrUpdates', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.removeLineItemFileAttrsAndCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/removeLineItemFileAttrsAndCreatives', null, {
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };

    factory.removeFlightFileAttrsAndCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/removeFlightFileAttrsAndCreatives', null, {
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };

    factory.orderTaskAttr = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/orderTaskAttr', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
        });
    };

    factory.newLineItemTaskAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/addNewLineItemTaskAttr', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
        });
    };

    factory.newFlightTaskAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/addNewFlightTaskAttr', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
        });
    };

    factory.orderTaskFileFieldAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/orderTaskFileFieldAttrs', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.lineItemTaskFileFieldAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/lineItemTaskFileFieldAttrs', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.flightFileFieldAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/flightFileFieldAttrs', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.flightTaskFileFieldAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/flightTaskFileFieldAttrs', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.removeOrderTaskFileAttrsAndCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/removeOrderTaskFileAttrsAndCreatives', null, {
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };

    factory.removeLineItemTaskFileAttrsAndCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/removeLineItemTaskFileAttrsAndCreatives', null, {
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };

    factory.removeFlightTaskFileAttrsAndCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/removeFlightTaskFileAttrsAndCreatives', null, {
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };

    factory.orderTypes = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/orderTypes', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };


    factory.cancelReasons = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/cancelReasons', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.orderNotificationUsers = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/orderNotificationUsers', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };


    factory.addOrderWatchers = function() {
        return $resource(AppConfig.IO_API_URL + 'orderWatchers/addOrderWatchers', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.updateOrderWatchers = function() {
        return $resource(AppConfig.IO_API_URL + 'orderWatchers/updateOrderWatchers', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.getOrderWatchers = function() {
        return $resource(AppConfig.IO_API_URL + 'orderWatchers/getOrderWatchers', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };


    factory.deleteOrderWatchers = function() {
        return $resource(AppConfig.IO_API_URL + 'orderWatchers/deleteOrderWatchers', null, {
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };


    factory.orderWatcher = function() {
        return $resource(AppConfig.IO_API_URL + 'orderWatchers/orderWatcher', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            },
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };


    factory.addLineItemWatchers = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItemWatchers/addLineItemWatchers', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.updateLineItemWatchers = function () {
        return $resource(AppConfig.IO_API_URL + 'lineItemWatchers/updateLineItemWatchers', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };


    factory.getLineItemWatchers = function () {
        return $resource(AppConfig.IO_API_URL + 'lineItemWatchers/getLineItemWatchers', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };


    factory.deleteLineItemWatchers = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItemWatchers/deleteLineItemWatchers', null, {
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };
    
    factory.lineItemWatcher = function () {
        return $resource(AppConfig.IO_API_URL + 'lineItemWatchers/lineItemWatcher', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'add': {
                method: 'POST',
                isArray: false
            },
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };

    factory.lineItems = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.formatOEAPreview = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/formatOEAPreview', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.lineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/lineItem', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.flight = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/flight', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.lineItemSteps = function() {
        return $resource(AppConfig.IO_API_URL + 'workflows/workflowStepsForLineItem', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.lineItemTaskAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/lineItemTaskAttrs', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.closeLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/closeLineItem', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.closeFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/closeFlight', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.addCampaignManager = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/addCampaignManager', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.removeCampaignManager = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/removeCampaignManager', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.getFlights = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/getFlights', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.flight = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/flight', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.flightStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'statuses/flightStatuses', {

        }, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.updateFlightStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/updateFlightStatus', {

        }, {
            'update': {
                method: 'PUT'
            }
        })
    };

    factory.flightSteps = function() {
        return $resource(AppConfig.IO_API_URL + 'workflows/workflowStepsForFlight', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.flightTaskAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/flightTaskAttrs', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.clientTaskAttrs = function() {
        return $resource(AppConfig.IO_API_URL + 'clients/clientTaskAttrs', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.clientStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'statuses/clientStatuses', {

        }, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.copyOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/clone', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.orderSupportsFlights = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/orderSupportsFlights', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.copyLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/clone', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.copyFlightsToLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/copyFlightsToLineItem', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.copyFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/clone', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.moveFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/move', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.autoCreateFlightsDryRun = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/autoCreateFlightsDryRun', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.autoCreateFlights = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/autoCreateFlights', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.redistributeValuesDryRun = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/redistributeValuesDryRun', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.redistributeValues = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/redistributeValues', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.regenerateFlightsDryRun = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/regenerateFlightsDryRun', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.regenerateFlights = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/regenerateFlights', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.copyLineItemCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'creatives/clone', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.copyToLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/copyTo', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        })
    };

    factory.cancelOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/cancelOrder', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.editOrderCancelReason = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/editCancelReason', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.editLineitemCancelReason = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/editCancelReason', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.editFlightCancelReason = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/editCancelReason', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.cancelLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/cancelLineItem', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.cancelFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'flights/cancelFlight', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.search = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/search', null, {
            'get': {
                method: 'GET'
            }
        });
    };

    factory.searchLineItems = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/searchLineItems', null, {
            'get': {
                method: 'GET'
            }
        });
    };

    factory.statuses = function() {
        return $resource(AppConfig.IO_API_URL + 'statuses', {
            'typeId': '1'
        }, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.entityActivity = function(tableName) {
        return $resource(AppConfig.IO_API_URL + 'activityLogs/activityLogForEntityAndRow', {
            'tableName': tableName
        }, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.lineItemForm = function(item) {
        return $resource(AppConfig.API_JSON_URL + item, {
            'get': {
                method: 'GET',
                isArray: true
            }
        })
    };

    factory.buySheet = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/buySheet', {

        }, {
            'get': {
                method: 'GET',
                isArray: false
            }
        })
    };

    factory.updateOrderStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'orders/updateOrderStatus', {

        }, {
            'update': {
                method: 'PUT'
            }
        })
    };

    factory.updateBulkOrderStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateStatusForOrders', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkTaskStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateStatusForTaskAssignments', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkSubmitUserForOrders = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateSubmitUserForOrders', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkCreateOrderUserForOrders = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateCreateOrderUserForOrders', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkFinanceUserForOrders = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateFinanceUserForOrders', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkSubmitUserForLineItems = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateSubmitUserForLineItems', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkCreateOrderUserForLineItems = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateCreateOrderUserForLineItems', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkCampaignManagerForLineItems = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateCampaignManagerForLineItems', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.clearBulkCampaignManagerForLineItems = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/clearCampaignManagerForLineItems', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkDefaultValues = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateBulkDefaultValues', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.completeBulkOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/completeOrders', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.cancelBulkOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/cancelOrders', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.resetWorkflowBulkOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/resetWorkflowForOrders', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.ThrowActionOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/executeWorkflowForOrders', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.completeBulkLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/completeLineItems', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.completeBulkFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/completeFlights', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.cancelBulkLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/cancelLineItems', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.cancelBulkFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/cancelFlights', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.resetWorkflowLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/resetWorkflowForLineItems', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.resetWorkflowFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/resetWorkflowForFlights', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.resetWorkflowClient = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/resetWorkflowForClients', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.ThrowActionLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/executeWorkflowForLineItems', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.ThrowActionFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/executeWorkflowForFlights', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.ThrowActionClient = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/executeWorkflowForClients', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkLineItemStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateStatusForLineItems', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkFlightStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateStatusForFlights', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.updateBulkClientStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/updateStatusForClients', {

        }, {
            'save': {
                method: 'POST',
                isArray: false
            }
        })
    };

    factory.bulkRollupClients = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/bulkRollupClients', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.bulkRollupOrders = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/bulkRollupOrders', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.bulkRollupLineItems = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/bulkRollupLineItems', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.bulkRollupFlights = function() {
        return $resource(AppConfig.IO_API_URL + 'bulk/bulkRollupFlights', null, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    factory.lineItemStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'statuses/lineItemStatuses', {

        }, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.updateLineItemStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/updateLineItemStatus', {

        }, {
            'update': {
                method: 'PUT'
            }
        })
    };

    factory.updateCascadingField = function() {
        return $resource(AppConfig.IO_API_URL + 'lineItems/updateCascadingField', {

        }, {
            'update': {
                method: 'PUT'
            }
        })
    };

    factory.workflowReset = function() {
        return $resource(AppConfig.API_URL + 'workflows/reset/:workflowTypeId/:entityId', {

        }, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        })
    };

    factory.bulkLineItemStatus = function() {
        return $resource(AppConfig.IO_API_URL + 'PushIntegration/LineItemStatusUpdate', {

        }, {
            'update': {
                method: 'PUT',
                isArray: false
            }
        })
    };

    factory.dfpValuesById = function() {
        return $resource(AppConfig.API_URL + 'caching/values', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.goalsForLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'goals/goalsForLineItem', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.goalsForOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'goals/goalsForOrder', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.getAllForClient = function() {
        return $resource(AppConfig.IO_API_URL + 'workflowLogs/getAllForClient', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.getAllForOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'workflowLogs/getAllForOrder', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.getAllForLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'workflowLogs/getAllForLineItem', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.getAllForFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'workflowLogs/getAllForFlight', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.getAllTaskAssignmentHistoryForClient = function() {
        return $resource(AppConfig.IO_API_URL + 'taskAssignmentHistory/getAllForClient', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.getAllTaskAssignmentHistoryForOrder = function() {
        return $resource(AppConfig.IO_API_URL + 'taskAssignmentHistory/getAllForOrder', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.getAllTaskAssignmentHistoryForLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'taskAssignmentHistory/getAllForLineItem', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.getAllTaskAssignmentHistoryForFlight = function() {
        return $resource(AppConfig.IO_API_URL + 'taskAssignmentHistory/getAllForFlight', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    return factory;
}

/**
 * @ngInject
 */
function StatusService(
    StatusFactory,
    $q,
    $rootScope,
    Upload,
    AppConfig,
    $http
) {
    let service = {};
    service.statuses = null;
    service.errors = null;

    // Get all statuses
    service.getAllStatuses = function(type_id, custom, active) {
        const deferred = $q.defer();
        const params = {
            'typeId': type_id,
            'custom': custom,
            'active': active,
        };

        StatusFactory.statuses().get(params).$promise.then(function(data) {
            service.statuses = data.statuses;
            deferred.resolve(data);
        }, function(response) {
            console.log("error getting all statuses");
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get all order statuses
    service.getOrderStatuses = function() {
        const deferred = $q.defer();

        StatusFactory.orderStatuses().get().$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error getting order statuses");
            deferred.reject(response);
        });

        return deferred.promise;
    };

    // Get all statuses
    service.getAllStatusTypes = function() {
        const deferred = $q.defer();

        StatusFactory.statusTypes().get().$promise.then(function(data) {
            deferred.resolve(data);
        }, function(response) {
            console.log("error getting all statuses");
            deferred.reject();
        });

        return deferred.promise;
    };

    // add a new custom status
    service.addCustomStatus = function(params) {
        const deferred = $q.defer();
        params.mutable = 1;
        StatusFactory.status().save(params).$promise.then(function(data) {
            if (!data.errors) {
                deferred.resolve(data);
            } else {
                deferred.reject(data.errors);
            }
        }, function(response) {
            console.log("error getting all statuses");
            deferred.reject();
        });

        return deferred.promise;
    };

    // add a new custom status
    service.updateCustomStatus = function(params) {
        const deferred = $q.defer();
        params.mutable = 1;
        StatusFactory.status().update(params).$promise.then(function(data) {
            if (!data.errors) {
                deferred.resolve(data);
            } else {
                deferred.reject(data.errors);
            }
        }, function(response) {
            console.log("error getting all statuses");
            deferred.reject();
        });

        return deferred.promise;
    };

    return service;
}

/**
 * @ngInject
 */
function StatusFactory($resource, AppConfig) {

    let factory = {};

    factory.statuses = function () {
        return $resource(AppConfig.IO_API_URL + 'statuses/getAllStatuses', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.orderStatuses = function () {
        return $resource(AppConfig.IO_API_URL + 'statuses/orderStatuses', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.statusTypes = function () {
        return $resource(AppConfig.IO_API_URL + 'statuses/statusTypes', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.status = function () {
        return $resource(AppConfig.IO_API_URL + 'statuses/status', null, {
            'save': {
                method: 'POST',
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            }
        });
    };

    return factory;
}

/**
 * @ngInject
 */
function MediaService(
    MediaFactory,
    $q,
    Upload,
    AppConfig
) {
    var service = {};
    service.creatives = null;
    service.flightCreatives = null;
    service.creative = null;
    service.deletedId = null;
    service.errors = null;

    // Get all creatives for a line item
    service.getLineItemCreatives = function(lineItemId) {
        var deferred = $q.defer();
        var params = {
            'lineItemId': lineItemId
        };

        MediaFactory.lineItemCreatives().get(params).$promise.then(function(data) {
            service.creatives = data.creatives;
            deferred.resolve();
        }, function(response) {
            console.log("error getting creatives for line item: " + lineItemId);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get all creatives for an order
    service.getOrderCreatives = function(orderId) {
        var deferred = $q.defer();
        var params = {
            'orderId': orderId
        };

        MediaFactory.orderCreatives().get(params).$promise.then(function(data) {
            service.creatives = data.creatives;
            deferred.resolve();
        }, function(response) {
            console.log("error getting creatives for order: " + orderId);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get all creatives for a flight
    service.getFlightCreatives = function(flightId) {
        var deferred = $q.defer();
        var params = {
            'flightId': flightId
        };

        MediaFactory.flightCreatives().get(params).$promise.then(function(data) {
            service.creatives = data.creatives;
            deferred.resolve();
        }, function(response) {
            console.log("error getting creatives for flight: " + flightId);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get all creatives for a client
    service.getClientCreatives = function(clientId) {
        var deferred = $q.defer();
        var params = {
            'clientId': clientId
        };

        MediaFactory.clientCreatives().get(params).$promise.then(function(data) {
            service.creatives = data.creatives;
            deferred.resolve();
        }, function(response) {
            console.log("error getting creatives for client: " + clientId);
            deferred.reject();
        });

        return deferred.promise;
    };

    // Get a single creative
    service.getCreative = function(creativeId) {
        var deferred = $q.defer();
        var params = {
            'id': creativeId
        };

        MediaFactory.creative().get(params).$promise.then(function(data) {
            service.creative = data.creative;
            deferred.resolve();
        }, function(response) {
            console.log("error getting creative for id: " + creativeId);
            deferred.reject();
        });

        return deferred.promise;
    };

    service.saveClientCreative = function(clientId, creativeFile) {
        var deferred = $q.defer();
        var params = {
            clientId: clientId,
            libraryCreative: "yes"
        };

        Upload.upload({
            url: AppConfig.IO_API_URL + 'creatives/creative',
            fields: params,
            file: creativeFile
        }).success(function(data, status, headers, config) {
            service.creative = data.creative;

            if (data.errors) {
                service.errors = data.errors;
            }

            deferred.resolve();

        }).error(function(error) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Add a new line item creative
    service.saveLineItemCreative = function(lineItemId, creativeFile, isSharedTask, addToLibrary) {
        var deferred = $q.defer();
        var params = { lineItemId: lineItemId };

        if (isSharedTask) {
            params.sharedTask = 1;
        }
        if (addToLibrary) {
            params.libraryCreative = addToLibrary;
        }

        Upload.upload({
            url: AppConfig.IO_API_URL + 'creatives/creative',
            fields: params,
            file: creativeFile
        }).success(function(data, status, headers, config) {
            service.creative = data.creative;

            if (data.errors) {
                service.errors = data.errors;
            }

            deferred.resolve();

        }).error(function(error) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Add a new order creative
    service.saveOrderCreative = function(orderId, creativeFile) {
        var deferred = $q.defer();

        Upload.upload({
            url: AppConfig.IO_API_URL + 'creatives/creative',
            fields: {
                orderId: orderId
            },
            file: creativeFile
        }).success(function(data, status, headers, config) {
            service.creative = data.creative;

            if (data.errors) {
                service.errors = data.errors;
            }

            deferred.resolve();

        }).error(function(error) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Clone creatives from one line item to another
    service.cloneLineItemCreatives = function(sourceLineItemId, destLineItemId) {
        var deferred = $q.defer();
        var params = {
            'sourceLineItemId': sourceLineItemId,
            'destLineItemId': destLineItemId
        };

        MediaFactory.cloneLineItem().save(params).$promise.then(function(data) {
            service.creatives = data.newCreatives;
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Clone creatives from one flight to another
    service.cloneFlightCreatives = function(sourceFlightId, destFlightId) {
        var deferred = $q.defer();
        var params = {
            'sourceFlightId': sourceFlightId,
            'destFlightId': destFlightId
        };

        MediaFactory.cloneFlightCreatives().save(params).$promise.then(function(data) {
            service.flightCreatives = data.newCreatives;
            deferred.resolve();
        }, function(response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    // Update a single creative
    service.updateCreative = function(params) {
        const deferred = $q.defer();
        MediaFactory.creative().update(params).$promise.then(function(data) {
            if (!data.errors) {
                deferred.resolve(data.creative);
            } else {
                deferred.reject(data.errors);
            }
        }, function(response) {
            console.log("error adding creative for " + response);
            deferred.reject(response);
        });

        return deferred.promise;
    };

    // Delete a single creative
    service.deleteCreative = function(creativeId) {
        let deferred = $q.defer();
        const params = {
            'id': creativeId
        };

        MediaFactory.creative().delete(params).$promise.then(function(data) {
            service.deletedId = data.id;
            deferred.resolve(data);
        }, function(response) {
            console.log("error deleting creative for id: " + creativeId);
            deferred.reject();
        });

        return deferred.promise;
    };

    return service;

}

/**
 * @ngInject
 */
function MediaFactory($resource, AppConfig) {
    var factory = {};

    factory.lineItemCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'creatives/creativesForLineItem', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.orderCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'creatives/creativesForOrder', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.flightCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'creatives/creativesForFlight', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.clientCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'creatives/creativesForClient', null, {
            'get': {
                method: 'GET',
                isArray: false
            }
        });
    };

    factory.creative = function() {
        return $resource(AppConfig.IO_API_URL + 'creatives/creative', null, {
            'get': {
                method: 'GET',
                isArray: false
            },
            'save': {
                method: 'POST',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                isArray: false
            },
            'update': {
                method: 'PUT',
                isArray: false
            },
            'delete': {
                method: 'DELETE',
                isArray: false
            }
        });
    };

    factory.cloneLineItem = function() {
        return $resource(AppConfig.IO_API_URL + 'creatives/clone', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    factory.cloneFlightCreatives = function() {
        return $resource(AppConfig.IO_API_URL + 'creatives/cloneForFlight', null, {
            'save': {
                method: 'POST',
                isArray: false
            }
        });
    };

    return factory;

}