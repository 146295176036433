'use strict';
import angular from 'angular';
import _ from 'lodash';

import {$WidgetBuilderEvents} from 'coreModules/design/widget/builder/widget.builder.constants';
import {TapMapConfig} from "../../../widget/design.widget.constants";

angular.module('drawoptionpanel.services', [])
    .constant('$DrawOptionPanelEvents', DrawOptionPanelEvents())
    .factory('DrawOptionPanelFactory', DrawOptionPanelFactory)
    .factory('DrawOptionFactory', DrawOptionFactory)
    .factory('DrawOptionsPanelParser', DrawOptionsPanelParser);

function DrawOptionPanelEvents() {
    return {
        REPORT_STUDIO_WIDGET_DRAW_OPTIONS: 'DrawOptionPanelEvents:REPORT_STUDIO_WIDGET_DRAW_OPTIONS',
        REPORT_STUDIO_REPORT_DRAW_OPTIONS: 'DrawOptionPanelEvents:REPORT_STUDIO_REPORT_DRAW_OPTIONS',
        EDIT_WIDGET_DRAW_OPTIONS: 'DrawOptionPanelEvents:EDIT_WIDGET_DRAW_OPTIONS',
        LAYOUT_DRAW_OPTIONS: 'DrawOptionPanelEvents:LAYOUT_DRAW_OPTIONS'
    }
}

/**
 * @ngInject
 */
function DrawOptionPanelFactory(
    $q,
    $DrawOptionPanelEvents,
    LoadingState,
    LayoutFactory,
    WidgetFactory,
    DataSourceType,
    DataSourceFactory,
    ReportStudioTemplateDataService,
    DrawOptionFactory,
    DrawOption,
    SlidePanelFactory,
    $SlidePanelEvents,
    PubSub,
    DesignFactory,
    YAxisPosition,
    DrawOptionsPanelParser,
    DashboardContextService,
    WidgetUtilService,
    ChartPlotType,
    HandTypes,
    GaugeBigNumberPosition,
) {

    var defaultProps = {
        panelId: null,
        options: null,
        state: {
            isLoading: true,
            isShowing: false
        },
        data: {
            widgetDrawOptions: [],
            widgetTypeDrawOptions: [],
            widgetTypeTabTitle: null,
            reportDrawOptions: []
        }
    };
    var props = angular.copy(defaultProps);

    PubSub.on($SlidePanelEvents.CLOSE_ALL, function (sourcePanel) {
        if (props.options && sourcePanel !== props.options.panelId) {
            closePanel();
        }
    });

    return {
        setProps: setProps,
        setInit: setInit,
        unSet: unSet,
        initializeWidgetCreateOptions: initializeWidgetCreateOptions,
        initializeDashboardOptions: initializeDashboardOptions,
        initializeExportReportOptions: initializeExportReportOptions,
        initializeExportWidgetOptions: initializeExportWidgetOptions,
        retrievePanelDrawOptions: retrievePanelDrawOptions,
        closePanel: closePanel,
        updateIfNeeded: updateIfNeeded,
        updateDrawOptions: updateDrawOptions,
        getIsLoading: getIsLoading,
        getIsShowing: getIsShowing,
        getLayoutDrawOptions: getLayoutDrawOptions,
        getPanelId: getPanelId,
        getSelectedWidgetType: getSelectedWidgetType
    };

    /**
     * Initializes draw options panel and sets defaut props
     * @param item
     * layoutId: null
     * isLayout: null
     * isExport: null
     * drawOptions: null
     * widgetTypeId: null
     * headerTitle: null
     * onWidgetSave: null
     */
    function setProps(item) {

        props.options = item;
        props.panelId = item.panelId;

    }

    function setInit(item) {
        // Stop execution if already active
        if (props.panelId === item.panelId) {
            return;
        }

        setProps(item);

        SlidePanelFactory.show(props.options);
        props.state.isShowing = true;
    }

    /**
     * to check the widget type
     * @returns {boolean}
     */
    function getSelectedWidgetType() {
       return WidgetUtilService.isDataGrid(props.options.widgetTypeId);
    }

    /**
     * Inverse on setInit, resets Panel state
     */
    function unSet() {
        _resetState()
    }

    /**
     * @param options
     * @returns {Promise}
     */
    function retrievePanelDrawOptions(options) {
        options = options || props.options;
        var deferred = $q.defer();

        _getDrawOptions(options, function (data) {
            deferred.resolve(data);
        });

        return deferred.promise;
    }

    /**
     * Call method to create initializer options to be passed to component bindings
     */
    function initializeWidgetCreateOptions(options) {
        options.isLayout = false;
        options.isExport = false;
        options.autoPersist = false;
        return angular.copy(options);
    }

    function initializeDashboardOptions(options) {
        options.isLayout = true;
        options.isExport = false;
        options.autoPersist = true;
        return angular.copy(options);
    }

    function initializeExportReportOptions(options) {
        options.isLayout = true;
        options.isExport = true;
        options.autoPersist = true;
        return angular.copy(options);
    }

    function initializeExportWidgetOptions(options) {
        options.isLayout = false;
        options.isExport = true;
        options.autoPersist = true;
        return angular.copy(options);
    }

    /**
     * Closes DrawOptionPanel
     */
    function closePanel() {
        if (!props.options) return;
        SlidePanelFactory.hide(props.options);
        _resetState();
    }

    /**
     * Re-initializes default props
     * @private
     */
    function _resetState() {
        props = angular.copy(defaultProps);
    }

    /**
     * @private
     * @param item
     * @param cb
     * @private
     */
    function _getDrawOptions(item, cb) {
        var params = {};
        var drawLocation = item.isExport ? DrawOption.LOCATION_EXPORT : DrawOption.LOCATION_DASHBOARD;

        // We have a widget
        // type specified in this case
        if (!item.isLayout) {
            if (!_.isEmpty(ReportStudioTemplateDataService.getReport())) {
                const reportLanguage = ReportStudioTemplateDataService.getReportLanguage();
                params = {fields: 'draw_options', lang: reportLanguage}
            }
            else {
                params = {fields: 'draw_options'}
            }
        }

        const skipDrawOptions = DashboardContextService.resolveSkipDrawOptions(item.widgetTypeId);
        if (skipDrawOptions.length) {
            params.skip_draw_options = skipDrawOptions;
        }

        if (!_.isEmpty(item.datasource) && item.datasource.type === DataSourceType.SEO_WEBSITES) {
            params.skip_draw_options = DrawOption.SHOW_TOTAL_ROW;
        }

        // Note: this loadingState is local to the directive controlled by this controller, it should not be confused with loadingState,
        // this way the loading of draw options will not set the widget to is loading
        props.state.isLoading = LoadingState.FETCHING;

        WidgetFactory.getMetadataColumnValues(item.widgetTypeId, drawLocation, params)
            .then(function (json) {
                var data = json.plain();

                // Remove these until we support on all data source types
                if (!_.isEmpty(item.datasource) && !DataSourceFactory.dataSourceContainsServices(item.datasource.type)) {
                    _.remove(data[DrawOption.TYPE_WIDGET], {key: DrawOption.GRID_FULL_IMAGE_SIZE});
                    _.remove(data[DrawOption.TYPE_WIDGET], {key: DrawOption.GRID_PREVIEW_AS_IFRAME});
                    _.remove(data[DrawOption.TYPE_WIDGET], {key: DrawOption.GRID_IFRAME_CAPTURE_DELAY});
                    if (item.datasource.type !== DataSourceType.LEADS) {
                        _.remove(data[DrawOption.TYPE_WIDGET], {key: DrawOption.PIVOT_GRID});
                    }
                }

                var drawPanelOptions = DrawOptionsPanelParser.parse(data, item.widgetTypeId, item.drawOptions);
                props.data.widgetTypeTabTitle = drawPanelOptions.widgetTypeTabTitle;
                props.data.widgetTypeDrawOptions = drawPanelOptions.widgetTypeDrawOptions;
                props.data.widgetDrawOptions = drawPanelOptions.widgetDrawOptions;
                props.data.reportDrawOptions = drawPanelOptions.reportDrawOptions;

                setTotalRowBottomStatus(data.widget_type, item);

                props.state.isLoading = LoadingState.DONE;
                cb(props.data);
            });
    }

    /**
     * Verify if drawOptionItem change requires an UI update
     * @param drawOptionItem
     */
    //TODO: maybe send an event. These services could suscribe to it to reduce coupling
    function updateIfNeeded(drawOptionItem) {
        if (WidgetUtilService.isDataGrid(props.options.widgetTypeId) && [DrawOption.PLOT_TYPE, DrawOption.SHOW_TOTAL_ROW].includes(drawOptionItem.key)) {
            setTotalRowBottomStatus(props.data.widgetTypeDrawOptions, props.options);
        }
        let isAmChartWidget = false;
        let drawOptions = props.options.drawOptions;
        if (WidgetUtilService.isAm5Chart(props.options.widgetTypeId, drawOptions && drawOptions.plot_type)) {
            isAmChartWidget = true;
            if (WidgetUtilService.isGaugeChart(props.options.widgetTypeId) && drawOptionItem.key === DrawOption.PLOT_TYPE) {
                updateGaugeDefaultValues(props.options);
            }
            if (WidgetUtilService.isBarChart(props.options.widgetTypeId) && drawOptionItem.key === DrawOption.PLOT_TYPE) {
                updateBarChartDefaultValues(props.options);
            }
        }
        if (DrawOptionFactory.drawOptionRequiresRedraw(drawOptionItem.key, isAmChartWidget)) {
            if (props.options.isLayout) {
                //rebuild the whole layout
                LayoutFactory.$rebuildAllWidgets();
            } else if (props.options.widgetId) {
                //rebuild the targeted widget
                WidgetFactory.$rebuildWidget(props.options.widgetId, props.options.isExport);
            }
        }
    }

    /**
     * When the plot type is changed on a gauge between the two new options make sure we set some
     * sensible defaults so the chart doesn't look strange
     * @param options
     */
    function updateGaugeDefaultValues(options) {
        let defaultValues = {};
        if (options.drawOptions[DrawOption.PLOT_TYPE] === ChartPlotType.MULTI_AXIS_GAUGE) {
            defaultValues = {
                [DrawOption.GAUGE_HAND_TYPE]: HandTypes.NEEDLE,
                [DrawOption.SHOW_TICKS]: true,
                [DrawOption.SHOW_LABELS]: false,
                [DrawOption.GAUGE_BIG_NUMBER_POSITION]: GaugeBigNumberPosition.NONE,
                [DrawOption.GAUGE_SHOW_VALUE]: false,
                [DrawOption.GAUGE_HAND_CUSTOM_COLOR]: false,
                [DrawOption.HAS_LEGEND]: true,
            };
        } else if (options.drawOptions[DrawOption.PLOT_TYPE] === ChartPlotType.GAUGE) {
            defaultValues = {
                [DrawOption.GAUGE_HAND_TYPE]: HandTypes.NONE,
                [DrawOption.SHOW_TICKS]: false,
                [DrawOption.SHOW_LABELS]: true,
                [DrawOption.GAUGE_BIG_NUMBER_POSITION]: GaugeBigNumberPosition.NONE,
                [DrawOption.GAUGE_SHOW_VALUE]: true,
                [DrawOption.HAS_LEGEND]: false,
            };
        }

        if (Object.values(defaultValues).length) {
            for (const [drawOption, defaultDrawOptionSetting] of Object.entries(defaultValues)) {
                options.drawOptions[drawOption] = defaultDrawOptionSetting;
            }
        }
    }

    function updateBarChartDefaultValues(options) {
        let defaultValues = {};
        if ([ChartPlotType.RADIAL_HISTOGRAM_V2, ChartPlotType.RADIAL_BAR_V2].includes(options.drawOptions[DrawOption.PLOT_TYPE])) {
            defaultValues = {
                [DrawOption.IS_NORMALIZED]: false,
                [DrawOption.IS_ROTATED]: false,
                [DrawOption.IS_Y_AXIS_MOVED]: false,
                [DrawOption.Y_AXIS_OVERRIDE]: YAxisPosition.DEFAULT,
                [DrawOption.CURVED_COLUMNS]: false,
            };
        }
        if (Object.values(defaultValues).length) {
            for (const [drawOption, defaultDrawOptionSetting] of Object.entries(defaultValues)) {
                options.drawOptions[drawOption] = defaultDrawOptionSetting;
            }
        }
    }

    /**
     * Emit draw options
     * @param [drawOptionItem] Optional
     */
    function updateDrawOptions(drawOptionItem) {
        if (drawOptionItem) {
            updateIfNeeded(drawOptionItem);
        }

        var eventToSend = _getEvent();
        var data = _getDataToSend();
        data.item = drawOptionItem;

        PubSub.emit(eventToSend, data);
    }

    /**
     * Return event based on props
     * @returns {string}
     * @private
     */
    function _getEvent() {
        if (props.options.isLayout && props.options.isExport) {
            return $DrawOptionPanelEvents.REPORT_STUDIO_REPORT_DRAW_OPTIONS;
        } else if (!props.options.isLayout && props.options.isExport) {
            return $DrawOptionPanelEvents.REPORT_STUDIO_WIDGET_DRAW_OPTIONS + props.options.widgetId;
        } else if (props.options.isLayout && !props.options.isExport) {
            return $DrawOptionPanelEvents.LAYOUT_DRAW_OPTIONS;
        } else {
            return $WidgetBuilderEvents.UPDATE_STYLES;
        }
    }

    function _getDataToSend() {
        if (props.options.isLayout && props.options.isExport) {
            return _editExportOptionsEventData();
        } else if (!props.options.isLayout && props.options.isExport) {
            return _editExportWidgetOptionsEventData();
        } else if (props.options.isLayout) {
            return _dashboardEventData();
        } else {
            return _editWidgetEventData();
        }
    }

    function _editExportOptionsEventData() {
        return {
            autoPersist: props.options.autoPersist,
            layoutId: props.options.layoutId,
            drawOptions: props.options.drawOptions,
            drawLocation: DrawOption.LOCATION_EXPORT
        }
    }

    function _editExportWidgetOptionsEventData() {
        return {
            autoPersist: props.options.autoPersist,
            layoutId: props.options.layoutId,
            widgetId: props.options.widgetId,
            drawOptions: props.options.drawOptions,
            drawLocation: DrawOption.LOCATION_EXPORT
        }
    }

    /**
     * Data to send when editing / creating a widget
     * @private
     */
    function _editWidgetEventData() {
        return {
            autoPersist: props.options.autoPersist,
            drawOptions: props.options.drawOptions
        }
    }

    /**
     * Data to send when editing a layout in dashboard
     * @private
     */
    function _dashboardEventData() {
        return {
            autoPersist: props.options.autoPersist,
            layoutId: props.options.layoutId,
            drawOptions: props.options.drawOptions,
            drawLocation: DrawOption.LOCATION_DASHBOARD
        }
    }

    /**
     * SETTERS
     */

    /**
     * Getters
     */
    function getLayoutDrawOptions() {
        var layout = DesignFactory.getCurrentLayout();
        if (_.isEmpty(layout)) {
            return [];
        }
        return !layout.is_predefined && props.options.isExport && !props.options.isLayout
            ? layout.metadata.export_options.draw_options
            : layout.metadata.draw_options;
    }

    function getIsShowing() {
        return props.state.isShowing;
    }

    function getIsLoading() {
        return props.state.isLoading;
    }

    function getPanelId() {
        return props.options.panelId;
    }

    /**
     *
     * @param widgetTypeDrawOptions
     * @param itemData
     */
    function setTotalRowBottomStatus(widgetTypeDrawOptions, itemData) {
        if (WidgetUtilService.isDataGrid(itemData.widgetTypeId)) {
            const drawOptionKeyValues = _.map(widgetTypeDrawOptions, (value, key) => {return { [value.key]: key }});
            const requiredIndex = _.find(drawOptionKeyValues, (num) => {
              return num[DrawOption.GRID_TOTAL_ROW_BOTTOM];
            })[DrawOption.GRID_TOTAL_ROW_BOTTOM];

            if (requiredIndex) {
                widgetTypeDrawOptions[requiredIndex].is_disabled = !!(
                    (WidgetUtilService.isGroupedColumnPlotType(itemData.drawOptions) || WidgetUtilService.isHeatMapPlotType(itemData.drawOptions)) &&
                    itemData.drawOptions[DrawOption.SHOW_TOTAL_ROW]
                );
            }
        }
    }

}

/**
 * @ngInject
 */
function DrawOptionsPanelParser(
    WidgetFactory,
    DateFormatType,
    WrapFormat,
    BackgroundColor,
    CenterNumbers,
    DrawOption,
    ColumnFormat,
    AppFactory,
    gettextCatalog,
    GradientTypes,
    BubbleShapes,
    BulletShapes,
    LineTypes,
    YAxisPosition,
    SparkLineTypes,
    MapTypes,
    HandTypes,
    GradientTargets,
    GaugeBigNumberPosition,
    PictorialTypes,
    BenchmarkLineStyle,
    SparklineOptions,
) {
    return {
        parse: parse
    };

    function parse(data, widgetTypeId, drawOptions) {
        // Widget look and feel
        var result = {};
        result.widgetDrawOptions = data[DrawOption.TYPE_ALL];

        // Draw options for report specific elements in export
        result.reportDrawOptions = data[DrawOption.TYPE_REPORT];

        // Widget data style based on widget type
        if (data[DrawOption.TYPE_WIDGET]) {
            result.widgetTypeTabTitle = gettextCatalog.getString('{{widgetTypeName}} Options',
                {widgetTypeName: WidgetFactory.getWidgetType(widgetTypeId).name});
            result.widgetTypeDrawOptions = _.map(data[DrawOption.TYPE_WIDGET], function (option) {

                if (option.format == ColumnFormat.FORMAT_INTEGER) {
                    var defaultOptions = {
                        id: option.key + '-slider',
                        showSelectionBar: true,
                        onEnd: function () {
                            drawOptions[option.key] = option.slider.value;
                        }
                    };

                    switch(option.key) {
                        case (DrawOption.ANGLE):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.ANGLE]) ? 0 : drawOptions[DrawOption.ANGLE],
                                options: {
                                    ceil: 50,
                                    step: 10
                                }
                            };
                            break;

                        case (DrawOption.START_ANGLE):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.START_ANGLE]) ? 90 : drawOptions[DrawOption.START_ANGLE],
                                options: {
                                    ceil: 360,
                                    step: 10
                                }
                            };
                            break;

                        case (DrawOption.PIE_ROTATION_ANGLE):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.PIE_ROTATION_ANGLE]) ? 90 : drawOptions[DrawOption.PIE_ROTATION_ANGLE],
                                options: {
                                    ceil: 360,
                                    step: 10
                                }
                            };
                            break;

                        case (DrawOption.DEPTH):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.DEPTH]) ? 0 : drawOptions[DrawOption.DEPTH],
                                options: {
                                    ceil: 100,
                                    step: 10
                                }
                            };
                            break;

                        case (DrawOption.NECK_HEIGHT):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.NECK_HEIGHT]) ? 0 : drawOptions[DrawOption.NECK_HEIGHT],
                                options: {
                                    ceil: 500,
                                    step: 10
                                }
                            };
                            break;

                        case (DrawOption.NECK_WIDTH):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.NECK_WIDTH]) ? 0 : drawOptions[DrawOption.NECK_WIDTH],
                                options: {
                                    ceil: 500,
                                    step: 10
                                }
                            };
                            break;

                        case (DrawOption.INNER_RADIUS):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.INNER_RADIUS]) ? 0 : drawOptions[DrawOption.INNER_RADIUS],
                                options: {
                                    ceil: 100,
                                    step: 10
                                }
                            };
                            break;

                        case (DrawOption.V2_INNER_RADIUS):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.V2_INNER_RADIUS]) ? 40 : drawOptions[DrawOption.V2_INNER_RADIUS],
                                options: {
                                    floor: 40,
                                    ceil: 100,
                                    step: 10
                                }
                            };
                            break;

                        case (DrawOption.FONT_SIZE):
                            option.slider = {
                                value: drawOptions[DrawOption.FONT_SIZE],
                                options: {
                                    floor: 15, // Default to 15 for min
                                    ceil: 150, // Default to 150 for max
                                    step: 5
                                }
                            };
                            break;

                        case (DrawOption.CIRCLE_FONT_SIZE):
                            const isComparing = AppFactory.getComparisonDateRange().enabled;
                            const fontSize = isComparing ? 15 : 22;
                            option.slider = {
                                value: drawOptions[DrawOption.CIRCLE_FONT_SIZE] || fontSize,
                                options: {
                                    floor: 15, // Default to 15 for min
                                    ceil: drawOptions[DrawOption.CIRCLE_SIZE] ? Math.round(drawOptions[DrawOption.CIRCLE_SIZE] * 0.4) : 100 * 0.4,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.SPACING):
                            option.slider = {
                                value: drawOptions[DrawOption.SPACING] || 15,
                                options: {
                                    floor: 10,
                                    ceil: 60,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.WRAPPED_FONT_SIZE):
                            option.slider = {
                                value: drawOptions[DrawOption.WRAPPED_FONT_SIZE] || 30,
                                options: {
                                    floor: 15, // Default to 15 for min
                                    ceil: drawOptions[DrawOption.CIRCLE_SIZE] ? Math.round(drawOptions[DrawOption.CIRCLE_SIZE] * 0.4) : 100 * 0.4,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.CIRCLE_SIZE):
                            option.slider = {
                                value: drawOptions[DrawOption.CIRCLE_SIZE] || 100,
                                options: {
                                    floor: 70,
                                    ceil: 400,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.OTHER_PERCENT):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.OTHER_PERCENT]) ? 2 : drawOptions[DrawOption.OTHER_PERCENT],
                                options: {
                                    floor: 0, // Default to 0 for min
                                    ceil: 80, // Default to 80 for max
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.LABEL_PERCENT):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.LABEL_PERCENT]) ? 0 : drawOptions[DrawOption.LABEL_PERCENT],
                                options: {
                                    floor: 0, // Default to 0 for min
                                    ceil: 100, // Default to 100 for max
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.GRID_IFRAME_CAPTURE_DELAY):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.GRID_IFRAME_CAPTURE_DELAY]) ? 0 : drawOptions[DrawOption.GRID_IFRAME_CAPTURE_DELAY],
                                options: {
                                    floor: 0,
                                    ceil: 30,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.BORDER_WIDTH):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.BORDER_WIDTH]) ? 0 : drawOptions[DrawOption.BORDER_WIDTH],
                                options: {
                                    floor: 0,
                                    ceil: 10,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.CORNER_RADIUS):
                            option.slider = {
                                value: _.isUndefined(drawOptions[DrawOption.CORNER_RADIUS]) ? 0 : drawOptions[DrawOption.CORNER_RADIUS],
                                options: {
                                    floor: 0,
                                    ceil: 50,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.GRID_FONT_SIZE):
                            option.slider = {
                                value: drawOptions[DrawOption.GRID_FONT_SIZE] || 10,
                                options: {
                                    floor: 10, // Default to 10 for min
                                    ceil: 150,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.ROUNDED_CORNERS):
                            option.slider = {
                                value: drawOptions[DrawOption.ROUNDED_CORNERS] || 0,
                                options: {
                                    floor: 1,
                                    ceil: 40,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.RADIAL_INNER_RADIUS):
                             let radialInnerRadius = drawOptions[DrawOption.RADIAL_INNER_RADIUS] > 60 ? 60 : drawOptions[DrawOption.RADIAL_INNER_RADIUS];
                            option.slider = {
                                value: radialInnerRadius || 40,
                                options: {
                                    floor: 10,
                                    ceil: 60,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.BUBBLE_MAX_BUBBLES):
                            option.slider = {
                                value: drawOptions[DrawOption.BUBBLE_MAX_BUBBLES] || 100,
                                options: {
                                    floor: 1,
                                    ceil: 1000,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.SHAPE_OPACITY):
                            option.slider = {
                                value: drawOptions[DrawOption.SHAPE_OPACITY] || 70,
                                options: {
                                    floor: 10,
                                    ceil: 100,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.GEO_LAYER_OPACITY):
                            option.slider = {
                                value: drawOptions[DrawOption.GEO_LAYER_OPACITY] || 100,
                                options: {
                                    floor: 10,
                                    ceil: 100,
                                    step: 10
                                }
                            };
                            break;

                        case (DrawOption.SHAPE_SIZE):
                            option.slider = {
                                value: drawOptions[DrawOption.SHAPE_SIZE] || 50,
                                options: {
                                    floor: 30,
                                    ceil: 100,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.GRID_MAX_HEIGHT_IMAGE):
                            option.slider = {
                                value: drawOptions[DrawOption.GRID_MAX_HEIGHT_IMAGE] || 0,
                                options: {
                                    floor: 0,
                                    ceil: 500,
                                    step: 1
                                }
                            };
                            break;

                        case (DrawOption.GRAIN_DENSITY):
                            option.slider = {
                                value: drawOptions[DrawOption.GRAIN_DENSITY] || 0,
                                options: {
                                    floor: 0,
                                    ceil: 100,
                                    step: 1,
                                }
                            };
                            break;
                        case (DrawOption.PIE_GRAIN_DENSITY):
                            option.slider = {
                                value: drawOptions[DrawOption.PIE_GRAIN_DENSITY] || 0,
                                options: {
                                    floor: 0,
                                    ceil: 100,
                                    step: 1,
                                }
                            };
                            break;

                        case (DrawOption.GAUGE_THICKNESS):
                            option.slider = {
                                value: drawOptions[DrawOption.GAUGE_THICKNESS] || 0,
                                options: {
                                    floor: 1,
                                    ceil: 100,
                                    step: 5,
                                }
                            };
                            break;

                        case (DrawOption.GAUGE_MULTI_THICKNESS):
                            option.slider = {
                                value: drawOptions[DrawOption.GAUGE_MULTI_THICKNESS] || 35,
                                options: {
                                    floor: 1,
                                    ceil: 46,
                                    step: 1,
                                }
                            };
                            break;

                        case (DrawOption.GAUGE_RANGE):
                            option.slider = {
                                value: drawOptions[DrawOption.GAUGE_RANGE] || 0,
                                options: {
                                    floor: 60,
                                    ceil: 320,
                                    step: 15,
                                }
                            };
                            break;
                    }

                    option.slider.ready = false;
                    option.slider.options = _.assign(defaultOptions, option.slider.options);
                }
                // Select inputs
                else if (option.format == ColumnFormat.FORMAT_STRING) {
                    switch(option.key) {
                        case (DrawOption.DISPLAY_LENGTH):
                            option.values = [
                                {key: 3, value: 3},
                                {key: 5, value: 5},
                                {key: 10, value: 10},
                                {key: 15, value: 15},
                                {key: 20, value: 20},
                                {key: 25, value: 25},
                                {key: 50, value: 50},
                                {key: 75, value: 75},
                                {key: 100, value: 100}
                            ];
                            break;

                        case (DrawOption.REPORT_DISPLAY_LENGTH):
                            option.values = [
                                {key:  null, value: gettextCatalog.getString('Default')},
                                {key: 3, value: 3},
                                {key: 5, value: 5},
                                {key: 10, value: 10},
                                {key: 15, value: 15},
                                {key: 20, value: 20},
                                {key: 25, value: 25},
                                {key: 50, value: 50},
                                {key: 75, value: 75},
                                {key: 100, value: 100},
                                {key: 500, value: 500},
                                {key: 1000, value: 1000},
                                {key: 2000, value: 2000},
                                {key: 5000, value: 5000},
                                {key:    -1, value: 'All'}
                            ];
                            break;

                        case (DrawOption.NUMBER_OF_PAGE):
                            option.values = [
                                {key: 1, value: 1},
                                {key: 3, value: 3},
                                {key: 5, value: 5},
                                {key: 10, value: 10},
                                {key: 15, value: 15},
                                {key: 20, value: 20},
                            ];
                            break;
                        case (DrawOption.ROWS_PER_PAGE):
                            option.values = [
                                {key: 3, value: 3},
                                {key: 5, value: 5},
                                {key: 10, value: 10},
                                {key: 12, value: 12},
                                {key: 15, value: 15},
                                {key: 18, value: 18},
                                {key: 20, value: 20},
                                {key: 25, value: 25}
                            ];
                            break;

                        case (DrawOption.OPTION_GEO_LIMIT):
                            option.values = [
                                {key: 1000, value: 1000},
                                {key: 2000, value: 2000},
                                {key: 5000, value: 5000}
                            ];
                            break;

                        case (DrawOption.DATE_FORMAT_TYPE):
                            option.values = [
                                {key:  DateFormatType.DEFAULT, value: gettextCatalog.getString('Default')},
                                {key:  DateFormatType.NO_YEAR, value: gettextCatalog.getString('Don\'t include year')},
                            ];
                            break;

                        case (DrawOption.CIRCLE_NUMBER):
                            option.values = [
                                {key: WrapFormat.CIRCLE, value: gettextCatalog.getString('Circle')},
                                {key: WrapFormat.SQUARE, value: gettextCatalog.getString('Square')},
                            ];
                            break;

                        case (DrawOption.FILL_TYPE):
                            option.values = [
                                {key: GradientTypes.SOLID, value: gettextCatalog.getString('Solid')},
                                {key: GradientTypes.LINEAR, value: gettextCatalog.getString('Gradient (Vertical)')},
                                {key: GradientTypes.LINEAR_Y, value: gettextCatalog.getString('Gradient (Horizontal)')},
                            ];
                            break;

                        case (DrawOption.BACKGROUND_COLOR_TYPE):
                            option.values = [
                                {key: BackgroundColor.DATASOURCE, value: gettextCatalog.getString('DataSource')},
                                {key: BackgroundColor.THEME, value: gettextCatalog.getString('Theme')},
                                {key: BackgroundColor.CUSTOM, value: gettextCatalog.getString('Custom')}
                            ];
                            break;

                        case (DrawOption.CENTER_NUMBERS):
                            option.values = [
                                {key: CenterNumbers.CENTER, value: gettextCatalog.getString('Center')},
                                {key: CenterNumbers.LEFT, value: gettextCatalog.getString('Left')},
                                {key: CenterNumbers.RIGHT, value: gettextCatalog.getString('Right')}
                            ];
                            break;

                        case (DrawOption.BACKGROUND_GRADIENT):
                            option.values = [
                                {key: GradientTypes.SOLID, value: gettextCatalog.getString('Solid')},
                                {key: GradientTypes.LINEAR, value: gettextCatalog.getString('Linear')},
                                {key: GradientTypes.RADIAL, value: gettextCatalog.getString('Radial')}
                            ];
                            break;

                        case (DrawOption.PIE_GRADIENT_OPTIONS):
                            option.values = [
                                {key: GradientTypes.SOLID, value: gettextCatalog.getString('Solid')},
                                {key: GradientTypes.LINEAR, value: gettextCatalog.getString('Linear')},
                            ];
                            break;

                        case (DrawOption.BUBBLE_SHAPE):
                            option.values = [
                                {key: BubbleShapes.CIRCLE, value: gettextCatalog.getString('Circle')},
                                {key: BubbleShapes.DIAMOND, value: gettextCatalog.getString('Diamond')},
                                {key: BubbleShapes.STAR, value: gettextCatalog.getString('Star')},
                                {key: BubbleShapes.TRIANGLE, value: gettextCatalog.getString('Triangle')}
                            ];
                            break;

                        case (DrawOption.MAP_TYPE):
                            option.values = [
                                {key: MapTypes.STREET, value: gettextCatalog.getString('Street')},
                                {key: MapTypes.OUTDOOR, value: gettextCatalog.getString('OutDoor')},
                                {key: MapTypes.LIGHT, value: gettextCatalog.getString('Light')},
                                {key: MapTypes.DARK, value: gettextCatalog.getString('Dark')},
                                {key: MapTypes.SATELLITE, value: gettextCatalog.getString('Satellite')},
                                {key: MapTypes.SATELLITE_STREET, value: gettextCatalog.getString('Satellite Street')},
                                {key: MapTypes.NAVIGATION_DAY, value: gettextCatalog.getString('Navigation Day')},
                                {key: MapTypes.NAVIGATION_NIGHT, value: gettextCatalog.getString('Navigation Night')},
                            ];
                            break;

                        case (DrawOption.TAP_MAP_CONFIG):
                            option.values = [
                                {key: TapMapConfig.BOUNDARY, value: gettextCatalog.getString('Boundary')},
                                {key: TapMapConfig.CLUSTER, value: gettextCatalog.getString('Cluster')},
                            ];
                            break;

                        case (DrawOption.PLOT_TYPE):
                            option.values = [];

                            WidgetFactory.getPlotTypes(widgetTypeId).then(function (data) {
                                _.each(data.plain(), function(plotType) {
                                    option.values.push(plotType);
                                });
                            });
                            break;

                        case (DrawOption.BULLETS_SHAPE):
                            option.values = [
                                {key: BulletShapes.CIRCLE, value: gettextCatalog.getString('Circle')},
                                {key: BulletShapes.DIAMOND, value: gettextCatalog.getString('Diamond')},
                                {key: BulletShapes.STAR, value: gettextCatalog.getString('Star')},
                                {key: BulletShapes.TRIANGLE, value: gettextCatalog.getString('Triangle')}
                            ];
                            break;
                        case (DrawOption.LINE_TYPE):
                            option.values = [
                                {key: LineTypes.LINE, value: gettextCatalog.getString('Line')},
                                {key: LineTypes.STEP_LINE, value: gettextCatalog.getString('Step Line')}
                            ];
                            break;

                        case (DrawOption.SPARKLINE_TYPE):
                            option.values = [
                                {key: SparkLineTypes.LINE, value: gettextCatalog.getString('Area')},
                                {key: SparkLineTypes.COLUMN, value: gettextCatalog.getString('Bar')}
                            ];
                            break;

                        case (DrawOption.EMBED_SPARKLINE_TYPE):
                            option.values = [
                                {key: SparkLineTypes.LINE, value: gettextCatalog.getString('Area')},
                                {key: SparkLineTypes.COLUMN, value: gettextCatalog.getString('Bar')}
                            ];
                            break;

                        case (DrawOption.EMBED_SPARKLINE_DATE_RANGE):
                            option.values = [
                                {key: SparklineOptions.LAST_3_MONTHS, value: gettextCatalog.getString('Last 3 months')},
                                {key: SparklineOptions.LAST_6_MONTHS, value: gettextCatalog.getString('Last 6 months')},
                            ];
                            break;

                        case (DrawOption.GAUGE_HAND_TYPE):
                            option.values = [
                                {key: HandTypes.NONE, value: gettextCatalog.getString('None')},
                                {key: HandTypes.NEEDLE, value: gettextCatalog.getString('Needle')},
                                {key: HandTypes.POINTER, value: gettextCatalog.getString('Pointer')},
                                {key: HandTypes.TRIANGLE, value: gettextCatalog.getString('Triangle')},
                                {key: HandTypes.LINE, value: gettextCatalog.getString('Line')},
                            ];
                            break;

                        case (DrawOption.GAUGE_GRADIENT_TARGET):
                            option.values = [
                                {key: GradientTargets.EVERYTHING, value: gettextCatalog.getString('Everything')},
                                {key: GradientTargets.HAND, value: gettextCatalog.getString('Hand')},
                                {key: GradientTargets.METRIC, value: gettextCatalog.getString('Metric')},
                                {key: GradientTargets.BAND, value: gettextCatalog.getString('Band')},
                                {key: GradientTargets.HAND_METRIC, value: gettextCatalog.getString('Hand and Metric')},
                                {key: GradientTargets.HAND_BAND, value: gettextCatalog.getString('Hand and Band')},
                                {key: GradientTargets.METRIC_BAND, value: gettextCatalog.getString('Metric and Band')},
                            ];
                            break;

                        case (DrawOption.GAUGE_BIG_NUMBER_POSITION):
                            option.values = [
                                {key: GaugeBigNumberPosition.NONE, value: gettextCatalog.getString('None')},
                                {key: GaugeBigNumberPosition.HAND, value: gettextCatalog.getString('Inside Pointer (Colored)')},
                                {key: GaugeBigNumberPosition.HAND_TRANSPARENT, value: gettextCatalog.getString('Inside Pointer (Transparent)')},
                                {key: GaugeBigNumberPosition.INSIDE, value: gettextCatalog.getString('Inside Gauge')},
                                {key: GaugeBigNumberPosition.BELOW, value: gettextCatalog.getString('Below Gauge')},
                            ];
                            break;

                        case (DrawOption.PICTORIAL_OPTIONS):
                            option.values = [
                                {key: PictorialTypes.MALE, value: gettextCatalog.getString('Male')},
                                {key: PictorialTypes.FEMALE, value: gettextCatalog.getString('Female')},
                                {key: PictorialTypes.MUG, value: gettextCatalog.getString('Mug')},
                                {key: PictorialTypes.CUP, value: gettextCatalog.getString('Cup')},
                                {key: PictorialTypes.KEY, value: gettextCatalog.getString('Key')},
                                {key: PictorialTypes.APPLE, value: gettextCatalog.getString('Apple')},
                                {key: PictorialTypes.MOUNTAIN, value: gettextCatalog.getString('Mountain')},
                                {key: PictorialTypes.SAILBOAT, value: gettextCatalog.getString('Sailboat')},
                                {key: PictorialTypes.BULB, value: gettextCatalog.getString('Bulb')},
                                {key: PictorialTypes.THERMOMETER, value: gettextCatalog.getString('Thermometer')},
                                {key: PictorialTypes.TREE, value: gettextCatalog.getString('Tree')},
                                {key: PictorialTypes.ROCKETSHIP, value: gettextCatalog.getString('Rocket Ship')},
                                {key: PictorialTypes.HAMMER, value: gettextCatalog.getString('Hammer')},
                                {key: PictorialTypes.SKYSCRAPER, value: gettextCatalog.getString('Skyscraper')},
                                {key: PictorialTypes.EVERGREEN, value: gettextCatalog.getString('Evergreen')},
                                {key: PictorialTypes.HEART, value: gettextCatalog.getString('Heart')},
                                {key: PictorialTypes.HOUSE, value: gettextCatalog.getString('House')},
                            ];
                            break;

                        case (DrawOption.BENCHMARK_LINE_STYLE):
                            option.values = [
                                {key: BenchmarkLineStyle.DASHED, value: gettextCatalog.getString('Dashed')},
                                {key: BenchmarkLineStyle.DOTTED, value: gettextCatalog.getString('Dotted')},
                                {key: BenchmarkLineStyle.SOLID, value: gettextCatalog.getString('Solid')},
                                {key: BenchmarkLineStyle.NONE, value: gettextCatalog.getString('None')},
                            ];
                            break;

                        case (DrawOption.BENCHMARK_LINE_TYPE):
                            option.values = [
                                {key: LineTypes.LINE, value: gettextCatalog.getString('Line')},
                                {key: LineTypes.STEP_LINE, value: gettextCatalog.getString('Step Line')}
                            ];
                            break;

                        case (DrawOption.Y_AXIS_OVERRIDE):
                            option.values = [
                                {key: YAxisPosition.DEFAULT, value: gettextCatalog.getString('Default')},
                                {key: YAxisPosition.SWAP, value: gettextCatalog.getString('Swap Y Axes')},
                                {key: YAxisPosition.LEFT, value: gettextCatalog.getString('All Left')},
                                {key: YAxisPosition.RIGHT, value: gettextCatalog.getString('All Right')},
                            ];
                            break;
                    }
                }
                return option;
            });
        }
        return result;
    }
}

/**
 * @ngInject
 */
function DrawOptionFactory(
    DrawOption,
    AppFactory,
    DesignFactory,
    ReportStudioTemplateDataService,
    WidgetUtilService
) {
    /**
     * Determines the inherited draw options based on layout/widget draw option
     * inheritance tree and returns ONLY the values
     * @param key
     * @param widget
     * @param isExport
     * @returns {null}
     */
    function resolveDrawOptionValue(key, widget = {}, isExport) {

        let layoutDrawOption;
        let metadata = widget.metadata;
        let widgetDrawOption = metadata.draw_options ? metadata.draw_options[key] : null;
        const reportStudioReport = ReportStudioTemplateDataService.getReport();
        const reportExists = !_.isEmpty(reportStudioReport);

        if (!reportExists) {
          layoutDrawOption = DesignFactory.getLayout(widget.layout_id).metadata.draw_options[key];
        } else if (
          isExport ||
          (metadata.dynamic && metadata.dynamic.is_creating)
        ) {
          //if we're in the builder
          layoutDrawOption = metadata.draw_options[key];
        } else if (reportExists) {
          layoutDrawOption = reportStudioReport.metadata.draw_options[key];
        }

        // For now, user settings override all other draw options
        if (drawOptionCanBeSetInPreferences(key)) {
            var userSettings = AppFactory.getUser();
            switch (key) {
                case DrawOption.SHOW_COMPARISON_ARROWS:
                    value = userSettings.showComparisonArrows;
                    break;

                case DrawOption.DEFAULT_COMPARISON_ARROW_COLOR:
                    value = userSettings.showDefaultComparisonArrowColor;
                    break;
            }
            return value;
        }

        if(_.isUndefined(widgetDrawOption)) {
            widgetDrawOption = layoutDrawOption;
        }

        var value = widgetDrawOption && widgetDrawOption.overridden
            ? widgetDrawOption.value
            : (layoutDrawOption ? layoutDrawOption.value : null);

        if (isExport) {
            // Get export draw options
            var exportLayoutDrawOption = ReportStudioTemplateDataService.getReport().metadata.draw_options[key];

            var exportWidgetDrawOption = exportLayoutDrawOption;
            if (_.isObject(metadata)
                && _.isObject(metadata.draw_options)) {
                exportWidgetDrawOption = metadata.draw_options[key];
            }

            // Return dashboard draw option, unless overridden by export layout or export widget (in that order)
            if (exportLayoutDrawOption && exportLayoutDrawOption.overridden) {
                value = exportLayoutDrawOption.value;
            }
            if (exportWidgetDrawOption && exportWidgetDrawOption.overridden) {
                value = exportWidgetDrawOption.value;
            }

            //ensure a default to widget/layout value if nothing could define it
            if (_.isNull(value) || _.isUndefined(value)) {
                if (exportWidgetDrawOption && !_.isUndefined(exportWidgetDrawOption.value)) {
                    value = exportWidgetDrawOption.value;
                }
                else if (exportLayoutDrawOption && !_.isUndefined(exportLayoutDrawOption.value)) {
                    value = exportLayoutDrawOption.value;
                }
            }
        }

        return value;
    }

    /**
     * @private
     * @param key
     * @returns {boolean}
     */
    function drawOptionCanBeSetInPreferences(key) {
        return key === DrawOption.SHOW_COMPARISON_ARROWS
            || key === DrawOption.DEFAULT_COMPARISON_ARROW_COLOR;
    }


    /**
     * Tells us if draw option requires widget to redraw
     * @param option
     * @param isAm5Widget
     * @returns {boolean}
     */
    function drawOptionRequiresRedraw(option, isAm5Widget = false) {
        if (isAm5Widget) {
            return _.includes([
                DrawOption.PLOT_TYPE,
                DrawOption.SHOW_SAMPLE_DATA,
                DrawOption.SHOW_EMPTY_DATES,
            ], option);
        }
        return  _.includes([
            DrawOption.REDUCE_NUMBER,
            DrawOption.PLOT_TYPE,
            DrawOption.HAS_LEGEND,
            DrawOption.IS_ROTATED,
            DrawOption.HAS_VALUE_SCROLLER,
            DrawOption.IS_HAND_DRAWN,
            DrawOption.IS_Y_AXIS_MOVED,
            DrawOption.IS_NORMALIZED,
            DrawOption.IS_ZERO_SCALED,
            DrawOption.IS_LOG_SCALED,
            DrawOption.SHOW_LABELS,
            DrawOption.SHOW_LABEL_NAMES,
            DrawOption.SHOW_LABEL_VALUES,
            DrawOption.SHOW_LABEL_PERCENT,
            DrawOption.SHOW_METRIC_LABELS,
            DrawOption.SHOW_VALUES_ON_SHAPES,
            DrawOption.SHOW_SHAPE_SHADOW,
            DrawOption.SHAPE_OPACITY,
            DrawOption.SHAPE_SIZE,
            DrawOption.DATE_FORMAT_TYPE,
            DrawOption.NECK_HEIGHT,
            DrawOption.NECK_WIDTH,
            DrawOption.DEPTH,
            DrawOption.ANGLE,
            DrawOption.OTHER_PERCENT,
            DrawOption.LABEL_PERCENT,
            DrawOption.START_ANGLE,
            DrawOption.INNER_RADIUS,
            DrawOption.HAS_BULLETS,
            DrawOption.HAS_TOOLTIP,
            DrawOption.IS_SMOOTHED_LINE,
            DrawOption.STEP_LINE_RISERS,
            DrawOption.SHOW_SAMPLE_DATA,
            DrawOption.SHOW_EMPTY_DATES,
            DrawOption.IS_SOLID_GAUGE,
            DrawOption.IS_DARK_MODE,
            DrawOption.DISPLAY_LENGTH,
            DrawOption.GRID_PAGINATE,
            DrawOption.GRID_IS_RESPONSIVE,
            DrawOption.GRID_ALTERNATING_ROW_COLOR,
            DrawOption.GRID_COLLAPSE_IN_MODAL,
            DrawOption.SHOW_TOTAL_ROW,
            DrawOption.PIVOT_GRID,
            DrawOption.HIDE_GRID_LINES,
            DrawOption.GRID_TOTAL_ROW_BOTTOM,
            DrawOption.GRID_FULL_IMAGE_SIZE,
            DrawOption.GRID_MAX_HEIGHT_IMAGE,
            DrawOption.PERCENT_OF_TOTAL,
            DrawOption.CIRCLE_NUMBER,
            DrawOption.CIRCLE_FONT_SIZE,
            DrawOption.FONT_SIZE,
            DrawOption.WRAP_METRIC_NAME,
            DrawOption.ROUNDED_CORNERS,
            DrawOption.CURVED_COLUMNS,
            DrawOption.RADIAL_INNER_RADIUS,
            DrawOption.SHOW_COLUMN_SHADOWS,
            DrawOption.LAYERS,
            DrawOption.ENABLE_GRID_THEME,
            DrawOption.GRID_FONT_SIZE,
            DrawOption.GRID_FONT_PROPERTIES,
            DrawOption.WRAP_TEXT_NAME,
            DrawOption.STYLIZED_EXCEL,
            DrawOption.MAP_TYPE,
            DrawOption.GEO_LAYER_OPACITY,
            DrawOption.TAP_MAP_CONFIG,
            DrawOption.TAP_MAP_SHOW_CLUSTER_VALUE,
            DrawOption.TAP_MAP_MANUALLY_ADJUST_DIMENSIONS,
            DrawOption.OPTION_GEO_LIMIT,
            DrawOption.HIDE_ZERO_VALUE,
            DrawOption.MAP_GEOGRAPHIC_FEATURES,
            DrawOption.FILL_TYPE,
            DrawOption.PIE_SEMICIRCLE_ANGLE,
            DrawOption.SHOW_GRAINY_PATTERN,
            DrawOption.GRAIN_DENSITY,
            DrawOption.PIE_GRAIN_DENSITY,
            DrawOption.INVERT_METRICS,
            DrawOption.ORDER_METRICS,
            DrawOption.PIE_ROTATION_ANGLE,
            DrawOption.PIE_GRADIENT_OPTIONS,
            DrawOption.V2_INNER_RADIUS,
            DrawOption.PIE_VARIABLE_RADIUS,
            DrawOption.CLASSIC_DRILL_DOWN_BEHAVIOUR,
            DrawOption.GAUGE_HAND_TYPE,
            DrawOption.GAUGE_THICKNESS,
            DrawOption.GAUGE_MULTI_THICKNESS,
            DrawOption.SHOW_TICKS,
            DrawOption.HAND_COLOR,
            DrawOption.GAUGE_RANGE,
            DrawOption.GAUGE_GRADIENT_TO,
            DrawOption.GAUGE_HAND_CUSTOM_COLOR,
            DrawOption.GAUGE_GRADIENT_TARGET,
            DrawOption.GAUGE_SHOW_VALUE,
            DrawOption.PICTORIAL_OPTIONS,
            DrawOption.HAS_REGRESSION_LINE,
            DrawOption.EMBED_SPARKLINE_TYPE,
            DrawOption.EMBED_SPARKLINE_DATE_RANGE,
            DrawOption.BENCHMARK_LINE_STYLE,
            DrawOption.BENCHMARK_LINE_TYPE,
            DrawOption.BENCHMARK_SHOW_BULLETS,
            DrawOption.SPLIT_IN_MULTIPLE_PAGES,
        ], option);
    }

    return {
        resolveDrawOptionValue: resolveDrawOptionValue,
        drawOptionRequiresRedraw: drawOptionRequiresRedraw
    }
}
