<template>
  <input ref="inputRef" @blur="onBlur" :data-cy="cypressLocator" />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';
import { FormCypressLocatorMixin } from '@/modules/core/form/mixins/FormCypressLocatorMixin';

export default {
  name: 'CurrencyInput',
  mixins: [FormCypressLocatorMixin],
  props: {
    value: { type: Number, default: undefined },
    options: {
      type: Object,
      default: () => ({}),
    },
    cy: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { inputRef, setOptions, setValue } = useCurrencyInput(props?.options);

    return { inputRef, setOptions, setValue };
  },
  computed: {
    defaultCYLocator() {
      return 'currency-element';
    },
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set internal value.
     */
    value(newValue) {
      this.setValue(newValue);
    },
    options(newOptions) {
      this.setOptions(newOptions);
    },
  },
  methods: {
    onBlur($event) {
      this.$emit('blur', $event);
    },
  },
};
</script>
