import { DataView } from '@/modules/core/datasource/models/DataView';

export class CategoryDataView extends DataView {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  category_id;

  /**
   * @var {string}
   */
  parent;

  /**
   * @var {string}
   */
  unique_id;

  /**
   * @var {string}
   */
  services;

  /**
   * @var {string}
   */
  category_metrics;

  /**
   * @var {string}
   */
  category_metrics_string;

  /**
   * @var {string}
   */
  parent_name;

  /**
   * @var {bool}
   */
  show_disable_delete;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.category_id = model.category_id;
    this.parent = model.parent;
    this.unique_id = model.unique_id;
    this.services = model.services;
    this.category_metrics = model.category_metrics;
    this.category_metrics_string = Array.isArray(model.category_metrics)
      ? model.category_metrics.map((metric) => metric.name).join(', ')
      : '';
    this.parent_name = model.parent_name;
    this.show_disable_delete = !model.can_be_deleted;
  }
}
