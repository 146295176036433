import angular from 'angular';
import _ from 'lodash';
import {TapMapConfig} from "../../design.widget.constants";

angular.module('widget.builder.models')
    .factory('WidgetBuilderStylesModelFactory', WidgetBuilderStylesModelFactory);

/**
 * @ngInject
 */
function WidgetBuilderStylesModelFactory(
    BenchmarkLineStyle,
    DateFormatType,
    YAxisPosition,
    LineTypes,
    MapTypes,
    TapMapConfig
) {

    return {
        getTabState: getTabState,
        getTabData: getTabData,
        getDrawOptionsModel: getDrawOptionsModel,
    };

    /**
     * @param model
     * @constructor
     */
    function DrawOptionsModel(model = {}) {
        let self = this;

        _.assign(self, _.defaults(model, {
            show_background: {
                value: true,
                overridden: false
            },
            show_borders: {
                value: true,
                overridden: false
            },
            show_header: {
                value: true,
                overridden: false
            },
            show_title: {
                value: true,
                overridden: false
            },
            show_data_source: {
                value: true,
                overridden: false
            },
            show_metric_name: {
                value: true,
                overridden: false
            },
            show_sample_data: true,

            force_sample_data: false,
            font_size: 32,
            reduce_number: true,
            center_numbers: true,
            circle_number: false,

            // All Charts
            depth: 0,
            angle: 0,
            is_hand_drawn: false,
            has_tooltip: true,
            has_bullets: true,
            has_legend: false,

            // Serial Charts
            plot_type: null,
            map_type: MapTypes.STREET,
            date_format_type: DateFormatType.DEFAULT,
            is_rotated: false,
            has_value_scroller: false,
            is_y_axis_moved: false,
            y_axis_override: YAxisPosition.DEFAULT,
            is_normalized: false,
            is_zero_scaled: true,
            is_solid_gauge: false,
            show_labels: true,
            show_label_values: false,
            show_label_percent: true,
            show_label_names: true,
            show_metric_labels: false,
            is_smoothed_line: false,
            show_empty_dates: false,

            // Slice charts
            start_angle: 90,
            inner_radius: 0,
            neck_height: false,
            neck_width: false,
            hide_grid_lines: true,
            other_percent: false,
            label_percent: false,

            // Data Grids
            display_length: 10,
            split_in_multiple_pages: false,
            rows_per_page: 10,
            number_of_page: 3,
            geo_limit: 1000,
            repeat_full_layout: false,
            report_display_length: null,
            grid_paginate: false,
            grid_max_height_image: 0,

            grid_is_responsive: false,
            grid_alternating_row_color: false,
            grid_collapse_in_modal: true,
            show_total_row: false,
            grid_total_row_bottom: false,
            grid_full_image_size: false,
            grid_preview_as_iframe: false,
            pivot_grid: false,
            grid_iframe_capture_delay: false,
            grid_theme: false,
            wrap_text_name: true,
            stylized_excel: true,
            tap_map_config: TapMapConfig.BOUNDARY,
            tap_map_show_cluster_value: false,
            tap_map_manually_adjust_dimensions: false,
            wrap_metric_name: false,

            // benchmarks
            benchmark_line_style: BenchmarkLineStyle.DASHED,
            benchmark_line_type: LineTypes.LINE,
            benchmark_bullets: false,
        }));
    }

    /**
     * @constructor
     */
    function WidgetBuilderStylesTabState() {
        let self = this;

        self.isLoading = false;
    }

    /**
     * @constructor
     */
    function WidgetBuilderStylesTabData() {
        let self = this;

        self.panelId = 'widget-draw-options';
        self.drawOptions = getDrawOptionsModel();
        self.datasource = null;
        self.widgetTypeId = null;
        self.widgetTypeTabTitle = null;
        self.styleDrawOptionItems = null;
        self.typeDrawOptionItems = null;
        self.selectedColumns = [];
        self.selectedBenchmarks = [];
        self.isLayout = false;
        self.isExport = false;
        self.autoPersist = false;
    }

    /**
     * @param model
     * @returns {DrawOptionsModel}
     */
    function getDrawOptionsModel(model) {
        return new DrawOptionsModel(model);
    }

    /**
     * @returns {WidgetBuilderStylesTabState}
     */
    function getTabState() {
        return new WidgetBuilderStylesTabState();
    }

    /**
     * @returns {WidgetBuilderStylesTabData}
     */
    function getTabData() {
        return new WidgetBuilderStylesTabData();
    }
}
