'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';
import EventBus from "../../../../../grok/src/modules/core/app/helpers/EventBus";
import { CHANGE_ROUTE_TO_DASHBOARD } from "../../../../../grok/src/modules/core/nav/nav.constants";
import UuidService from "../../../../../grok/src/modules/core/app/services/UuidService";
var _tapWalkMe = window._tapWalkMe;

angular.module('design.ctrls', [])

    .controller('DesignController', DesignController);

/**
 * @ngInject
 */
function DesignController(
    $rootScope,
    $scope,
    $timeout,
    $state,
    $stateParams,
    $location,
    pageType,
    pageEntity,
    vars,
    PageType,
    UIFactory,
    AppModule,
    AppFactory,
    PageFactory,
    PageResource,
    WidgetFactory,
    LayoutFactory,
    SlidePanelFactory,
    ExportReportFactory,
    ExportOptionsFactory,
    EditLayoutFactory,
    DrawOptionPanelFactory,
    WidgetBuilderUIService,
    DashboardFilterPanelFactory,
    DashboardFilterHeaderFactory,
    ManageLayoutFactory,
    PageDataGridFactory,
    ExecutiveSummaryFactory,
    UIExecutiveSummaryPanelFactory,
    DetailsModalFactory,
    LoadingState,
    WidgetType,
    WidgetTypeGrouping,
    WidgetTagFactory,
    WidgetTagsResource,
    DesignFactory,
    DataGridFactory,
    $SlidePanelEvents,
    AppModelFactory,
    PubSub,
    $PageLibraryEvents,
    $WidgetBuilderEvents,
    WidgetCreateFactory,
    $window,
    $LayoutEvents,
    PageEvents,
    $DateRangeEvents,
    $WidgetEvents,
    AppService,
    DashboardFilterEvents,
    UserLoginFactory,
    WindowUtilUIService,
    WidgetBuilderService,
    WidgetBuilderDataModelFactory,
    ViewAuditLogsEvent,
) {
    DesignFactory.$registerScope($scope);
    $scope.state = DesignFactory.state;
    DesignFactory.props.hasVariableHeightWidgets = false;
    $scope.performanceData = {};
    $scope.shouldSendPerformanceReport = true;

    init();
    registerEvents();

    var drawOptionPanelId = 'dashboard-draw-options';
    // Check the layout contains at least one widget
    $scope.sidePanelActive = SlidePanelFactory.isActive;
    $scope.layoutHasWidgets = DesignFactory.layoutHasWidgets;
    $scope.pageHasWidgets = DesignFactory.pageHasWidgets;
    $scope.hasOnlyChatGptWidgets = DesignFactory.hasOnlyChatGptWidgets;
    $scope.getDashTitle = getDashTitle;
    $scope.getDashSubTitle = getDashSubTitle;
    $scope.canShowDashSubTitle = canShowDashSubTitle;
    $scope.rebuildPage = rebuildPage;
    $scope.widgetIsInBuildMode = widgetIsInBuildMode;
    $scope.triggerPageLoader = triggerPageLoader;
    $scope.createDataWidget = createDataWidget;
    $scope.createMediaWidget = createMediaWidget;
    $scope.createAdminWidget = createAdminWidget;
    $scope.createChatGPTWidget = createChatGPTWidget;
    $scope.customizeReport = customizeReport;
    $scope.triggerGranularControlModal = triggerGranularControlModal;
    $scope.canShowGranularControlButton = canShowGranularControlButton;
    $scope.emptyDashboardLayouts = emptyDashboardLayouts;
    $scope.isEmptyDashboard = isEmptyDashboard;
    $scope.canShowEmptyLayoutsNotice = canShowEmptyLayoutsNotice;
    $scope.canShowEmptyLayoutDashboardPlaceholderAction = canShowEmptyLayoutDashboardPlaceholderAction;
    $scope.canIncludeDashFilters = canIncludeDashFilters;
    $scope.confirmDashboardClone = confirmDashboardClone;
    $scope.toggleEditPage = toggleEditPage;
    $scope.toggleEditPageReportOptions = toggleEditPageReportOptions;
    $scope.toggleResetWidgetPaletteWarning = toggleResetWidgetPaletteWarning;
    $scope.toggleDrawOptionsPanel = toggleDrawOptionsPanel;
    $scope.toggleManageLayoutsPanel = toggleManageLayoutsPanel;
    $scope.toggleExecutiveSummaryPanel = toggleExecutiveSummaryPanel;
    $scope.toggleLayoutEditMode = toggleLayoutEditMode;
    $scope.triggerExportOptionsModal = triggerExportOptionsModal;
    $scope.triggerPublishSelectionModal = triggerPublishSelectionModal;
    $scope.editLayout = editLayout;
    $scope.brandMappings = AppFactory.getBrandMappings();
    $scope.showDrawOptionPanel = showDrawOptionPanel;
    $scope.goToAllDashboards = goToAllDashboards;
    $scope.canShowBackButton = canShowBackButton;
    $scope.showTooltip = showTooltip;
    $scope.toggleFavorite = toggleFavorite;
    $scope.getDashIsFavorite = getDashIsFavorite;
    $scope.getCanFavoriteDash = getCanFavoriteDash;
    $scope.getFavoriteButtonIcon = getFavoriteButtonIcon;
    $scope.getBenchmarkMessage = getBenchmarkMessage;
    $scope.canShowFavoriteButton = window.isNUI;
    $scope.isWidgetProductPanelActive = isWidgetProductPanelActive;
    $scope.triggerViewDashboardSectionAuditLogsModal = triggerViewDashboardSectionAuditLogsModal;
    $scope.createClientSpecificCalculation = createClientSpecificCalculation;
    // Page gets set later with all the info it may need but for
    // nav highlighting, we need at least this info
    DesignFactory.setCurrentPage({'id': $stateParams.id, 'entity': pageEntity});

    function goToAllDashboards() {
        $window.location.hash = '#/dashboards';
    }

    function toggleFavorite(page) {
        if (getCanFavoriteDash(page)) {
            DesignFactory.setIsFavorite(page, !page.is_favorite);
        }
    }

    function showTooltip() {
        let title = '';
            if(!_.isUndefined(getDashTitle())){
                title =  getDashTitle().replace(/  +/g, ' ');
            }

        return widgetIsInBuildMode() || title.length > 50;
    }

    function canShowBackButton() {
        return $scope.design && window.isNUI && AppFactory.getUser().canManageDashboards && !$stateParams.isFavorite;
    }

    /**
     * @returns {*}
     */
    function getDashTitle() {
        return DesignFactory.getCurrentPage().title;
    }

    function isFavoriteRoute() {
        return $stateParams.isFavorite;
    }

    /**
     * If page is associated to a client, show associated name
     */
    function getDashSubTitle(page) {
        if (!_.isUndefined(page) && !_.isNull(page.metadata.selected_entity)) {
            return page.metadata.selected_entity.name;
        }
        return null;
    }

    /**
     * Return true if dashboard is marked as favorite
     */
    function getDashIsFavorite(page) {
        return page && page.is_favorite;
    }

    function getCanFavoriteDash(page) {
        return page && page.can_favorite;
    }

    /**
     * Return benchmark warning message for dashboard
     */
    function getBenchmarkMessage(page) {
        const { dashboard_filters } = page.metadata;
        if (!_.isNull(dashboard_filters) && dashboard_filters.client_group.values.length > 0) {
            return 'Viewing Client Group-wide benchmark averages. Apply filters to refine insights for specific clients';
        }
        if (!_.isNull(dashboard_filters) && dashboard_filters.cluster.values.length > 0) {
            return 'Viewing BU-wide benchmark averages. Apply filters to refine insights for specific clients';
        }
        return 'Viewing instance-wide benchmark averages. Apply filters to refine insights for specific clients';
    }

    function getFavoriteButtonIcon(page) {
        return getDashIsFavorite(page) ? 'fa-star': 'fa-star-o';
    }

    function canShowDashSubTitle(page) {
        return !_.isUndefined(page)
            && !_.isNull(page.metadata.selected_entity)
            && !AppFactory.getUser().isClient()
            && !AppFactory.getUser().isClientGroup();
    }

    function widgetIsInBuildMode() {
        return WidgetBuilderUIService.isActive();
    }

    function rebuildPage() {
        $scope.design = undefined;
        init();
    }

    function triggerPageLoader() {
        $scope.design = undefined;
        $scope.state.loading = LoadingState.FETCHING;
    }

    function createDataWidget() {
        if (window.isNUI) {
            PubSub.emit("SegmentEvents", {
                event: "AddWidgetEvent",
                payload: {widget_type: 'data' }
            });
        }
        PubSub.emit($WidgetBuilderEvents.INIT_PANEL, {model: null, isDataWidget: true});
    }

    function createClientSpecificCalculation(clientId) {
        if (window.isNUI) {
            const queryParams = {
                client_id: encodeURI(clientId)
            };
            window.location.href = '#/calculations/new?' + new URLSearchParams(queryParams).toString();
        }
    }

    function createMediaWidget() {
        if (window.isNUI) {
            PubSub.emit("SegmentEvents", {
                event: "AddWidgetEvent",
                payload: {widget_type: WidgetType.MEDIA }
            });
        }
        WidgetCreateFactory.$initNew(WidgetTypeGrouping.DISPLAY);
    }

    function createAdminWidget() {
        if (window.isNUI) {
            PubSub.emit("SegmentEvents", {
                event: "AddWidgetEvent",
                payload: {widget_type: WidgetTypeGrouping.ADMIN }
            });
        }
        PubSub.emit($WidgetBuilderEvents.INIT_PANEL, {model: null, isDataWidget: false});
    }

    function createChatGPTWidget() {
        if (window.isNUI) {
            PubSub.emit("SegmentEvents", {
                event: "AddWidgetEvent",
                payload: { widget_type: WidgetType.CHATGPT }
            });
        }
        let chatGptWidgetModel = {
            title: 'ChatGPT Widget',
            type: WidgetType.CHATGPT,
            width: 12,
            height: 10
        }
        chatGptWidgetModel = WidgetBuilderDataModelFactory.getChatGptWidgetBuilderModel(chatGptWidgetModel);
        chatGptWidgetModel.layout_id = DesignFactory.getCurrentLayout().id;
        chatGptWidgetModel.id = null;
        WidgetFactory.save(chatGptWidgetModel).then(function (newWidget) {
            PubSub.emit($WidgetBuilderEvents.ADD_WIDGET, newWidget);
        });
    }

    function customizeReport() {
        if (window.isNUI) {
            PubSub.emit('SegmentEvents', {
                event: 'BuildPDFReportStartEvent',
                payload: {page: DesignFactory.getCurrentPage() }
            });
        }
        ExportReportFactory.$initPdfOptions();
    }

    function canShowGranularControlButton() {
        const isPredefinedSuperAdmin = AppFactory.getUser().isPredefinedSuperAdmin();
        const permission = Permission.hasPermission(
            Permission.moduleName.DASHBOARD,
            Permission.permissionKey.MANAGE_ACCESS_LISTS,
        );
        return isPredefinedSuperAdmin && permission;
    }

    function triggerGranularControlModal() {
        if (canShowGranularControlButton()) {
            EventBus.signal('$PageNuiEvents:TriggerGranularControlModal', {
                id: DesignFactory.getCurrentPage().id
            });
        }
    }

    function emptyDashboardLayouts() {
        return canShowEmptyLayoutsNotice();
    }

    function isEmptyDashboard() {
        return !$scope.pageHasWidgets()
            && $scope.design
            && _.size($scope.design.page.layouts) === 1;
    }

    function canShowEmptyLayoutsNotice() {
        return DesignFactory.canShowEmptyLayoutsNotice();
    }

    function canShowEmptyLayoutDashboardPlaceholderAction() {
        return AppFactory.getUser().isAdmin();
    }

    function canIncludeDashFilters() {
        return $scope.design && !$scope.design.page.metadata.disable_dash_filters;
    }

    function showDrawOptionPanel() {
        return DrawOptionPanelFactory.getIsShowing() && drawOptionPanelId === DrawOptionPanelFactory.getPanelId()
    }

    /**
     * Creates a copy of the dashboard, saves it and moves to it
     */
    function confirmDashboardClone() {
        function clonePage() {
            var copyOptions = PageDataGridFactory.getCloneOptions();
            PageResource.copy($scope.design.page.id, copyOptions).then(function (clonedPageId) {
                swal.close();
                UIFactory.notify.showSuccess('Dashboard successfully cloned');
                if ($stateParams.isFavorite) {
                    $timeout(function() {
                        EventBus.signal(CHANGE_ROUTE_TO_DASHBOARD, clonedPageId);
                    });
                } else {
                    $state.go('dash', {id: clonedPageId});
                }
            }, function () {
                swal.enableButtons();
            });
        }

        var options = DataGridFactory.getCloneOptions({
            text: 'This will create a copy of this dashboard that you will be able to modify.',
            entityName: $scope.design.page.title,
            pageId: $scope.design.page.id,
            callback: clonePage
        });

        UIFactory.confirm(options);
    }

    function toggleEditPage() {
        PageFactory.$editPage();
    }

    function toggleEditPageReportOptions() {
        PageFactory.$editPageReportOptions();
    }

    /**
     * Toggle layout draw options panel fn
     */
    function toggleDrawOptionsPanel() {
        var layout = DesignFactory.getCurrentLayout();
        $scope.drawOptionsPanelOptions = DrawOptionPanelFactory.initializeDashboardOptions({
            panelId: drawOptionPanelId,
            layoutId: layout.id,
            headerTitle: 'Section Draw Options',
            drawOptions: layout.metadata.draw_options
        });
    }

    function toggleResetWidgetPaletteWarning() {
        var paletteHtml = '<div class="flex flex-center mt20">';
        _.each(DesignFactory.getCurrentPage().metadata.chart_palette, function(color) {
            paletteHtml += '<p style="border-radius:50%;width: 24px;height:24px;margin: 0 4px;background-color:' + color + '"></p>';
        });
        paletteHtml += '</div>';

        var options = {};
        options.text = 'This will reset all modified widget chart palettes to the dashboard color palette below:\n\n' + paletteHtml;
        options.html = true;
        options.closeOnConfirm = false;
        options.confirmButtonText = 'Yes';
        options.confirmFn = function() {
            return PageFactory.resetWidgetChartPalettes(DesignFactory.getCurrentPage().id, true).then(function() {
                if (window.isNUI) {
                    PubSub.emit("SegmentEvents", {
                        event: "ResetWidgetColorPalette",
                        payload: DesignFactory.getCurrentPage()
                    });
                }
                _.each(DesignFactory.getAllWidgets(), function(widget) {
                    widget.metadata.chart_palette = null;
                });
                LayoutFactory.updateLayoutWidgets();
                $timeout(function() {
                    LayoutFactory.$rebuildAllWidgets();
                    swal.close();
                    UIFactory.notify.showSuccess('Palette successfully resetted');
                }, 0, false);
            });
        };
        UIFactory.confirm(options);
    }

    /**
     * Toggle layout draw options panel fn
     */
    function toggleExecutiveSummaryPanel() {
        UIExecutiveSummaryPanelFactory.initPanel();
    }

    function toggleManageLayoutsPanel() {
        ManageLayoutFactory.init();
    }

    function setDefaultPerformanceDetails() {
        $scope.performanceData = {
            dashboard: {
                id: $stateParams.id,
                startTime: Date.now()
            },
            url: window.location.href
        }
    }

    function init() {
        var designRequest = getDesignRequest();
        $scope.state.loading = LoadingState.FETCHING;
        $scope.vars = vars;
        $scope.pageEntity = pageEntity;

        setDefaultPerformanceDetails();

        var user = AppFactory.getUser();

        // Need widget types before fetching design
        WidgetFactory.getColumnValues('type').then(function(data) {
            WidgetFactory.setWidgetTypes(data.plain());

            designRequest.then(function(data) {
                setDesign(data);
            }).catch(function (error) {
                const route = UserLoginFactory.getDefaultHomeRoute(user,true);
                if (route.state === $state.current.name)
                {
                    $state.go('welcome');
                }
                else {
                    if (window.isNUI) {
                        $timeout(function () {
                            WindowUtilUIService.navigateToNewApp(route.state);
                        }, 3000);
                    } else {
                        $state.go(route.state, route.params, route.options);
                    }
                }
            });
        }).catch(function () {
            if(window.isNUI){
                location.reload();
            }
        });

        $scope.isAnalyticsRoute = user.isAnalyticsRoute;
        $scope.isIORoute = user.isIORoute;
        $scope.hasDevTools = user.hasDevTools;
        $scope.userCanFilter = !user.isClient();

        // gives access only to super admin and cluster admin
        $scope.canAccessExportBuilder = Permission.hasPermissionToWrite(
          Permission.moduleName.REPORTSTUDIO,
          user.isAdmin()
        ) && $rootScope.util.isModuleAvailable(AppModule.REPORT_STUDIO);

        $scope.isChatGptWidgetEnabled = user.isModuleAvailable(AppModule.CHATGPT_WIDGET);
        $scope.hasChatGPTIntegration = user.isModuleAvailable(AppModule.CHATGPT);
    }

    /**
     * Loads all the layouts data of the current dashboard except the one that is already loaded.
     *
     * @param {Object} page - Dashboard record
     */
    function fetchAllLayoutsData(page) {
        const designResource = DesignFactory.getResource(pageType);
        const sortedLayouts = _.sortBy(page.layouts, 'display_order');
        let loadedLayoutId = null;
        if (DesignFactory.getCurrentLayout()?.id) {
            loadedLayoutId = DesignFactory.getCurrentLayout().id;
        } else {
            loadedLayoutId = sortedLayouts.length ? sortedLayouts[0]?.id : null;
        }
        sortedLayouts.forEach((layout) => {
            if (loadedLayoutId !== layout.id) {
                page.layouts[layout.id].loaded = false; // This flag is used to show loading screen when the section data is not loaded yet.
                designResource.one($stateParams.id).get({all: true, layout_id: layout.id}).then(function(layoutData) {
                    layoutData.page.layouts[layout.id].loaded = true;
                    page.layouts[layout.id] = layoutData.page.layouts[layout.id];

                    // Once we fetch the layout data, update the state data to let it reflect on the UI.
                    DesignFactory.setCurrentPage(page);
                    PageFactory.updatePageLayouts();
                    LayoutFactory.updateLayoutWidgets();
                });
            } else {
                page.layouts[layout.id].loaded = true;
            }
        })
    }

    function setDesign(data) {

        // TODO: potentially remove -> This alters the name on untitled sections in all the visible areas of the dash
        Object.keys(data.page.layouts).forEach((layoutKey) => {
            const title = data.page.layouts[layoutKey].title;
            if (_.isEmpty(_.trim(title))) {
                data.page.layouts[layoutKey].title = "Untitled Section";
            }
        });

        $scope.state.loading = LoadingState.DONE;
        $scope.design = data.plain();
        var page = data.page;
        $scope.currentModule = page.module;
        DesignFactory.setCurrentPage(page);
        if (_.isNull(pageEntity)) { // Normal dashboard
            fetchAllLayoutsData(page);
        }
        AppFactory.setCurrentClientCurrency(page.client_currency_code);
        AppFactory.setPageTitle(page.title);

        $('#right-frame').addClass('page-dash');

        if (!_.isEmpty(page.layouts)) {
            // Used to know which layout widget belongs to
            var sortedLayouts = _.sortBy(page.layouts, 'display_order');
            const currentLayout = DesignFactory.getCurrentLayout();
            if (currentLayout) {
                $scope.activeLayout = _.find(sortedLayouts, {id: currentLayout.id})
                  || _.first(sortedLayouts);
            } else {
                $scope.activeLayout = _.first(sortedLayouts);
            }

            DesignFactory.setCurrentLayoutId($scope.activeLayout.id);
            ExecutiveSummaryFactory.fetchExecutiveSummary($scope.activeLayout.id);
            onLayoutChanged($scope.activeLayout);
        }

        onDateRangeChanged();

        let dashboardFilterStatus = false;
        const { metadata, id, data_sources } = page;

        // checking for only global filters and floating filters is selected or not
        if (metadata.selected_entity && metadata.dashboard_filters) {
          if (metadata.dashboard_filters.client?.values.length > 0) {
            dashboardFilterStatus = metadata.groupedClientIds.includes(
              metadata.dashboard_filters.client.values[0].id
            );
          } else if (!_.isNil(data_sources) && !dashboardFilterStatus && !metadata.dashboard_filters.client_group?.values.length && !metadata.dashboard_filters.cluster?.values.length) {
              dashboardFilterStatus = data_sources.some((source) => {
              const keyFilterName = `${source.data_type}|${source.data_source_id}|${source.data_view_id}|name`;
              return (
                metadata.dashboard_filters.widget_sourced?.[id]?.[keyFilterName]
                  ?.values?.length > 0
              );
            });
          }
        }

        // Set any dashboard level filters, if no specific entity is already set on the dashboard
        if (_.isNull(metadata.selected_entity)) {
            DashboardFilterPanelFactory.setFilters(metadata.dashboard_filters);
            DashboardFilterHeaderFactory.setActiveFilters();
        }
        else if(!dashboardFilterStatus) {
            DashboardFilterHeaderFactory.resetFilterValues();
        }

        $scope.hasGranularAccess = hasGranularAccess(page);
        $scope.userCanEdit = page.can_be_edited && Permission.hasPermissionToWrite(Permission.moduleName.DASHBOARD, page.can_be_edited);
        $scope.userCanCopy = Permission.hasPermissionToWrite(Permission.moduleName.DASHBOARD, page.can_be_copied);
        $scope.userCanAddWidgets = page.can_be_edited && Permission.hasPermissionToWrite(
            Permission.moduleName.WIDGET,
            page.can_be_edited
        );
        $scope.userCanEditExecSummaries = Permission.hasPermissionToWrite(
            Permission.moduleName.DASHBOARDEXECUTIVESUMMARIES,
            !AppFactory.getUser().isClient()
        );
        $scope.pageIsPredefined = page.is_predefined;
        $scope.pageIsDynamic = page.is_dynamic;
        var user = AppFactory.getUser();
        $scope.userIsAdmin = user.isAdmin();
        $scope.isSuperAdmin = user.isSuperAdmin();
        $scope.isDashboardLocked = page.has_granular_permissions;
        $scope.canShowWalkMeVideo = !window.isNUI && user.isAdmin() && user.isRevLocalInstance;
        $scope.canViewAuditLogs = user.canAccessAuditLogs();
        $scope.$broadcast('design:page:callback');
        PubSub.emit('design:page:callback');

        $scope.showDetailsModal = false;
        DetailsModalFactory.onIsActive(_onDetailsModalToggle);

        $scope.canManageSections = Permission.hasPermissionToWrite(
          Permission.moduleName.LAYOUT
        );

        $scope.canEditSectionDrawOptions = Permission.hasPermission(
            Permission.moduleName.LAYOUT,
            Permission.permissionKey.CAN_EDIT_DRAW_OPTIONS
        ) && $scope.canManageSections;

        $scope.canEditReportingOptions = Permission.hasPermission(
          Permission.moduleName.DASHBOARD,
          Permission.permissionKey.CAN_EDIT_REPORTING_OPTIONS,
          $scope.userCanEdit
        );

        $scope.canPublishToLibrary = function () {
            return Permission.hasPermission(
              Permission.moduleName.DASHBOARD,
              Permission.permissionKey.CAN_PUBLISH_TO_LIBRARY,
              $scope.userCanEdit
            ) && !$scope.pageIsPredefined && $scope.pageHasWidgets() && hasNonChatGptWidgets() && !isDashboardUsingClientCalculation();
        };

        $scope.isClientAbleToFilter = page.metadata.selected_entity?.type === 'client' && window.isNUI && Permission.hasPermissionToWrite(Permission.moduleName.CLIENTCALCULATIONS) && user.isModuleAvailable(AppModule.CLIENT_CALCULATIONS);
        $scope.clientId = page.metadata.selected_entity?.id;

        // Expose page too WalkMe
        _tapWalkMe.page = {};
        _tapWalkMe.page.title = page.title;
        _tapWalkMe.page.entity = page.entity;
    }

    function isDashboardUsingClientCalculation() {
        const widgetsArray = Object.values(DesignFactory.getAllWidgets());
        return widgetsArray.some(widget =>
            widget.metadata?.dynamic?.filters.some(column => column.client_id) ||
            widget.metadata?.data_columns?.grouped.some(column => column.client_id) ||
            widget.metadata?.data_columns?.selected.some(column => column.client_id)
        );
    }

    /**
     * Return design request and append id if present in state params
     */
    function getDesignRequest() {

        // If a page has an entity then it is predefined or else it is null and a normal dashboard
        var isPredefinedPage = !_.isNull(pageEntity);
        var designResource = DesignFactory.getResource(pageType);
        if (isPredefinedPage) {
            // Predefined page
            if (_.isUndefined($stateParams.id)) {
                return designResource.one(pageEntity).get({all: true});
            }
            // Predefined page + id
            else {
                return designResource.one(pageEntity).one($stateParams.id).get({all: true});
            }
        }
        // Normal dashboard page
        else {
            const params = {
                all: true,
                single_layout_widgets_only: true,
            }
            // Fetch the widgets data for the current layout first when rebuilding dashbaord
            if (DesignFactory.getCurrentLayout()?.id) {
                params.layout_id = DesignFactory.getCurrentLayout().id;
            }
            return designResource.one($stateParams.id).get(params);
        }
    }

    function hasGranularAccess(page) {
        if (!page.has_granular_permissions) return true;

        return page.granular_access_levels.can_be_edited;
    }

    /**
     * Triggers the layout into resize/move widget mode
     */
    function toggleLayoutEditMode() {
        LayoutFactory.toggleEditMode();
    }

    // @TODO-DRAFTMODE:  KEEP HERE for when we reevaluate draft mode
    // function returnToBuildMode() {
    //     var options = {};
    //     options.text = 'Returning to draft mode will hide this dashboard from anyone who has access to it until you are done drafting again. Also, if this dashboard is a scheduled as a report, it will no longer be sent.';
    //     options.type = null;
    //     options.imageUrl = $.core.setCorePath('modules/shared/images/pencil.png');
    //     options.closeOnConfirm = false;
    //     options.confirmButtonText = 'Continue';
    //     options.confirmFn = function() {
    //         var currentPage = DesignFactory.getCurrentPage();
    //         return PageFactory.updateBuildMode(currentPage.id, true).then(function() {
    //             currentPage.is_in_build_mode = true;
    //             DesignFactory.setCurrentPage(currentPage);
    //             DesignFactory.getNavPageList().then(function() {
    //                 DesignFactory.$refreshMenu();
    //             });
    //             swal.close();
    //             UIFactory.notify.showSuccess('Drafting successfully reenabled');
    //         });
    //     };
    //     UIFactory.confirm(options);
    // }

    function triggerExportOptionsModal() {
        ExportOptionsFactory.init();
    }

    function triggerPublishSelectionModal() {
        const colorPalette = DesignFactory.getPageColorPalette();
        if(colorPalette && $scope.design?.page?.metadata) {
            $scope.design.page.metadata.chart_palette = colorPalette;
        }
        PubSub.emit(
            $PageLibraryEvents.INIT_PUBLISH_SELECTION_MODAL,
            {page: $scope.design.page, layout: DesignFactory.getCurrentLayout()}
        );
    }

    function editLayout() {
        EditLayoutFactory.init();
    }

    /**
     * Cleans the unwanted data in the PerformanceData
     */
    function cleanPerformanceData() {
        delete $scope.performanceData.dashboard.dateRange.comparison_date_range.formatted;
        delete $scope.performanceData.dashboard.dateRange.current_date_range.formatted;
        delete $scope.performanceData.dashboard.layout.startTime;
        let widgets = $scope.performanceData.dashboard.layout.widgets;
        for(let key in widgets) {
            delete widgets[key].startTime;
        }
    }

    /**
     * Builds the data of the filters applied on the dashboard.
     */
    function buildAppliedFiltersData() {
        let filters = {dashboard: [], widgets: []};
        DashboardFilterHeaderFactory.shared.activeFilters.dash.forEach(filter => {
            filters.dashboard.push({
                label: filter.label,
                values: filter.values.map(value => value.id)
            })
        })
        DashboardFilterHeaderFactory.shared.activeFilters.widgets.forEach(filter => {
            // Question mark [?] added because for goals widget there is no id and data_view [Fix RP-2577]
            filters.widgets.push({
                id: filter.dataSource?.id,
                type: filter.dataSource?.type,
                data_view: filter.dataSource?.data_view,
                field: filter.field
            })
        })
        $scope.performanceData.dashboard.filters = filters;
    }

    /**
     * Sends the Performance Report to backend for logging
     */
    function sendPerformanceReport() {
        if($scope.shouldSendPerformanceReport) {
            $scope.shouldSendPerformanceReport = false;
            let dashboard = $scope.performanceData.dashboard;
            dashboard.layout.loadTime = Date.now() - dashboard.layout.startTime;
            let performanceData = Object.assign({}, $scope.performanceData);
            let widgets_data = [];
            for(let key in performanceData.dashboard.layout.widgets) {
                let widget = performanceData.dashboard.layout.widgets[key];
                widget.id = key;
                widgets_data.push(widget);
            }
            performanceData.dashboard.layout.widgets = widgets_data;
            buildAppliedFiltersData();
            cleanPerformanceData();
            AppService.sendPerformanceReport({
                event: 'design_page',
                performanceData
            });
        }
    }

    /**
     * Checks if all the widgets in the section/layout are loaded
     */
    function checkAllWidgetsLoaded() {
        let widgets = $scope.performanceData.dashboard.layout.widgets;
        if(_.isEmpty(widgets)) {
            return false;
        }
        for(let key in widgets) {
            if(widgets[key].loadTime == null) {
                return false;
            }
        }
        return true;
    }

    /**
     * Called when a new widget gets initiated
     */
    function onWidgetInitiated(widget) {
        $scope.performanceData.dashboard.layout.widgets[widget.id] = {
            startTime: Date.now(),
            type: widget.type
        };
    }

    /**
     * Called when a widget finishes loading.
     * Generates the performance report for the widget
     */
    function onWidgetLoaded(widget) {
        let widgets = $scope.performanceData.dashboard.layout.widgets;
        if(widgets[widget.id]) {
            widgets[widget.id].loadTime = Date.now() - widgets[widget.id].startTime;
        }
        if(checkAllWidgetsLoaded()) {
            sendPerformanceReport();
        }
    }

    /**
     * Called when a layout/section has changed
     * Generates the performance report for the layout/section
     */
    function onLayoutChanged(layout) {
        if (layout) {
            DesignFactory.setCurrentLayoutId(layout.id);
            $scope.performanceData.dashboard.layout = {
                id: layout.id,
                startTime: Date.now(),
                widgets: {}
            };
            $scope.shouldSendPerformanceReport = true;
        }
    }

    /**
     * Resets the layout performance data
     */
    function resetLayoutPerformanceData() {
        if ($scope.performanceData.dashboard.layout) {
            $scope.performanceData.dashboard.layout.startTime = Date.now();
            let widgets = $scope.performanceData.dashboard.layout.widgets;
            for (const key in widgets) {
                widgets[key] = {
                    startTime: Date.now(),
                    type: widgets[key].type
                }
            }
            $scope.performanceData.dashboard.layout.widgets = widgets;
            $scope.shouldSendPerformanceReport = true;
        }
    }

    /**
     * Called when the Date range of the dashboard gets changed.
     * Re-initializes the layout performance data as the section/layout gets soft reloaded.
     */
    function onDateRangeChanged() {
        UuidService.kill();
        $scope.performanceData.dashboard.dateRange = {
            current_date_range: AppFactory.getDateRange(),
            comparison_date_range: AppFactory.getComparisonDateRange()
        };
        resetLayoutPerformanceData();
    }

    /**
     * Called when a dashboard filter is applied or reset.
     * Re-initializes the layout performance data as the section/layout gets soft reloaded.
     */
    function onDashboardFiltersUpdated() {
        resetLayoutPerformanceData();
    }

    /**
     * Handles all the performance tracking events and generates Performance reports
     * @param data
     */
    function handlePerformanceTrackingEvents(data) {
        switch(data.event) {
            case $WidgetEvents.WIDGET_INITIATED:
                onWidgetInitiated(data.payload);
                break;
            case $WidgetEvents.WIDGET_LOADED:
                onWidgetLoaded(data.payload);
                break;
            case $DateRangeEvents.DATE_RANGE_CHANGE:
                onDateRangeChanged();
                break;
            case $LayoutEvents.CHANGED:
                onLayoutChanged(data.payload);
                break;
            case DashboardFilterEvents.FILTERS_UPDATED:
                onDashboardFiltersUpdated();
                break;
        }
    }

    function registerEvents() {
        const $dashHeader = $('div#dash-header');
        $dashHeader.attr('initialHeight', $dashHeader.outerHeight(true));
        $('#right-frame').scroll(function() {
            // Header scroll spy
            var $page = $('div.design-page');
            var $header = $('div#dash-header');
            var threshold = 40; // Starting point
            var margin = parseInt($header.attr('initialHeight')); // Original height of dash header
            if (!(margin > 0)){
                margin = 85;
            }
            var $dashfilterheader = $('div#dashboard-filter-header');
            var hasDashFilters = $dashfilterheader.is(':visible');
            if (hasDashFilters) {
                margin += $dashfilterheader.outerHeight(true);
                threshold = 30;
            }
            if ($(this).scrollTop() >= threshold) {
                $page.css({marginTop: margin});
                $header.addClass('fixed');
                $dashfilterheader.addClass('fixed');
            }
            else {
                $page.css({marginTop: 0});
                $header.removeClass('fixed');
                $dashfilterheader.removeClass('fixed');
            }
        });

        $scope.$on('$destroy', function() {
            DesignFactory.resetVars();
            PageFactory.resetState();
            LayoutFactory.resetState();
            WidgetBuilderService.resetPanelState();
            WidgetBuilderService.resetWidgetModel();
            // reset client currency code to empty string
            AppFactory.setCurrentClientCurrency('');

            // Reset widget filters before moving to another dashboard
            if (!DashboardFilterPanelFactory.shared.isShowing) {
                DashboardFilterPanelFactory.resetWidgetFilterOptionList();
            }
            SlidePanelFactory.closeAll();
            DashboardFilterHeaderFactory.setActiveFilters();
            DetailsModalFactory.offIsActive(_onDetailsModalToggle);
            PubSub.off($SlidePanelEvents.CLOSE, onPanelClose);
            PubSub.off($SlidePanelEvents.CLOSE_ALL, onPanelCloseAll);
            PubSub.off(PageEvents.PERFORMANCE_TRACKING, handlePerformanceTrackingEvents);
            EventBus.destroy('$PageNuiEvents:ReloadState');
        });
        PubSub.on($SlidePanelEvents.CLOSE, onPanelClose);
        PubSub.on($SlidePanelEvents.CLOSE_ALL, onPanelCloseAll);
        PubSub.on(PageEvents.PERFORMANCE_TRACKING, handlePerformanceTrackingEvents);
        EventBus.listen('$PageNuiEvents:ReloadState', () => {
            $state.reload();
        });
    }

    function onPanelClose(sourcePanel) {
        if (sourcePanel === drawOptionPanelId) {
            $scope.drawOptionsPanelOptions = null;
        }
    }

    function onPanelCloseAll(sourcePanel) {
        if (sourcePanel !== drawOptionPanelId) {
            $scope.drawOptionsPanelOptions = null;
        }
    }

    function _onDetailsModalToggle(isActive) {
        // If we are calling the details modal from within the details modal, we need to persist it
        if (isActive && $scope.showDetailsModal) {
            $scope.showDetailsModal = false;
            //DetailsModalFactory.$getElement().modal('hide');
        }
        $timeout(function() {
            $scope.showDetailsModal = isActive;
        }, 0);
    }

    function hasNonChatGptWidgets() {
        let hasNonChatGptWidgets = false;
        let pageLayouts = $scope.design?.page.layouts ?? [];
        _.forEach(pageLayouts, (layout) => {
            if (!hasNonChatGptWidgets) {
                hasNonChatGptWidgets = DesignFactory.hasNonChatGptWidgets(layout);
            }
        });

        return hasNonChatGptWidgets;
    }

    function isWidgetProductPanelActive() {
        return WidgetBuilderUIService.isWidgetProductPanelActive();
    }

    function triggerViewDashboardSectionAuditLogsModal() {
        PubSub.emit(
            ViewAuditLogsEvent.VIEW_DASHBOARD_SECTION_AUDIT_LOGS,
            { page: $scope.design.page, layout: DesignFactory.getCurrentLayout() }
        );
    }
}
