import { useGaugeAxes } from '@/modules/core/charts/am5/gauge/composables/useGaugeAxes';
import { useMultiAxes } from '@/modules/core/charts/am5/gauge/composables/useMultiAxes';
import { useBigNumber } from '@/modules/core/charts/am5/gauge/composables/useBigNumber';
import { RadarChart } from '@amcharts/amcharts5/radar';
import { percent, Modal } from '@amcharts/amcharts5';
import { Constant, ZeroDataMessageGaugeChart } from '@/modules/core/charts/am5/charts.constants';

export function useGaugeChart(context) {
  const { root, chart, config } = context();
  const { addChartAxes } = useGaugeAxes(context);
  const { addMultiAxes } = useMultiAxes(context);
  const { createInteriorBigNumbers } = useBigNumber(context);

  function initGaugeChart() {
    chart.value = [];

    const baseSettings = {
      width: config.value.comparisonEnabled ? percent(50) : percent(100),
    };

    createChart(baseSettings);
    if (config.value.comparisonEnabled) {
      baseSettings.x = percent(50);
      createChart(baseSettings);
    }

    // used when the big numbers are set to "inside"
    // otherwise added through radar hands as they go inside the pin
    if (config.value.showBigNumber) {
      createInteriorBigNumbers();
      if (config.value.comparisonEnabled) {
        // add them on the comparison chart too
        createInteriorBigNumbers(true);
      }
    }

    // Here adds a modal when zero values appears for gauge plot type alone
    if (
      config.value.popupAndTooltipStatus &&
      !config.value.isMultiAxis() &&
      !config.value.isExporting &&
      !config.value.isReportStudioWidget()
    ) {
      loadZeroValuesModal();
    }
    // default option is to compare the selected metrics (v2 gauge)
    if (config.value.isMultiAxis()) {
      // otherwise we build the bands ourselves
      addMultiAxes();
    } else {
      // chart axes are the bands that fill to 100%
      addChartAxes();
    }
  }

  function createChart(settings = {}) {
    chart.value.push(
      root.value.container.children.push(
        RadarChart.new(root.value, {
          startAngle: config.value.startAngle,
          endAngle: config.value.endAngle,
          height: percent(90),
          layout: root.value.verticalLayout,
          radius: percent(getRadius()),
          ...settings,
        })
      )
    );
  }

  /**
   * Radius needs to change as we mess with the range.  a small range by default tries to display
   * a large circle to scale the whole thing in, so we need to adjust so we're zoomed in on the
   * slice of the full circle we should be displaying
   */
  function getRadius() {
    const { gaugeRange, comparisonEnabled } = config.value;
    // default value for semicircle
    if (gaugeRange === 180) {
      return 70;
    }

    if (gaugeRange >= 225) {
      return comparisonEnabled ? 73 : 78;
    }

    if (gaugeRange >= 180) {
      return comparisonEnabled ? 75 : 80;
    }

    if (gaugeRange >= 150) {
      return comparisonEnabled ? 50 : 55;
    }

    if (gaugeRange >= 135) {
      return comparisonEnabled ? 50 : 40;
    }

    if (gaugeRange >= 120) {
      return comparisonEnabled ? 50 : 35;
    }

    if (gaugeRange >= 105) {
      return comparisonEnabled ? 50 : 30;
    }

    if (gaugeRange >= 75) {
      return comparisonEnabled ? 35 : 20;
    }

    return comparisonEnabled ? 30 : 15;
  }

  function loadZeroValuesModal() {
    const buttonId = `${ZeroDataMessageGaugeChart.BUTTON_ID}${
      config.value.get(Constant.WIDGET).id
    }`;
    const modal = Modal.new(root.value, {
      content: `${ZeroDataMessageGaugeChart.MODAL_CONTENT}
                  <button id="${buttonId}"
                  style="padding: 5px 10px;
                  background: blue;
                  color: white;
                  border: none;
                  border-radius: 5px;
                  float: right;
                  margin-top: 1em;
                  cursor: pointer;">
                  OK
                  </button>`,
    });
    modal.open();
    // Attach event listener after modal is created
    document.addEventListener(Constant.CLICK, (event) => {
      if (event.target && event.target.id === buttonId) {
        modal.close();
      }
    });
  }

  return {
    initGaugeChart,
  };
}
