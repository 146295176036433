export const LAT_LONG = 'latlong';
export const COUNTRY = 'country';
export const STATE = 'state';
export const CITY = 'city';
export const COUNTY = 'county';
export const DMA = 'dma';
export const CLUSTER = 'cluster';
export const BOUNDARY = 'boundary';
export const CONGRESSIONAL_DISTRICT = 'congressional_district';
export const ZIP_CODE = 'zipcode';
export const THE_TRADE_DESK_ID = '61';
export const FACEBOOK_ADS_ID = '39';
export const TOP_LEFT = 'top-left';
export const BLUE = 'blue';
export const SYMBOL = 'symbol';
export const MOVEEND = 'moveend';
export const CLICK = 'click';

export const WEBKITFULLSCREENCHANGE = 'webkitfullscreenchange';
export const FULLSCREENCHANGE = 'fullscreenchange';
export const MOZFULLSCREENCHANGE = 'mozfullscreenchange';
export const $MapBoxEvents = {
  LAYER_UPDATE: '$MapBoxEvents:LAYER_UPDATE',
};
export const LAYERS_PRIORITY = {
  [LAT_LONG]: 0,
  [COUNTRY]: 1,
  [STATE]: 2,
  [COUNTY]: 3,
  [CITY]: 4,
  [ZIP_CODE]: 5,
  [DMA]: 6,
  [CONGRESSIONAL_DISTRICT]: 7,
};

export const MAPBOX_LAYERS = {
  LAT_LONG_CLUSTER_LAYER: 'latLongClusterLayer',
  LAT_LONG_CLUSTER_COUNT_LAYER: 'latLongClusterCountLayer',
  LAT_LONG_METRIC_UNCLUSTER_LAYER: 'latLongMetricUnClusterLayer',
  LAT_LONG_METRIC_CLUSTER_COUNT_LAYER: 'latLongMetricClusterCountLayer',
  LAT_LONG_METRIC_CLUSTER_CIRCLE_LAYER: 'latLongMetricClusterCircleLayer',
};

export const STATE_LAT_LONG = {
  AK: { geo_latitude: 63.588753, geo_longitude: -154.493062 },
  AL: { geo_latitude: 32.318231, geo_longitude: -86.902298 },
  AR: { geo_latitude: 35.20105, geo_longitude: -91.831833 },
  AZ: { geo_latitude: 34.048928, geo_longitude: -111.093731 },
  CA: { geo_latitude: 36.778261, geo_longitude: -119.417932 },
  CO: { geo_latitude: 39.550051, geo_longitude: -105.782067 },
  CT: { geo_latitude: 41.603221, geo_longitude: -73.087749 },
  DC: { geo_latitude: 38.905985, geo_longitude: -77.033418 },
  DE: { geo_latitude: 38.910832, geo_longitude: -75.52767 },
  FL: { geo_latitude: 27.664827, geo_longitude: -81.515754 },
  GA: { geo_latitude: 32.157435, geo_longitude: -82.907123 },
  HI: { geo_latitude: 19.898682, geo_longitude: -155.665857 },
  IA: { geo_latitude: 41.878003, geo_longitude: -93.097702 },
  ID: { geo_latitude: 44.068202, geo_longitude: -114.742041 },
  IL: { geo_latitude: 40.633125, geo_longitude: -89.398528 },
  IN: { geo_latitude: 40.551217, geo_longitude: -85.602364 },
  KS: { geo_latitude: 39.011902, geo_longitude: -98.484246 },
  KY: { geo_latitude: 37.839333, geo_longitude: -84.270018 },
  LA: { geo_latitude: 31.244823, geo_longitude: -92.145024 },
  MA: { geo_latitude: 42.407211, geo_longitude: -71.382437 },
  MD: { geo_latitude: 39.045755, geo_longitude: -76.641271 },
  ME: { geo_latitude: 45.253783, geo_longitude: -69.445469 },
  MI: { geo_latitude: 44.314844, geo_longitude: -85.602364 },
  MN: { geo_latitude: 46.729553, geo_longitude: -94.6859 },
  MO: { geo_latitude: 37.964253, geo_longitude: -91.831833 },
  MS: { geo_latitude: 32.354668, geo_longitude: -89.398528 },
  MT: { geo_latitude: 46.879682, geo_longitude: -110.362566 },
  NC: { geo_latitude: 35.759573, geo_longitude: -79.0193 },
  ND: { geo_latitude: 47.551493, geo_longitude: -101.002012 },
  NE: { geo_latitude: 41.492537, geo_longitude: -99.901813 },
  NH: { geo_latitude: 43.193852, geo_longitude: -71.572395 },
  NJ: { geo_latitude: 40.058324, geo_longitude: -74.405661 },
  NM: { geo_latitude: 34.97273, geo_longitude: -105.032363 },
  NV: { geo_latitude: 38.80261, geo_longitude: -116.419389 },
  NY: { geo_latitude: 43.299428, geo_longitude: -74.217933 },
  OH: { geo_latitude: 40.417287, geo_longitude: -82.907123 },
  OK: { geo_latitude: 35.007752, geo_longitude: -97.092877 },
  OR: { geo_latitude: 43.804133, geo_longitude: -120.554201 },
  PA: { geo_latitude: 41.203322, geo_longitude: -77.194525 },
  PR: { geo_latitude: 18.220833, geo_longitude: -66.590149 },
  RI: { geo_latitude: 41.580095, geo_longitude: -71.477429 },
  SC: { geo_latitude: 33.836081, geo_longitude: -81.163725 },
  SD: { geo_latitude: 43.969515, geo_longitude: -99.901813 },
  TN: { geo_latitude: 35.517491, geo_longitude: -86.580447 },
  TX: { geo_latitude: 31.968599, geo_longitude: -99.901813 },
  UT: { geo_latitude: 39.32098, geo_longitude: -111.093731 },
  VA: { geo_latitude: 37.431573, geo_longitude: -78.656894 },
  VT: { geo_latitude: 44.558803, geo_longitude: -72.577841 },
  WA: { geo_latitude: 47.751074, geo_longitude: -120.740139 },
  WI: { geo_latitude: 43.78444, geo_longitude: -88.787868 },
  WV: { geo_latitude: 38.597626, geo_longitude: -80.454903 },
  WY: { geo_latitude: 43.075968, geo_longitude: -107.290284 },
};
