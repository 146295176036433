<template>
  <div class="full-width">
    <MapboxLoadingState v-if="!isExport && isLoading" />
    <img v-if="isExport" alt="map image" :id="imageMapId" :src="mapboxImageUrl" />
    <div :id="mapId"></div>
  </div>
</template>

<script>
import { first, groupBy } from 'lodash';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { MapboxRenderService } from '@/modules/ta/widget/services/types/MapboxRenderService';
import { MapboxMixin } from '@/modules/ta/widget/mixins/MapboxMixin';
import { dispatches } from '@/modules/core/app/helpers/store';
import NotificationService from '@/modules/core/ui/services/NotificationService';
import {
  TOP_LEFT,
  MOVEEND,
  CLICK,
  WEBKITFULLSCREENCHANGE,
  FULLSCREENCHANGE,
  MOZFULLSCREENCHANGE,
  MAPBOX_LAYERS,
} from '@/modules/ta/widget/mapbox.constants';
import MapboxLoadingState from '@/modules/ta/widget/components/states/MapboxLoadingState';

export default {
  name: 'MapboxWidget',
  mixins: [MapboxMixin],
  components: { MapboxLoadingState },
  static: {
    renderService: new MapboxRenderService(),
    maps: {
      street: 'mapbox://styles/mapbox/streets-v12',
      outdoor: 'mapbox://styles/mapbox/outdoors-v12',
      light: 'mapbox://styles/mapbox/light-v11',
      dark: 'mapbox://styles/mapbox/dark-v11',
      satellite: 'mapbox://styles/mapbox/satellite-v9',
      satelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v12',
      navigationDay: 'mapbox://styles/mapbox/navigation-day-v1',
      navigationNight: 'mapbox://styles/mapbox/navigation-night-v1',
    },
    popUp: null,
    // eslint-disable-next-line tap/no-raw-text-js
    shimmerStyle: { width: '100%', height: '100%', position: 'absolute', 'z-index': 4 },
  },
  mounted() {
    this.createMap();
  },
  beforeDestroy() {
    document.removeEventListener(WEBKITFULLSCREENCHANGE, this.resizeMap);
    document.removeEventListener(FULLSCREENCHANGE, this.resizeMap);
    document.removeEventListener(MOZFULLSCREENCHANGE, this.resizeMap);
  },
  data() {
    return {
      isExport: false,
      mapboxImageUrl: null,
      isLoading: true,
    };
  },
  local() {
    return {
      map: null,
    };
  },
  computed: {
    mapId() {
      return `map-${this.id}`;
    },
    imageMapId() {
      // eslint-disable-next-line tap/no-raw-text-js
      return `${this.mapId}-image`;
    },
    center() {
      const { mapbox_position } = this.widget.metadata;

      return mapbox_position ? mapbox_position.center : this.centerPosition;
    },
    zoom() {
      const { mapbox_position } = this.widget.metadata;

      return mapbox_position ? mapbox_position.zoom : this.zoomLevel;
    },
    mapboxData() {
      return this.renderService.transformData(this.widget, this.getData(), this.chartPalette);
    },
  },
  methods: {
    async createMap() {
      try {
        dispatches.dashboardExport.setUnrenderedWidgetIds([this.widget.id]);
        mapboxgl.accessToken = this.token;
        this.map = new mapboxgl.Map({
          container: this.mapId,
          style: this.maps[this.widget.metadata.draw_options.map_type],
          center: this.center,
          zoom: this.zoom,
          projection: 'mercator',
        });
        document.addEventListener(WEBKITFULLSCREENCHANGE, this.resizeMap);
        document.addEventListener(FULLSCREENCHANGE, this.resizeMap);
        document.addEventListener(MOZFULLSCREENCHANGE, this.resizeMap);
        this.map.setMaxZoom(10);
        this.popUp = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });
        this.map.addControl(
          new MapboxGeocoder({
            accessToken: this.token,
            mapboxgl: this.map,
            marker: false,
          })
        );
        this.map.addControl(new mapboxgl.NavigationControl(), TOP_LEFT);
        if (this.isDashboard) {
          this.map.addControl(new mapboxgl.FullscreenControl(), TOP_LEFT);
        }
        this.map.on('load', this.loaded);
      } catch (err) {
        NotificationService.show(err.message);
      }
    },
    resize() {
      this.map.resize();
    },
    loaded() {
      this.map.resize();
      this.map.on('zoom', () => this.zoomend());
      this.map.on(MOVEEND, () => this.zoomend());
      this.map.on('idle', () => this.onMapIdle());
      this.map.on('mouseenter', this.latLongUnClusterLayer, (e) => this.handleUnclusteredPoints(e));
      this.map.on(
        'mouseenter',
        this.getLayerName(MAPBOX_LAYERS.LAT_LONG_METRIC_UNCLUSTER_LAYER),
        (e) => this.handleUnclusteredPoints(e)
      );
      this.map.on('mousemove', this.countryLayer, (e) => this.handleCountry(e));
      this.map.on('mousemove', this.stateLayer, (e) => this.handleState(e));
      this.map.on('mousemove', this.countyLayer, (e) => this.handleCounty(e));
      this.map.on('mousemove', this.cityLayer, (e) => this.handleCity(e));
      this.map.on('mousemove', this.zipLayer, (e) => this.handleZip(e));
      this.map.on('mousemove', this.dMALayer, (e) => this.handleDMA(e));
      this.map.on('mousemove', this.congressionalDistrictLayer, (e) =>
        this.handleCongressionalDistrict(e)
      );
      this.map.on(CLICK, this.getLayerName(MAPBOX_LAYERS.LAT_LONG_CLUSTER_COUNT_LAYER), (e) =>
        this.handleClusteredPoints(e)
      );
      this.map.on(
        CLICK,
        this.getLayerName(MAPBOX_LAYERS.LAT_LONG_METRIC_CLUSTER_COUNT_LAYER),
        (e) => this.handleMetricClusteredPoints(e)
      );
      this.map.on(
        'mouseleave',
        this.getLayerName(MAPBOX_LAYERS.LAT_LONG_METRIC_UNCLUSTER_LAYER),
        () => this.resetPopUp()
      );
      this.map.on('mouseleave', this.latLongUnClusterLayer, () => this.resetPopUp());
      this.map.on('mouseleave', this.countryLayer, () => this.resetPopUp());
      this.map.on('mouseleave', this.stateLayer, () => this.resetPopUp());
      this.map.on('mouseleave', this.countyLayer, () => this.resetPopUp());
      this.map.on('mouseleave', this.zipLayer, () => this.resetPopUp());
      this.map.on('mouseleave', this.dMALayer, () => this.resetPopUp());
      this.map.on('mouseleave', this.congressionalDistrictLayer, () => this.resetPopUp());
      this.updateMap();
    },
    zoomend() {
      const zoom = this.map.getZoom();
      const center = this.map.getCenter();
      this.widget.metadata.mapbox_position = {
        zoom,
        center,
      };
    },
    updateMap() {
      if (this.map.isStyleLoaded()) {
        this.isLoading = true;
        this.resetMap();
        this.updateLatLongLayer();
        this.updateCountryLayer();
        this.updateStateLayer();
        this.updateCountyLayer();
        this.updateCityLayer();
        this.updateZipLayer();
        this.updateDMALayer();
        this.updateCongressionalDistrictLayer();
      }
    },
    onMapIdle() {
      this.map.resize();
      this.isExport = this.isExporting;
      if (this.isExport) {
        this.mapboxImageUrl = this.map.getCanvas().toDataURL('image/png', 1);
      }
      this.isLoading = false;
      dispatches.dashboardExport.setWidgetAsRendered(this.widget.id);
    },
    handleUnclusteredPoints(e) {
      const unclusteredfeatures = e.features;
      if (unclusteredfeatures && unclusteredfeatures.length > 0) {
        const coordinates = first(unclusteredfeatures).geometry.coordinates.slice();
        while (Math.abs(e.lngLat.lng - first(coordinates)) > 180) {
          coordinates[0] += e.lngLat.lng > first(coordinates) ? 360 : -360;
        }
        this.popUp
          .setLngLat(coordinates)
          .setHTML(this.renderService.getPopupHtml(first(unclusteredfeatures).properties))
          .addTo(this.map);
      }
    },
    handleClusteredPoints(e) {
      const { features } = e;
      if (features && features.length > 0) {
        const clusterId = first(features).properties.cluster_id;
        this.map.getSource(this.latLongSource).getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          this.map.easeTo({
            center: first(features).geometry.coordinates,
            zoom,
          });
        });
      }
    },
    handleMetricClusteredPoints(e) {
      const { features } = e;
      if (features && features.length > 0) {
        const clusterId = first(features).properties.cluster_id;
        this.map
          .getSource(this.latLongMetricSource)
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            this.map.easeTo({
              center: first(features).geometry.coordinates,
              zoom,
            });
          });
      }
    },
    handleCountry(e) {
      const column = MapboxRenderService.getSelectedColumn(this.widget);
      const countryGroups = groupBy(this.getData(), (row) => row[column]?.toUpperCase());
      const countryGeoCodeGroups = groupBy(this.getData(), (row) => row.geocode0?.toUpperCase());
      const countryName = first(e.features).properties.name.toUpperCase();
      const countryISO = first(e.features).properties.iso_3166_1.toUpperCase();
      const countryISOAlpha = first(e.features).properties.iso_3166_1_alpha_3.toUpperCase();
      const countryArray =
        countryGeoCodeGroups[countryName] ||
        countryGeoCodeGroups[countryISO] ||
        countryGeoCodeGroups[countryISOAlpha] ||
        countryGroups[countryName] ||
        countryGroups[countryISO] ||
        countryGroups[countryISOAlpha];
      if (!countryArray) {
        this.resetPopUp();
        return;
      }
      this.handleLayerHover(e, countryArray, first(e.features).properties.name);
    },
    handleState(e) {
      const column = MapboxRenderService.getSelectedColumn(this.widget);
      const stateGroups = groupBy(this.getData(), (row) => row[column]?.toUpperCase());
      const stateGeoCodeGroups = groupBy(this.getData(), (row) =>
        MapboxRenderService.getValueFromGeoConfig(row.geocode1)
      );
      const state = first(e.features).properties.NAME.toUpperCase();
      const stateCode = first(e.features).properties.STUSPS.toUpperCase();
      const stateArray =
        stateGeoCodeGroups[state] ||
        stateGeoCodeGroups[stateCode] ||
        stateGroups[state] ||
        stateGroups[stateCode];
      if (!stateArray) {
        this.resetPopUp();
        return;
      }
      this.handleLayerHover(e, stateArray, first(e.features).properties.NAME);
    },
    handleCounty(e) {
      const column = MapboxRenderService.getSelectedColumn(this.widget);
      const countyGroups = groupBy(this.getData(), (row) => row[column]?.toUpperCase());
      const countyGeoCodeGroups = groupBy(this.getData(), (row) =>
        MapboxRenderService.getValueFromGeoConfig(row.geocode2)
      );
      const county = first(e.features).properties.NAME.toUpperCase();
      const countyName = first(e.features).properties.NAMELSAD.toUpperCase();
      const countyArray =
        countyGeoCodeGroups[county] ||
        countyGeoCodeGroups[countyName] ||
        countyGroups[county] ||
        countyGroups[countyName];
      if (!countyArray) {
        this.resetPopUp();
        return;
      }
      this.handleLayerHover(e, countyArray, first(e.features).properties.NAME);
    },
    handleCity(e) {
      const column = MapboxRenderService.getSelectedColumn(this.widget);
      const stateGroups = groupBy(this.getData(), (row) => {
        const stateDetails = row.geocode1?.split('-');
        if (stateDetails.length < 2) {
          return null;
        }
        return stateDetails[1].toUpperCase();
      });
      const stateCode = first(e.features).properties.STUSPS.toUpperCase();
      const stateCityDetails = stateGroups[stateCode];
      if (!stateCityDetails) {
        this.resetPopUp();
        return;
      }
      const cityGroups = groupBy(stateCityDetails, (row) => row[column]?.toUpperCase());
      const city = first(e.features).properties.NAME.toUpperCase();
      const cityFullName = first(e.features).properties.NAMELSAD.toUpperCase();
      const cityCode = first(e.features).properties.STUSPS.toUpperCase();
      const cityArray = cityGroups[city] || cityGroups[cityCode] || cityGroups[cityFullName];
      if (!cityArray) {
        this.resetPopUp();
        return;
      }
      this.handleLayerHover(e, cityArray, first(e.features).properties.NAME);
    },
    handleZip(e) {
      const column = MapboxRenderService.getSelectedColumn(this.widget);
      const zipGroups = groupBy(this.getData(), (row) => row[column]?.toUpperCase());
      const zipcode = first(e.features).properties.NAME20;
      const zipArray = zipGroups[zipcode];
      if (!zipArray) {
        this.resetPopUp();
        return;
      }
      this.handleLayerHover(e, zipArray, first(e.features).properties.NAME20);
    },
    handleDMA(e) {
      const column = MapboxRenderService.getSelectedColumn(this.widget);
      const dmaGroups = groupBy(this.getData(), (row) => row[column]?.toUpperCase());
      const dma = first(e.features).properties.NAME.toUpperCase();
      const dmaKey = first(e.features).properties.Key.toUpperCase();
      const dmaArray = dmaGroups[dma] || dmaGroups[dmaKey];
      if (!dmaArray) {
        this.resetPopUp();
        return;
      }
      this.handleLayerHover(e, dmaArray, first(e.features).properties.NAME);
    },
    handleCongressionalDistrict(e) {
      const column = MapboxRenderService.getSelectedColumn(this.widget);
      const congressionalDistrictGroups = groupBy(this.getData(), (row) =>
        row[column]?.toUpperCase()
      );
      const congressionalDistrict = first(e.features).properties.NAMELSAD.toUpperCase();
      const congressionalDistrictCode = first(e.features).properties.STATEFP.toUpperCase();
      const congressionalDistrictArray =
        congressionalDistrictGroups[congressionalDistrict] ||
        congressionalDistrictGroups[congressionalDistrictCode];
      if (!congressionalDistrictArray) {
        this.resetPopUp();
        return;
      }
      this.handleLayerHover(e, congressionalDistrictArray, first(e.features).properties.NAMELSAD);
    },
    resetPopUp() {
      this.popUp.remove();
    },
    getData() {
      const dataColumns = this.renderService.getSelectedColumns(this.widget);
      return this.renderService.getNonZeroFieldData(this.data, this.widget, dataColumns[0].field);
    },
    resizeMap() {
      setTimeout(() => this.map.resize(), 0);
    },
  },
  watch: {
    'widget.metadata.draw_options.layers': {
      deep: true,
      handler() {
        this.updateMap();
      },
    },
    // eslint-disable-next-line tap/no-raw-text-js
    'widget.metadata.draw_options.hide_zero_value': {
      deep: true,
      handler() {
        this.updateMap();
      },
    },
    // eslint-disable-next-line tap/no-raw-text-js
    'widget.metadata.draw_options.map_geographic_features': {
      deep: true,
      handler() {
        this.updateMap();
      },
    },
    // eslint-disable-next-line tap/no-raw-text-js
    'widget.metadata.draw_options.geo_layer_opacity': {
      deep: true,
      handler() {
        this.updateMap();
      },
    },
    data: {
      deep: true,
      handler() {
        this.updateMap();
      },
    },
    'widget.width': {
      deep: true,
      handler() {
        this.resize();
      },
    },
    'widget.height': {
      deep: true,
      handler() {
        this.resize();
      },
    },
    'state.isShowing': {
      deep: true,
      handler() {
        this.resize();
      },
    },
  },
};
</script>

<style lang="scss">
.mapboxgl-ctrl-geocoder--input {
  background: white !important;
  color: black !important;
}
</style>
