export const ServicePathName = {
  MARKETPLACE: 'marketplace',
  SERVICEMANAGE: 'servicemanage',
  SERVICEMANAGE_SHOWCONNECTION: 'servicemanage:showconnection',
  SERVICEMANAGE_OPEN: 'servicemanage:open',
  SERVICEMAPPINGS: 'servicemappings',
  SERVICEOVERVIEW: 'serviceoverview',
  SERVICESETTINGS: 'servicesettings',
  SERVICEDRILLDOWN: 'servicedrilldown',
  SERVICEDRILLDOWN_CLIENT: 'servicedrilldown:client',
};

export const ServiceRoute = {
  MARKETPLACE: 'marketplace',
  OVERVIEW: 'overview',
  SERVICE: 'service',
  SERVICES: 'services',
  SERVICESETTINGS: 'servicesettings',
};

export const RefreshLegacyMdsEvent = 'RefreshLegacyMdsEvent';

export const FacebookAdsServiceId = 39;
