import { ChartConfig } from '@/modules/core/charts/am5/base/models/ChartConfig';
import { Axis } from '@/modules/core/charts/am5/base/models/Axis';
import { XYSeries } from '@/modules/core/charts/am5/base/models/XYSeries';

/**
 * @class LineChartConfig
 * @extends ChartConfig
 */
export class LineChartConfig extends ChartConfig {
  /**
   * @var {Array<XYSeries>}
   */
  series;

  /**
   * @var {Array<Axis>}
   */
  categoryAxis;

  /**
   * @var {Array<Axis>}
   */
  valueAxis;

  /**
   * If chart axis are exchanged
   * @var {Boolean}
   */
  isRotated;

  /**
   * If need to show separate value axis for each series
   * @var {Boolean}
   */
  isNormalizedData;

  /**
   * If chart is full stacked chart
   * @var {Boolean}
   */
  isFullStacked;

  /**
   * @var {Boolean}
   */
  hasTooltip;

  /**
   * If chart has axis value scroll bars
   * @var {Boolean}
   */
  hasValueScrollbar;

  /**
   * Enables Cursor for chart
   * @var {Boolean}
   * @default true
   */
  cursorEnabled;

  /**
   * Set true to show a default value axis when valueAxis not passed
   * @var {Boolean}
   * @default false
   */
  useDefaultValueAxis;

  /**
   * When true, tooltip is displayed on cursor and when false, tooltip is displayed on series.
   * @var {boolean}
   * @default true
   */
  isTooltipOnCursor;

  /**
   * Set true to show chart as a spark line
   * @var {Boolean}
   * @default false
   */
  isSparkLine;

  /**
   * Set true to show chart as a stacked line chart
   * @var {Boolean}
   * @default false
   */
  isStacked;

  /**
   * Set true to show axis start at zero
   * @var {Boolean}
   * @default true
   */
  isZeroScaled;

  lineType;

  /**
   * @var {Boolean}
   */
  isLogScaled;

  /**
   * @var {Boolean}
   */
  hasRegressionLine;

  /**
   * @var {Boolean}
   */
  hasMetricLinesVisible;

  /**
   * @var {Boolean}
   */
  fontColorPicker;

  /**
   * @var {Boolean}
   */
  hasRankingMetrics;

  /**
   * @var {String}
   */
  yAxisOverride;

  /**
   * @var {Boolean}
   */
  hasComparison;

  /**
   * @var {Object}
   */
  dataSelected;

  /**
   * @var {Array}
   */
  comparisonOverWrite;

  constructor(model = {}) {
    super(model);
    const {
      cursorEnabled = true,
      useDefaultValueAxis = false,
      isTooltipOnCursor = true,
      isSparkLine = false,
      hasMetricLinesVisible = !model.hasRegressionLine,
    } = model;
    this.series = model.series?.map((singleSeries) => new XYSeries(singleSeries));
    this.categoryAxis = model.categoryAxis?.map((axis) => new Axis(axis));
    this.valueAxis = useDefaultValueAxis
      ? [new Axis()]
      : model.valueAxis?.map((axis) => new Axis(axis));
    this.depth = model.depth;
    this.angle = model.angle;
    this.isRotated = model.isRotated;
    this.isNormalizedData = model.isNormalizedData;
    this.isFullStacked = model.isFullStacked;
    this.hasTooltip = model.hasTooltip;
    this.hasValueScrollbar = model.hasValueScrollbar;
    this.cursorEnabled = cursorEnabled;
    this.isTooltipOnCursor = isTooltipOnCursor;
    this.isSparkLine = isSparkLine;
    this.isStacked = model.isStacked;
    this.isZeroScaled = model.isZeroScaled;
    this.lineType = model.lineType;
    this.isLogScaled = model.isLogScaled;
    this.hasRegressionLine = model.hasRegressionLine;
    this.hasMetricLinesVisible = hasMetricLinesVisible;
    this.fontColorPicker = model.fontColorPicker;
    this.hasRankingMetrics = model.hasRankingMetrics;
    this.yAxisOverride = model.yAxisOverride;
    this.hasComparison = model.hasComparison;
    this.dataSelected = model.dataSelected;
    this.comparisonOverWrite = model.comparisonOverWrite;

    // the regression line automatically removes the metric line (unless you have the config set to enable it)
    // if bullets aren't enabled in the config then this can result in it looking like the series has disappeared
    this.shouldOverrideBullets =
      this.hasRegressionLine && !this.hasMetricLinesVisible && !this.series[0].bullet.enabled;
  }

  overrideBullets(enabled = true) {
    this.series.forEach((configSeries) => {
      configSeries.bullet.enabled = enabled;
    });
  }
}
