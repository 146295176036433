import PieChartWidgetRenderService from '@/modules/core/charts/am5/pie/services/PieChartWidgetRenderService';

export class LegacyPieChartWidgetRenderService extends PieChartWidgetRenderService {
  /**
   * @var Widget
   */
  _widget;

  /**
   * @var array
   */
  _chartPalette;

  /**
   * @var boolean
   */
  _hasComparisonData;

  /**
   * @var boolean
   */
  _isExporting;

  /**
   * @var Object
   */
  _totalData;

  constructor(
    widget,
    chartPalette = [],
    hasComparisonData = false,
    isExporting = false,
    totalData = {}
  ) {
    super(widget.id);
    this._widget = widget;
    this._chartPalette = chartPalette;
    this._hasComparisonData = hasComparisonData;
    this._isExporting = isExporting;
    this._totalData = totalData;
  }

  getWidget() {
    return this._widget;
  }

  getFullChartPalette() {
    return this._chartPalette;
  }

  /**
   * @param columnIndex
   * @returns {Array}
   */
  getChartPalette(columnIndex = null) {
    return columnIndex
      ? this._chartPalette[columnIndex % this._chartPalette.length]
      : this._chartPalette;
  }

  getChartConfigProperties() {
    return {
      ...super.getChartConfigProperties(),
      hoverColor: this.getChartPalette(0),
    };
  }

  hasComparison() {
    return this._hasComparisonData;
  }

  /**
   * @override
   * @returns {boolean}
   */
  isExporting() {
    return this._isExporting;
  }
}
