<template>
  <section data-cy="rectangle-container">
    <div class="line-chart" :style="{ bottom: hasComparison }">
      <component
        v-show="sparklineData.length"
        :key="componentKey"
        :is="chartComponent"
        :config="getChartConfig()"
      />
    </div>
  </section>
</template>

<script>
import { ScoreCardMixin } from '@/modules/core/scorecard/mixins/ScoreCardMixin';
import LineChart from '@/modules/core/charts/am5/line/components/LineChart.vue';
import { LineChartConfig } from '@/modules/core/charts/am5/line/models/LineChartConfig';
import BarChart from '@/modules/core/charts/am5/bar/components/BarChart.vue';
import { BarChartConfig } from '@/modules/core/charts/am5/bar/models/BarChartConfig';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import {
  SeriesType,
  ChartType,
  ChartAdapters,
  ColorCodes,
} from '@/modules/core/charts/chart.constants';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export default {
  name: 'EmbedSparkline',
  components: { BarChart, LineChart },
  mixins: [ScoreCardMixin],
  props: {
    field: {
      type: String,
      required: true,
    },
    sparklineData: {
      type: [Array, Object],
      default: () => [],
    },
    categoryField: {
      type: String,
      default: 'formatted_log_date',
    },
    widgetId: {
      type: [Number, String],
      default: 0,
    },
    sparklineType: {
      type: String,
      default: SeriesType.LINE,
    },
    isComparisonEnabled: {
      type: Boolean,
    },
    sparklineColor: {
      type: String,
      default: ColorCodes.BLACK_COLOR,
    },
    isRankMetric: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentKey: 0,
    };
  },
  computed: {
    // Dynamically compute the chart component based on sparklineType
    chartComponent() {
      return this.sparklineType === SeriesType.COLUMN ? ChartType.BAR_CHART : ChartType.LINE_CHART;
    },
    hasComparison() {
      return this.isComparisonEnabled ? `${1}rem` : `${-1}rem`;
    },
    sparkLineMetricData() {
      if (this.isRankingMetricLineChart) {
        return this.sparklineData.map((v) => ({
          ...v,
          [this.sparklineLineChartField()]: this.sparklineLineChartMaxValue(),
        }));
      }

      return this.sparklineData;
    },
    isRankingMetricLineChart() {
      return (
        this.isRankMetric && this.sparklineType === SeriesType.LINE && this.sparklineData.length > 0
      );
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    sparklineLineChartField() {
      return `${this.field}_${Constant.OPEN_VALUE_FIELD_NAME}`;
    },
    sparklineLineChartMaxValue() {
      return Math.max(...this.sparklineData.map((o) => o[this.field]));
    },
    getChartConfig() {
      if (this.isRankMetric && this.componentKey === 0) {
        // reload the component to make the reverse bars for ranking metrics
        this.forceRerender();
      }

      const rankingLineSeriesProps = this.isRankingMetricLineChart
        ? {
            openValueYField: this.sparklineLineChartField(),
            strictMinMax: true,
            max: this.sparklineLineChartMaxValue(),
          }
        : {};

      const commonConfig = {
        series: [
          {
            fill: this.sparklineColor,
            labelFill: ColorCodes.WHITE_COLOR,
            fillOpacity: this.sparklineType === SeriesType.COLUMN ? 0.5 : 0.3,
            opacity: 1,
            strokeOpacity: 0.5,
            tension: 0.9,
            isCircularBullets: false,
            value: this.field,
            category: this.categoryField,
            tooltipText: '',
            name: this.label,
            color: this.sparklineColor,
            dataItemFormat:
              this.format === ColumnFormat.FORMAT_TIME
                ? ColumnFormat.FORMAT_TIME
                : ColumnFormat.FORMAT_INTEGER,
          },
        ],
        categoryAxis: [
          {
            category: this.categoryField,
            showLabels: false,
            hideGridLines: true,
            minGridDistance: 30,
            dataItemFormat: ColumnFormat.FORMAT_STRING,
          },
        ],
        valueAxis: [
          {
            axisType: ChartAdapters.VALUE,
            hideGridLines: true,
            showLabels: true,
            dataItemFormat: ColumnFormat.FORMAT_INTEGER,
            isRankingMetric: this.isRankMetric,
            ...rankingLineSeriesProps,
          },
        ],
        legend: {
          active: false,
        },
        data: this.sparkLineMetricData,
        isSparkLine: true,
        hasTooltip: true,
        isRotated: false,
        hasRankingMetrics: this.isRankMetric,
        fillAreaAboveLine: this.isRankingMetricLineChart,
      };

      if (this.sparklineType === SeriesType.COLUMN) {
        return new BarChartConfig({
          ...commonConfig,
          series: [
            {
              ...commonConfig.series[0],
              seriesType: SeriesType.COLUMN,
              isRankingMetric: this.isRankMetric,
            },
          ],
        });
      }
      return new LineChartConfig({
        ...commonConfig,
        series: [
          {
            ...commonConfig.series[0],
            seriesType: SeriesType.LINE,
            isRankingMetric: this.isRankMetric,
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* Apply styles to the first <section> element */
section {
  position: relative;
  padding: rem(0px) rem(5px);
  border-radius: $radius-small;
}

.line-chart {
  display: inline-flex;
  flex-wrap: wrap;
  width: 70%;
  height: 30px;
  position: absolute;
  left: 0;
  right: 0;

  :deep(section) {
    width: 100%;
    padding: 0;
  }
}

.b-tooltip {
  display: block;
}
</style>
