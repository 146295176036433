'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import tinycolor from 'tinycolor2';

angular.module('widget.chart.services', [])
    .factory('ChartFactory', ChartFactory)
    .factory('ChartUtilFactory', ChartUtilFactory)
    .service('ChartAxisService', ChartAxisService);

/**
 * @ngInject
 */
export function ChartFactory(
    $timeout,
    $q,
    ColumnFormat,
    ChartUtilFactory,
    MomentDateFormat,
    RelativeDateRange,
    DateFormatType,
    CoreFactory,
    AppFactory,
    WidgetDataSourceFactory,
    DateRangeFactory,
    DesignFactory,
    TimeGrouping,
    WidgetFactory,
    FontSize,
    ExportFactory,
    ChartPlotType,
    DrawOption,
    WidgetType,
    WidgetUtilService,
    ChartDataType,
    ChartAxisService,
    WidgetSortUtilService,
    gettextCatalog,
    DashboardContextService,
    GeoConfigurationType,
    DataSourceType,
    ReportStudioTemplateDataService,
    AppModelFactory
) {

    // Expose resolved color palette to be used accross this service
    var tinyColorIndex = 0;

    /**
     * Helper function to reset tiny color index
     */
    function resetTinyColorIndex() {
        tinyColorIndex = 0;
    }

    /**
     * Returns the base color of the chart, based on the chart palette
     * @param palette
     * @returns {*}
     */
    function getBaseColor(palette) {
        return palette[0];
    }

    /**
     * Returns valueWidth based on dashboard font size
     * @param fontSize
     * @returns {number}
     */
    function getValueWidth(fontSize) {
        switch (fontSize) {
            case FontSize.SIZE_SMALL:
                return 50;
            case FontSize.SIZE_MEDIUM:
                return 65;
            case FontSize.SIZE_LARGE:
                return 80;
        }
    }

    /**
     * @returns {string|null}
     * @param chartType
     */
    function getDefaultPlotType(chartType, isLegacy) {
        // metadata.dynamic.hasOwnProperty('raw_data') && !metadata.draw_options.plot_type
        if (isLegacy) {
            switch (chartType) {
                case WidgetType.BARCHART:
                case WidgetType.LINECHART:
                case WidgetType.COMBINATIONCHART:
                    return chartType === WidgetType.LINECHART
                        ? ChartPlotType.LINE
                        : ChartPlotType.CLUSTERED;

                case WidgetType.PIECHART:                
                case WidgetType.FUNNELCHART:
                case WidgetType.GAUGECHART:
                    return ChartPlotType.CLASSIC;

                case WidgetType.GEOCHART:
                    return ChartPlotType.BUBBLE_MAP;

                case WidgetType.DATAGRID:
                case WidgetType.BIGNUMBER:
                    return ChartPlotType.DEFAULT

                default:
                    return null;
            }
        } else {
            switch (chartType) {
                case WidgetType.BARCHART:
                case WidgetType.LINECHART:
                case WidgetType.COMBINATIONCHART:
                    return chartType === WidgetType.LINECHART
                        ? ChartPlotType.LINE_V2
                        : ChartPlotType.CLUSTERED_V2;
    
                case WidgetType.PIECHART:       
                    return ChartPlotType.PIE_CHART_V2;
    
                case WidgetType.FUNNELCHART:
                    return ChartPlotType.FUNNEL;
    
                case WidgetType.GAUGECHART:
                    return ChartPlotType.GAUGE_V2;
    
                case WidgetType.GEOCHART:
                    return ChartPlotType.BUBBLE_MAP_V2;
    
                case WidgetType.DATAGRID:
                case WidgetType.BIGNUMBER:
                    return ChartPlotType.DEFAULT
    
                default:
                    return null;
            }
        }
    }

    /**
     * Returns a color in the palette and will generate one beyond the palette if needed
     * @param index
     * @param chartPalette
     * @returns {string}
     */
    function getPaletteColor(index, chartPalette) {
        chartPalette = chartPalette || this.chartPalette;

        // This sets the amount of color diversity
        const colorDiversity = 10;
        const maxOffset = 100;

        // If index is beyond color palette, generate a color
        if (!chartPalette[index]) {
            const baseIndex = index > maxOffset ? index - 90 : index;

            let chartBaseColor = chartPalette[baseIndex - colorDiversity];
            if (_.isUndefined(chartBaseColor)) {
                chartBaseColor =  chartPalette[Math.floor(Math.random() * 10)];
            }

            // Avoid to generate same color it the color is greyscale
            let offset = index === 0 ? 0 : index / chartPalette.length;
            chartBaseColor = tinycolor(chartBaseColor).lighten(offset).desaturate(offset).toString();

            let newColor = _.uniqBy(tinycolor(chartBaseColor)
                .analogous(colorDiversity * 2, colorDiversity)
                .map(function(t) { return t.toHexString(); }));
            newColor = _.last(newColor);
            chartPalette[index] = newColor;
        }
        return chartPalette[index];
    }

    /**
     * Helper function to convert HEX color to RGBA
     * @param hex
     * @param alpha
     * @returns {string}
     */
    function hexToRGBA(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }

    /**
     * // transparency adjusted hex to support colors in pptx
     * @param hex
     * @param alpha
     * @returns {string}
     */
    function hexToRGBWithTransparency(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        const balanceTransparency = (c) => (c * alpha) + (255 * (1-alpha));
        if (alpha) {
            return "rgb(" + balanceTransparency(r) + ", " + balanceTransparency(g) + ", " + balanceTransparency(b) + ")";
        }
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }

    /**
     * Helper function to tell us if a widget has comparison
     * @returns {boolean}
     * @param options
     */
    function isComparisonEnabled(options) {
        return options.is_overriding_date_range && options.forceComparison
            || !options.is_overriding_date_range && AppFactory.getComparisonDateRange().enabled;
    }

    /**
     * Helper function to update color palette
     * @param dataArr
     * @param options
     * @param selectedColumnsObj
     * @returns {number}
     */
    function updateColorPalette(dataArr, options, selectedColumnsObj) {
        var otherPercentage = options[DrawOption.OTHER_PERCENT];
        // If other percentage is smaller than 0, no need to update color palette
        if (otherPercentage <= 0) {
            return -1;
        }
        var colorIndex = 0;
        var otherPercentageValue = 0;
        var displaySliceIndexArr = [];
        var otherSliceIndexArr = [];
        var maxIndex = 0;
        options.resetTinyColorIndex();

        otherPercentageValue = options.isComparisonChart
            ? options.totalComparisonValue * otherPercentage / 100
            : options.totalValue * otherPercentage / 100;

        _.each(dataArr, function(data, index) {
            if (data.value >= otherPercentageValue) {
                displaySliceIndexArr.push(index);
            }
            else {
                otherSliceIndexArr.push(index);
            }
        });

        _.each(displaySliceIndexArr, function(dataIndex) {
            var data = dataArr[dataIndex];
            var index = selectedColumnsObj ? selectedColumnsObj[data.valueField].index : colorIndex++;
            maxIndex = Math.max(maxIndex, index);
            data.color = options.getPaletteColor(index);
        });

        _.each(otherSliceIndexArr, function(otherSliceIndex) {
            var data = dataArr[otherSliceIndex];
            var index = selectedColumnsObj ? selectedColumnsObj[data.valueField].index : colorIndex++;
            data.color = options.getPaletteColor(index);
        });

        return maxIndex;
    }

    /**
     * Return data for a chart
     *
     * @param widget
     * @param options
     * @param chartDataIndex
     * @returns {{}}
     */
    function getChartData(widget, options, chartDataIndex) {
        let metadata = widget.metadata;
        var queryParams = {};

        if (widget.has_live_integration) {
            queryParams.has_live_integration = widget.has_live_integration;
        }

        var dataColumns = metadata.data_columns;
        var drillDownConfig = options.redrawOptions ? {
            queryParams: options.redrawOptions,
            groupbyIndex: chartDataIndex
        } : null;

        if (options.is_multi_grouped) {
            drillDownConfig = null;
        }

        const getGeoConfigurationType = (groupedValues) => {
            return (groupedValues && groupedValues[0] && groupedValues[0].geo_config)
                ? groupedValues[0].geo_config.type : null;
        }

        if (_.isEmpty(dataColumns.grouped) && (!metadata.geo_code)) {
            queryParams.aggregate = true;
        }

        if (metadata.geo_code) {
            queryParams.include_geo = 1;
            // only for smart connector
            const columnGeoConfigurationType = getGeoConfigurationType(dataColumns.grouped);
            if (metadata.type === WidgetType.GEOCHART && ![DataSourceType.SERVICE_DATA, DataSourceType.CATEGORY_DATA].includes(widget.data_type)) {
                ChartUtilFactory.resetAll();
                if (metadata.map_id && options.map_id) {
                    let id = options.map_id;
                    let areas = _.split(id, '-');
                    let country = _.first(areas).toUpperCase();
                    const state = _.last(areas);
                    if(country === 'US') {
                        ChartUtilFactory.setSelectedCountry(country);
                        if (areas.length > 1) {
                            ChartUtilFactory.setSelectedState(state);
                        }
                    }
                }
                ChartUtilFactory.setMapColumnName(widget);
                if (ChartUtilFactory.canApplyStateFilter()) {
                    queryParams[ChartUtilFactory.getStateColumnName()] = ChartUtilFactory.getSelectedState();
                }
                if (ChartUtilFactory.canApplyCountryFilter()) {
                    queryParams[ChartUtilFactory.getCountryColumnName()] = ChartUtilFactory.getSelectedCountry();
                }
            }
            if (metadata.type === WidgetType.GEOCHART && parseInt(metadata.data_source.id) > 1000000) {
                if (metadata.map_id) {
                    const names = metadata.map_id.split('-');
                    if (names.length === 1) {
                        queryParams.geocode0 = metadata.map_id;
                    } else if (names.length === 2) {
                        queryParams.geocode1 = metadata.map_id;
                    }
                } else if (metadata.map_name) {
                    queryParams.geocode0 = metadata.map_name;
                }
                if (columnGeoConfigurationType === GeoConfigurationType.PIN) {
                    delete queryParams.geocode0;
                    delete queryParams.geocode1;
                }
            } else if(widget.data_type === 'service_data') {
                if (metadata.map_id) {
                    queryParams.geocode0 = metadata.map_id;
                } else if (metadata.map_name) {
                    queryParams.geocode0 = metadata.map_name;
                }
            }
            // In order to make sure we have geo data to display, we must sort by the first selected metric
            queryParams.sort = '-' + _.first(metadata.data_columns.selected).field;
        }

        if ([WidgetType.FUNNELCHART, WidgetType.PIECHART].includes(metadata?.type)) {
            const { data_columns } = metadata;
            if (data_columns?.grouped?.length) {
                queryParams.total = data_columns.selected.some(value => value.defined_by_formula);
            }
        }

        var result = WidgetDataSourceFactory.getData(widget, queryParams, drillDownConfig);

        // WidgetDataSourceFactory may return a promise or not
        if (result.then) {
            result.then((json) => {
                if (widget.metadata?.dynamic?.predefined_data !== null) {
                    if ('currency_discrepancy' in widget.metadata.dynamic.predefined_data) {
                        widget.metadata.currency_discrepancy = widget.metadata.dynamic.predefined_data.currency_discrepancy;
                    }

                    if ('show_currency' in widget.metadata.dynamic.predefined_data) {
                        widget.metadata.show_currency = widget.metadata.dynamic.predefined_data.show_currency;
                    }
                }

                WidgetSortUtilService.updateColumnSorting(metadata);
                if (WidgetFactory.useSampleData(metadata)) {
                    json.data = isComparisonEnabled(options)
                        ? WidgetSortUtilService.applyComparisonSort(json.data, metadata)
                        : WidgetSortUtilService.applySort(json.data, metadata);
                }
                // make sure formatting is only for smart connectors
                if (metadata.type === WidgetType.GEOCHART && parseInt(metadata.data_source.id) > 1000000) {
                    json.data = formatGeocodeData(json.data);
                }
                // sorting is present for few type of charts
                if (WidgetUtilService.isSortableChart(metadata.type)) {
                    let selected = _.filter(dataColumns.selected, { is_metric: true });
                    //Stacked bar chart - Multi Group_by (<=2), single selected metric calls addGrandTotalToJsonData function
                    if (options[DrawOption.PLOT_TYPE] === ChartPlotType.STACKED && dataColumns.grouped.length <= 2 && selected.length === 1) {
                        var grandTotalResult = addGrandTotalToJsonData(json, metadata, dataColumns, selected);
                        if(grandTotalResult){
                            json = grandTotalResult;
                        }
                    }
                    // Apply sorting only if relative range is set to default
                    if(options.relative_date_range === ChartPlotType.DEFAULT){
                        json = sortData(json, metadata, options);
                    }
                }

                return json
            });
        }
        return result;
    }

    /**
     *
     * @param data
     * @param conversionKey
     * @param hasComparison
     * @returns {*}
     */
    function convertStrToInt(data, conversionKey, hasComparison) {
      _.forEach(data, function (value, key) {
        if (hasComparison) {
          _.forEach(value, function (value1, key1) {
            if (value1[conversionKey]) {
                if (!isNaN(value1[conversionKey])) {
                    data[key][key1][conversionKey] = _.toNumber(value1[conversionKey]);
                }else{
                    return false;
                }
            }
          });
        } else {
          if (value[conversionKey]) {
              if (!isNaN(value[conversionKey])) {
                  data[key][conversionKey] = _.toNumber(value[conversionKey]);
              }else{
                  return false;
              }
          }
        }
      });
      return data;
    }

    /**
     Works on
     Stacked BarChart Type
     Multiple Group_by, single selected metric
     adds grand total on multiple group by to perform asc & desc operations
     */
    /**
     *
     * @param json
     * @param metadata
     * @param dataColumns
     * @param selected
     * @returns {boolean}
     */
    function addGrandTotalToJsonData(json, metadata, dataColumns, selected) {
      if (json.data) {
        _.each(selected, function (selectedColumn) {
          if (selectedColumn.sorting && ['integer','currency','decimal','percent'].includes(selectedColumn.format)) {
            var sortByName = metadata.sort_by[0];
            var groupByName = dataColumns.grouped[0].field;
            var hasComparisonData = json.has_comparison_data;
            var barData = json.data;

            barData = convertStrToInt(barData, sortByName, hasComparisonData);
            if (!barData) {
              return false;
            }

            if (!hasComparisonData) {
              _.chain(barData)
                .groupBy(groupByName)
                .map((objs) => {
                  var group_by_grand_total = 0;
                  group_by_grand_total = _.sumBy(objs, sortByName);
                  _.forEach(objs, function (value, key) {
                    value.group_by_grand_total = group_by_grand_total;
                  });
                  return objs;
                })
                .value();
            } else {
              _.chain(barData)
                .groupBy((value) => {
                  return value.current_period[groupByName]
                    ? value.current_period[groupByName]
                    : value.prior_period[groupByName];
                })
                .map((objs) => {
                  var group_by_grand_total = 0;
                  _.forEach(objs, function (value, key) {
                    if (value) {
                      _.forEach(value, function (value1, key1) {
                        if (value1[sortByName])
                          group_by_grand_total += value1[sortByName];
                      });
                    }
                  });
                  _.forEach(objs, function (value, key) {
                    if (value) {
                      _.forEach(value, function (value1, key1) {
                        if (value1[sortByName] || _.isNull(value1[sortByName]) || value1[sortByName] == 0) {
                          value1.group_by_grand_total = group_by_grand_total;
                        }
                      });
                    }
                  });
                  return objs;
                })
                .value();
            }
            metadata.sort_by_temp = metadata.sort_by;
            metadata.sort_by = ["group_by_grand_total"];
            return barData;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    }

    function formatGeocodeData(data) {
        return data.map((row) => {
            if (row.current_period && row.prior_period) {
                formatGeoData(row.current_period);
                formatGeoData(row.prior_period);
            } else {
                formatGeoData(row);
            }
            return row;
        })
    }

    function formatGeoData(row) {
        if (row.geocode2) {
            const [country, state, countyCode] = row.geocode2.split('-');
            row.geocode0 = country;
            row.geocode1 = `${country}-${state}`;
            row.geocode2 = countyCode;
        }
        if (row.geocode1) {
            const [country, state] = row.geocode1.split('-');
            row.geocode0 = country;
            row.geocode1 = `${country}-${state}`;
        }
        if (row.geo_longitude) {
            formatGeoPinData(row, 'geo_longitude');
        }
        if (row.geo_latitude) {
            formatGeoPinData(row, 'geo_latitude');
        }
    }

    // latitude and longitude have -ve to +ve ranges
    function formatGeoPinData(row, propertyName) {
        const parsedData = row[propertyName].toString().split('-');
        row.geocode0 = parsedData[0];
        row.geocode1 = `${parsedData[0]}-${parsedData[1]}`;
        if (parsedData.length === 4) {
            row[propertyName] = `-${parsedData[3]}`;
        }
        if (parsedData.length === 3) {
            row[propertyName] = parsedData[2];
        }
    }

    function toolTipHelper() {

        function setToolTip(options, columns, toolTip, metadata) {
            // Account for double grouping...
            toolTip = toolTip || _.head(options.graphs);

            if (toolTip) {
                toolTip.showBalloon = true;
                toolTip.balloonText = '<text><strong>[[category]][[categoryComparison]]</strong>';
                var selectedColumns = columns.selected || columns;
                var comparisonEnabled = isComparisonEnabled(options);

                // Add each column to the mouseover graph balloon text
                _.each(selectedColumns, function (column, index) {
                    toolTip.balloonText += getDefaultText(options.getPaletteColor(index), column.label, column.field);
                    if (comparisonEnabled) {
                        // Add comparison balloon text to graph balloon text
                        toolTip.balloonText += getDefaultText(options.getPaletteColor(index), column.label + ' ' + gettextCatalog.getString('Comparison'), column.field + 'Comparison', options.comparisonOpacity);
                    }
                });

                // If a serial chart that can drill down, add drill down appropriate text
                if (!options.is_multi_grouped && columns.grouped[options.chartDataIndex + 1]) {
                    var groupedColumn = columns.grouped[options.chartDataIndex];
                    var drillDownColumn = columns.grouped[options.chartDataIndex + 1];
                    toolTip.balloonText += getDrillDownToolTip(groupedColumn, drillDownColumn);
                }

                toolTip.balloonText += '</text>';
                var selectedColumnFields = AppFactory.arrayToMemoizedObj((options.is_multi_grouped ? options.graphs : columns.selected), 'field');

                toolTip.balloonFunction = function (item, graph) {
                    var result = graph.balloonText;
                    result = result.replaceAll("[[category]]", item.dataContext.category);

                    // prior period comparison tooltip
                    if (options.time_grouping && !_.includes(options.time_grouping, 'of') && item.dataContext.log_dateComparison
                        && moment.unix(item.dataContext.log_dateComparison).utc().isValid()) {

                        const momentFormat = WidgetFactory.getDateFormats(item.dataContext.timegroupComparison || options.time_grouping).momentFormat;
                        var priorDate = moment.unix(item.dataContext.log_dateComparison).utc().format(momentFormat);
                        if (priorDate !== item.dataContext.category) {
                            result = result.replaceAll("[[categoryComparison]]",
                                '<br><div style="opacity: ' + options.comparisonOpacity + ';"> ' + gettextCatalog.getString('compared to') + ' '
                                + priorDate
                                + "</div>");
                        }
                    }

                    for (var key in item.dataContext) {
                        var column = comparisonEnabled ? selectedColumnFields[key.replace('Comparison', '')] : selectedColumnFields[key];
                        if (column) {
                            result = replacePlaceholder(result, item, column, key, metadata);
                        }
                    }

                    // Account for graphs with null value...
                    // If current graph item has null value, balloon will not display
                    if (!item.dataContext[graph.valueField]) {
                        graph.showBalloon = false;

                        // find next graph item with a value so that a balloon will display
                        _.each(options.graphs, function(valueGraph) {
                            if (item.dataContext[valueGraph.valueField]) {
                                // Assign result/balloonText to next options.graphs item with data
                                valueGraph.showBalloon = true;
                                valueGraph.balloonText = graph.balloonText;
                                valueGraph.balloonFunction = toolTip.balloonFunction;
                                return false;
                            }
                            else {
                                // TA-13311 Set first metric to be true in order to keep the balloon listener
                                graph.showBalloon = true;
                            }
                        });
                    }
                    else {
                        return result;
                    }
                };
            }
        };

        function getDefaultText(color, label, placeholder, opacity) {

            return '<span class="label" style="background-color:' + (color || '[[color]]') + '; ' +
                'opacity:' + opacity + ';">' +
                label +
                '</span> ' +
                '[[' + placeholder + ']] <br>';
        };

        function replacePlaceholder(result, item, column, key, metadata) {
            // Time columns have todays date added to the front, which does not represent the data.
            // This is needed in order to correctly display the data.
            var formatted = column.format == ColumnFormat.FORMAT_TIME  && item.dataContext[key] ? moment.unix(item.dataContext[key]).utc().format(MomentDateFormat.TIME) : $.fn.formatNumber(item.dataContext[key], column.format, column.precision, false, item.dataContext, metadata.currency_discrepancy, metadata.show_currency);
            return result.replace("[[" + key + "]]", formatted);
        };

        function getDrillDownToolTip(groupedColumn, drillDownColumn) {
            if (groupedColumn) {
                var specifier = groupedColumn.format === ColumnFormat.FORMAT_DATETIME ? ' on ' : ' for ';
                return '<i>Click to see ' + drillDownColumn.label.toLowerCase().pluralize() + specifier + '[[category]]</i>';
            }
            else {
                return null;
            }
        };

        return {
            setToolTip: setToolTip,
            getDefaultText: getDefaultText,
            replacePlaceholder: replacePlaceholder,
            getDrillDownToolTip: getDrillDownToolTip
        }
    };

    function formatLegendValue(value, precision, format) {
        if (format == ColumnFormat.FORMAT_TIME || format == ColumnFormat.FORMAT_DATETIME) {
            return moment.unix(value).isValid() ? moment.unix(value).utc().format(MomentDateFormat.TIME) : moment(value).utc().format(MomentDateFormat.TIME)
        }
        return _.isNumber(value) ? parseFloat(value).reduceNumber(2, precision, format) : '';
    };

    /**
     * Returns default chart options
     * @param chartOptions
     * @param columns
     */
    function getOptions(chartOptions, columns) {

        // Make chart responsive if thumb preview
        var responsive = {enabled: false};
        if (chartOptions.isThumbPreview) {
            responsive = {
                enabled: true,
                overrides: {
                    categoryAxes: {
                        labelsEnabled: false
                    }
                }
            }
        }

        // Default chart options for all charts
        var defaultOptions = {
            addClassNames: true,
            balloon : {
                fixedPosition: false,
                maxWidth: 500, // TA-12226 make more room for tooltip
                borderAlpha: 0,
                fillAlpha: 0,
                color: null,
                textAlign: 'left',
                shadowAlpha: 0,
                disableMouseEvents: true // TA-12296 More consistent tooltip interaction
            },
            borderAlpha: 0,
            borderColor: '#ffffff',
            categoryField: 'category',
            chartCursor: {
                enabled: true,
                categoryBalloonEnabled: false,
                oneBalloonOnly: true,
                fullWidth: true,
                cursorColor: getBaseColor(chartOptions.chartPalette),
                graphBulletSize: 0,
                animationDuration: 0,
                listeners: []
            },
            comparisonOpacity: 0.4,
            fontFamily: DashboardContextService.resolveFontType(),
            fontSize: chartOptions.responsive ? 10 : DashboardContextService.resolveFontSizeValue(),
            handDrawn: false,
            legend: {
                autoMargins: false,
                borderAlpha: 0,
                borderColor: '#ffffff',
                labelWidth: 240,
                marginLeft: 10,
                marginRight: 10,
                spacing: 5,
                useGraphSettings: false,
                valueWidth: getValueWidth(DashboardContextService.resolveFontSize()),
            },
            pathToImages: CoreFactory.getAssetPath('async/amcharts/images/'),
            precision: 1,
            rotate: false,
            responsive: responsive,
            startDuration: 0,
            theme: chartOptions.theme,
            titleField: 'title',
            valueField: 'value',
            usePrefixes: true,
            fontSizeSpacing: DashboardContextService.resolveFontSizeValue() * 4,
        };


        var options = _.extend(chartOptions, defaultOptions);

        //
        // OPTION OVERRIDES
        //

        // Note: value scroller has been disabled for now

        if (options.has_value_scroller && !options.isDrilling && !DesignFactory.getIsExportingPage()) {
            options.chartScrollbar = {
                enabled: true,
                scrollbarHeight: 2,
                backgroundAlpha: 0.1,
                //updateOnReleaseOnly: true,
                selectedBackgroundAlpha: 1
            };

            // Provide spacing for value scroller
            if (options.is_rotated) {
                options.marginRight = 18;
                options.marginLeft = 120;
                options.chartScrollbar.offset = options.fontSizeSpacing;
                options.chartScrollbar.oppositeAxis = true;
            }
            else {
                var right_axis_moved = 0;
                var left_axis_moved = 0;

                /**
                 * Only needed if normalized data option is enabled
                 */
                if (options.is_normalized) {
                    /**
                     * Retrieve an array of all columns moved to the right
                     */
                    right_axis_moved = _.filter(columns.selected, function(o) {
                        return !!o.moving_y_axis;
                    }).length;

                    /**
                     * Calculate number of axis moved to the left
                     */
                    left_axis_moved = columns.selected.length - right_axis_moved;

                    left_axis_moved = Math.max(left_axis_moved, 1);
                    right_axis_moved = Math.max(right_axis_moved, 1);
                }

                options.autoMargins = false;
                options.marginBottom = 18;
                options.marginLeft = options.is_normalized ? (left_axis_moved * options.fontSizeSpacing) + 10 : options.fontSizeSpacing;
                options.marginRight = options.is_normalized ? right_axis_moved * options.fontSizeSpacing : options.fontSizeSpacing;
                options.chartScrollbar.offset = options.fontSizeSpacing;
                options.chartScrollbar.oppositeAxis = false;
            }
        }

        options.handDrawn = options.is_hand_drawn;
        options.legend.enabled = options.has_legend;
        options.rotate = options.is_rotated;
        options.baseColor = getBaseColor(options.chartPalette);
        options.getPaletteColor = getPaletteColor;
        options.resetTinyColorIndex = resetTinyColorIndex;
        options.colorField = options.type == ChartDataType.SERIAL ? null : 'color';
        options.balloon = options.has_tooltip ? options.balloon : {};
        options.getWidgetDateRange = function() {
            var dateRange = AppFactory.getDateRange();
            if (options.is_overriding_date_range) {
                if (options.relative_date_range === RelativeDateRange.CUSTOM) {
                    dateRange.start = moment(options.start_date_override);
                    dateRange.end = moment(options.end_date_override);
                }
                else {
                    dateRange = DateRangeFactory.getDateRangeFromRelativeRange(options.relative_date_range);
                }
                return {start: dateRange.start.unix(), end: dateRange.end.unix()};
            }
            return dateRange;
        };


        //
        // VALUE AXES
        //
        options.valueAxes = (function() {
            var valueAxes = [];

            var defaultOptions = {
                markPeriodChange: false,
                dashLength: 4,
                tickLength: 0,
                axisThickness: 0,
                labelsEnabled: options[DrawOption.SHOW_LABELS],
                gridAlpha: options[DrawOption.HIDE_GRID_LINES] ? 0 : 0.2
            };

            if (options[DrawOption.PLOT_TYPE] === ChartPlotType.FULL_STACKED
                || options[DrawOption.PLOT_TYPE] === ChartPlotType.STACKED
                || options[DrawOption.PLOT_TYPE] === ChartPlotType.DEEP_STACKED
                || options[DrawOption.PLOT_TYPE] === ChartPlotType.DEFAULT) {
                valueAxes = [_.extend(defaultOptions, {
                    labelsEnabled: options.show_labels,
                    stackType: options[DrawOption.PLOT_TYPE]
                })];
            }

            if (!options.is_normalized) {
                // Need to account for displaying date, and numeric
                var axesTypes = [];

                _.each(columns.selected, function(selectedColumn) {
                    var type = selectedColumn.format === ColumnFormat.FORMAT_TIME ? 'date' : 'numeric';

                    if (axesTypes.indexOf(type) < 0) {
                        axesTypes.push(type);
                    }
                });

                if (axesTypes.length > 1) {
                    // set the chart warning label
                    options.helpNeeded = {
                        enabled: true,
                        helpMessage: 'Selected Data Column Formats Don\'t Match',
                        helpAction:
                            options.chartType === WidgetType.GAUGECHART ?
                                ['This widget will not work correctly. There cannot be only one duration metric for gauge widgets. Please change the metrics in order to have ' +
                                'two duration metrics or none.'] :
                                (options[DrawOption.PLOT_TYPE] ?
                                    ['Columns with like formats will be displayed on a separate axis', 'This widget will still work correctly'] :
                                    ['Date columns can not be displayed on current chart',  'This widget will still work correctly'])
                    };
                }
                else {
                    delete options.helpNeeded
                }

                valueAxes = _.map(axesTypes, function(axesType, index) {
                    return _.extend({
                        labelsEnabled: options.show_labels,
                        titleColor: getBaseColor(options.chartPalette),
                        id: axesType,
                        duration: axesType == 'date' ? 'ss' : null,
                        offset: index * options.fontSizeSpacing
                    }, defaultOptions);
                });
            }
            else {
                var extendValues = function(column, index) {
                    return _.extend({
                        labelsEnabled: options.show_labels,
                        titleColor: options.getPaletteColor(index),
                        title: options.show_labels ? column.label : null,
                        id: column.field,
                        // Offset is currently no always correct (if an axis has big numbers vs small numbers)
                        duration: column.format == ColumnFormat.FORMAT_TIME ? 'ss' : null,
                        offset: column.offsetIndex * options.fontSizeSpacing
                    }, defaultOptions);
                };

                setOffsetIndex(columns, options);

                valueAxes =_.map(columns.selected, extendValues);
            }
            return valueAxes;
        })();

        return options;
    };

    /**
     * Links chart events to one another
     * Function is only used with Gauge, and Slice Charts
     * @param chartArray
     */
    var linkCharts = function(chartArray, listenerArray) {
        var suspendActions = false;
        function replicateAction(chart, action, event) {
            if (suspendActions) {
                return;
            }
            suspendActions = true;
            if (!_.isUndefined(chart[action])) {
                // Need to find the correct field to hover over when linking charts....
                // Use chart.chartData instead of char.dataProvider in order to get right index,
                // if there is an 'Other' slice
                chart.mappedDataIndeces = AppFactory.arrayToMemoizedObj(chart.chartData, 'title');
                var chartDataIndex = chart.mappedDataIndeces[event.dataItem.title];
                if (chartDataIndex) {
                    chart[action](chartDataIndex.index);
                }
            }
            suspendActions = false;
        }

        _.each(chartArray, function(chart, index) {
            var chartArrayCopy = _.map(chartArray);
            chartArrayCopy.splice(index, 1);

            _.each(listenerArray, function(listener) {
                // chart.axes is for gauge charts
                if (chart.axes) {
                    _.first(chart.axes).addListener(listener, function(event) {
                        _.each(chartArrayCopy, function(linkedChart) {
                            replicateAction(linkedChart, listener, event);
                        })
                    });
                } else {
                    chart.addListener(listener, function(event) {
                        _.each(chartArrayCopy, function(linkedChart) {
                            replicateAction(linkedChart, listener, event);
                        })
                    });
                }
            });
        });
    };

    /**
     *
     * @param options
     * @param columns
     */
    var makeChart = function (options, columns) {
        // All amCharts5 charts are implemented in VueJs. We don't need to create
        // these charts here, so we are ignoring createChart for these.
        if (WidgetUtilService.isAm5Chart(options.chartType, options.plot_type)) {
            return;
        }

        var chart = {};

        /**
         *
         * Function to parse and format data to comply with non-specialized dataProvider structure
         * @param json
         * @returns {Array}
         */
        var provideData = function(json) {
            // Slice Chart drill down:
            //    Consist of two clicks:
            //      1. First click (event is pullOutSlice for slicecharts) will be similar to this: https://www.amcharts.com/demos/pie-chart-broken-slices/
            //      2. Second click (pullInSlice for slicecharts) will render new chart with the selected drill down
            var hasComparisonData = json.has_comparison_data;
            var chartData = json.data;
            var dataArr = [];
            options.totalValue = 0;
            options.totalComparisonValue = 0;
            options.isAutoDrill =  columns.selected.length == 1 && columns.grouped.length > 0;
            options.allEmptyData = true;
            var createDataPoint = function(chartData, isComparison, simpleChartData) {

                return _.reduce(chartData, function(result, valueObj, index) {
                    var value = valueObj;
                    if (hasComparisonData) {
                        value = isComparison ? valueObj.prior_period : valueObj.current_period;
                    }
                    if (_.isEmpty(value)) {
                        return result;
                    }

                    // Original color is needed for slices when displaying comparison charts
                    if (options.mappedDataIndeces) {
                        var currentSlice = columns.grouped.length ? options.mappedDataIndeces[value[columns.grouped[options.chartDataIndex].groupby_name_field]] : null;
                        value.color = currentSlice ? currentSlice.color : options.getPaletteColor(chartData.length + index);
                    }
                    else {
                        value.color = options.getPaletteColor(index);
                    }
                    result.subset.push(value);
                    if (value[result.valueField]) {
                        if (options.is_normalized) {

                            const excludedChartTypes = [WidgetType.FUNNELCHART, WidgetType.PIECHART];
                            if (!(excludedChartTypes.includes(options.chartType) && _.toNumber(value[result.valueField]) === 0)) {
                                result.value = 1;
                            }

                            if (isComparison) {
                                options.totalComparisonValue += result.value;
                            } else {
                                options.totalValue += result.value;
                            }

                            if (result.format === ColumnFormat.FORMAT_TIME) {
                                result.rawValue += AppModelFactory.getDurationToSeconds(value[result.valueField]);
                            } else {
                                result.rawValue += +value[result.valueField];
                            }
                        }
                        else {
                            if (result.format === ColumnFormat.FORMAT_TIME) {
                                result.value += moment.duration(value[result.valueField]).asSeconds();
                                if (isComparison) {
                                    options.totalComparisonValue += moment.duration(value[result.valueField]).asSeconds();
                                } else {
                                    options.totalValue += moment.duration(value[result.valueField]).asSeconds();
                                }
                            }
                            else {
                                result.value += +value[result.valueField];
                                if (isComparison) {
                                    options.totalComparisonValue += +value[result.valueField];
                                } else {
                                    options.totalValue += +value[result.valueField];
                                }
                            }
                        }

                        if (options.allEmptyData && result.value) {
                            options.allEmptyData = false;
                        }
                    }

                    return result;
                }, simpleChartData);
            };

            if (hasComparisonData) {
                options.comparisonData = {
                    dataProvider: [],
                };
            }
            // Iterate through each of the remaining data (and add/account for percentages?)

            // If one selected column/one slice displayed, and grouping by, then we can drill into the chart
            if (columns.selected.length == 1 && columns.grouped.length) {
                if (DesignFactory.getIsExportingPage()) {
                    ExportFactory.widgetReadyCounter.incRef();
                    ExportFactory.chartReadyCounter.incRef();

                    if (!_.isEmpty(options.comparisonData)) {
                        ExportFactory.widgetReadyCounter.incRef();
                        ExportFactory.chartReadyCounter.incRef();
                    }
                }
                options.listeners['drawn'] = function(event) {

                    var currentChart = event.chart;
                    if (event.chart.dataProvider.length == 1) {
                        event.chart.clickSlice(0);
                    }
                    if (DesignFactory.getIsExportingPage()) {
                        if (!currentChart.hasDrillDown) {
                            ExportFactory.widgetReadyCounter.decRef();
                            ExportFactory.chartReadyCounter.decRef();
                            currentChart.hasDrillDown = true;
                        }
                    }
                };
            }

            _.each(columns.selected, function(column, index) {
                // Todo: Need to account for column.format
                var dataPoint = createDataPoint(chartData, false, {
                    title: column.label,
                    value: 0,
                    rawValue: null,
                    format: column.format,
                    precision: column.precision,
                    color: options.getPaletteColor(index),
                    valueField: column.field,
                    pulled: false,
                    id: index,
                    subset: [],
                    dataContext: chartData
                });

                dataArr.push(dataPoint);
                if (hasComparisonData && !options.isThumbPreview) {
                    options.titles = [{
                        text: gettextCatalog.getString('Current Period')
                    }];
                    options.mappedDataIndeces = columns.grouped.length ? AppFactory.arrayToMemoizedObj(dataPoint.dataContext, columns.grouped[options.chartDataIndex].groupby_name_field) : {};
                    options.comparisonData.dataProvider.push(createDataPoint(chartData, true, {
                        title: column.label,
                        value: 0,
                        rawValue: null,
                        format: column.format,
                        precision: column.precision,
                        color: options.getPaletteColor(index),
                        valueField: column.field,
                        pulled: false,
                        id: index,
                        subset: [],
                        dataContext: chartData
                    }));
                }
            });

            if (WidgetUtilService.isSliceChart(options.chartType) && !options.isAutoDrill) {
                var indexCount = updateColorPalette(dataArr, options, AppFactory.arrayToMemoizedObj(columns.selected, 'field'));
                options.groupedColor = options.getPaletteColor(indexCount + 1);
            }

            return dataArr;
        };

        if (!_.isEmpty(options.clickType)) {
            options.listeners[options.clickType] = function(e) {
                var item =  e.item ? 'item' : 'dataItem';
                // Send the event (e.chart),  graph (e.item), and the item that was clicked.
                options.drillDown(e.chart, e[item], (e.graph ? e.graph.data[e.index] : e.dataItem.dataContext));
            };
        }


        var createChart = function() {
            // All amCharts5 charts are implemented in VueJs. We don't need to create
            // these charts here, so we are ignoring createChart for these.
            if (WidgetUtilService.isAm5Chart(options.chartType, options.plot_type)) {
                return;
            }

            $timeout(function() {
                var isExporting = DesignFactory.getIsExportingPage();
                let isTimeGrouped = columns.grouped.length && columns.grouped[options.chartDataIndex] && columns.grouped[options.chartDataIndex].is_primary_date_field;


                var comparisonLabelHelper = function(valueText, item, categoryAxis) {
                    if (isTimeGrouped && options.time_grouping && !_.includes(options.time_grouping, 'of') && !categoryAxis.parseDates && item.dataContext.log_dateComparison) {
                        const momentFormat = WidgetFactory.getDateFormats(item.dataContext.timegroupComparison || options.time_grouping).momentFormat;
                        let prevDate = moment.unix(item.dataContext.log_dateComparison).utc().format(momentFormat);
                        if (options.date_format_type === DateFormatType.NO_YEAR) {
                            prevDate = WidgetFactory.formatDate(prevDate, options.date_format_type, item.dataContext.timegroupComparison || options.time_grouping);
                        }


                        if (!item.dataContext.log_date) {
                            valueText = '';
                        } else {
                            valueText += '<br>';
                        }

                        if (!_.isEmpty(prevDate)) {
                            valueText += '(' + prevDate + ')';
                        }
                    }
                    return valueText;
                };

                if (!_.isUndefined(options.categoryAxis)) {

                    var hasLargeData = options.dataProvider.length > 10;
                    var categoryAxis = options.categoryAxis;
                    var labelLengthLimit = options.is_rotated ? 40 : 15;
                    var substringLimit = options.is_rotated ? 35 : 10;

                    categoryAxis.labelsEnabled = options[DrawOption.SHOW_LABELS];

                    // If large amounts of data showing, we need to modify the graph to support the space taken
                    if (hasLargeData) {
                        categoryAxis.labelRotation = 45;
                        if (options.has_value_scroller && !options.isDrilling && !DesignFactory.getIsExportingPage()) {
                            categoryAxis.ignoreAxisWidth = true;
                            options.chartScrollbar.offset = 70;
                        }
                    } else {
                        categoryAxis.labelRotation = 0;
                    }

                    if (options.is_rotated && categoryAxis.labelsEnabled) {
                        _.each(options.dataProvider, function(data) {
                            if(data['category'].length > 15){
                                options.marginLeft = ReportStudioTemplateDataService.getIsActive() ? 240 : 200;
                                categoryAxis.ignoreAxisWidth = true;
                                switch (DashboardContextService.resolveFontSize()) {
                                    case FontSize.SIZE_MEDIUM:
                                        categoryAxis.minVerticalGap = 55;
                                        break;
                                    case FontSize.SIZE_LARGE:
                                        labelLengthLimit = 30;
                                        substringLimit = 25;
                                        categoryAxis.minVerticalGap = 75;
                                        break;
                                    default:
                                        categoryAxis.minVerticalGap = 45;
                                }
                                return false;
                            }
                        });
                    }

                    categoryAxis.labelFunction = function (valueText, item, categoryAxis) {
                        if (!_.isUndefined(valueText)
                            && valueText.length > labelLengthLimit
                            && (!isTimeGrouped || !options.time_grouping)) {
                            // Check for anchor tag
                            const anchorMatch = valueText.match(/<a[^>]+href="([^"]+)"[^>]*>(.*?)<\/a>/i);
                            if (anchorMatch) {
                                return AppFactory.getDomainNameFromUrl(anchorMatch);
                            }
                            return valueText.substring(0, substringLimit) + '...';
                        } else {
                            return comparisonLabelHelper(valueText, item, categoryAxis);
                        }
                    };

                }

                //
                // Empty chart data handler
                //
                AmCharts.addInitHandler(function(chart) {
                    // Check if data is empty
                    if (chart.allEmptyData) {
                        chart.alpha = 0.05;
                    }
                    if (chart.hasError) {
                        chart.addLabel('50%', '50%', 'Data is invalid', 'middle', 14);
                        chart.alpha = 0.05;
                    }
                });

                if (isExporting) {
                    ExportFactory.chartReadyCounter.incRef();
                    var oldDrawn = options.listeners['drawn'];
                    options.hasDrawn = false;
                    var timeout;

                    options.listeners['drawn'] = function(e) {
                        var currentChart = e.chart;
                        // Still need to execute old 'drawn' logic.
                        if (oldDrawn) {
                            oldDrawn(e);
                        }

                        // This will fix the weird issue that amCharts legends get overlapped in exported PDF
                        // The reason is QtWebkit (the engine that wkhtml runs on) doesn't render it correctly,
                        // therefore we need to find duplicate legends and remove them.

                        if (currentChart.isComparisonChart) {
                            var amchartsLegendGraph = angular.element('#' + currentChart.chartId).find('.amcharts-legend-div > svg > g');
                            // Remove duplicate legend here.
                            if (amchartsLegendGraph.length > 1) {
                                amchartsLegendGraph.first().remove();
                            }
                        }
                        if (!currentChart.hasDrawn) {
                            currentChart.hasDrawn = true;
                            timeout = $timeout(function() {
                                ExportFactory.chartReadyCounter.decRef();
                            }, 5000);
                        } else {
                            $timeout.cancel(timeout);
                            ExportFactory.chartReadyCounter.decRef();
                        }
                    };
                }

                // Map listeners to array.
                // Listeners is created as an object so it's easier to keep track of listeners
                options.listeners = _.map(options.listeners, function(func, name) {
                    return {
                        event: name,
                        method: func
                    };
                });
                chart = AmCharts.makeChart(options.chartId, options);

                options.renderCallback(chart);

                if (options.type === ChartDataType.SERIAL && !_.isUndefined(options.categoryAxis) && _.isFunction(options.categoryAxis.addListener)) {
                    options.categoryAxis.addListener("rollOverItem", function (event) {
                        const chartBalloon = event.chart.balloon;

                        chartBalloon.followCursor(true);
                        if (event.serialDataItem.category.length > 30) {
                            chartBalloon.showBalloon('<b>'+event.serialDataItem.category+'</b>');
                        }
                    });

                    options.categoryAxis.addListener("rollOutItem", function (event) {
                        event.chart.balloon.hide();
                    });
                }

                if (!_.isEmpty(options.comparisonData)) {
                    var comparisonChartOptions = angular.copy(options, {});
                    comparisonChartOptions = _.extend(comparisonChartOptions, comparisonChartOptions.comparisonData);
                    comparisonChartOptions.chartId = comparisonChartOptions.chartId + '-comparison';
                    comparisonChartOptions.title = 'Comparison Chart';
                    comparisonChartOptions.alpha = options.comparisonOpacity;
                    comparisonChartOptions.isComparisonChart = true;
                    comparisonChartOptions.titles = [{
                        text: gettextCatalog.getString('Prior Period')
                    }];
                    comparisonChartOptions.marginTop = 20;

                    if (isExporting) {
                        comparisonChartOptions.hasDrawn = false;
                    }

                    if (WidgetUtilService.isSliceChart(options.chartType) && !options.isAutoDrill) {
                        var indexCount = updateColorPalette(comparisonChartOptions.comparisonData.dataProvider, comparisonChartOptions, AppFactory.arrayToMemoizedObj(columns.selected, 'field'));
                        comparisonChartOptions.groupedColor = comparisonChartOptions.getPaletteColor(indexCount + 1);
                    }

                    var comparisonChart = AmCharts.makeChart(comparisonChartOptions.chartId, comparisonChartOptions);

                    linkCharts([comparisonChart, chart], chart.linkedListeners);

                }
            });
        };

        // Note: $q is used (instead of promise.resolve) for IE support.
        $q.resolve(options.dataPromise).then(function(json) {
            // If evaluateLoadingState (which happens before this callback in widget.chart.ctrls)
            // does not pass, it returns null
            if (_.isNull(json)) {
                options.addChartListeners({}, options);
                return;
            }
            else {
                if (json && !_.isEmpty(json.data)) {
                    // Note: Any chart with a provideData function must take in a callback in order to build chart.
                    // Since maps are loaded asynchronously, build chart function is passed in as a callback to
                    // widgets with a custom provideData function (currently gauge chart, and geo chart).
                    // This way, a geochart will not be built until the corresponding map has been loaded.
                    if (options.provideData) {
                        // Note: Charts with provideData must have a truthy value for 'dataprovider' to update loading state correctly.
                        options.provideData(json, createChart);
                    }
                    else {
                        options.dataProvider = provideData(json);
                        createChart();
                    }
                }
                else {
                    options.renderCallback(chart);
                }
            }
        });
    };

    /**
     * Apply sorting order if necessary
     */
    function setOrder(metadata, column, isMultiSorting) {
        WidgetSortUtilService.updateColumnSort(metadata, column, isMultiSorting);
    }

    /**
     * Helper function to set offset index in order to calculate y-axis offset correclty
     * @param columns
     * @param options
     */
    function setOffsetIndex(columns, options) {
        var selected = columns.selected;
        var moveYAxesObj = options.move_y_axes || {};
        var movingIndex = 0, nonMovingIndex = 0;
        _.each(selected, function(column) {
            if (ChartAxisService.moveAllYAxes(options) && moveYAxesObj[column.field]) {
                column.offsetIndex = movingIndex++;
            } else {
                column.offsetIndex = nonMovingIndex++;
            }
        });
    }

    /**
     * Set default value for line_columns in combo chart
     * @param metadata
     * @param isCreating
     */
    function setDefaultLineColumns(metadata, isCreating) {
        var selected = _.filter(metadata.data_columns.selected, {is_metric: true});
        // By default, if new widget, always alternate y axis columns
        if (isCreating && _.isNil(metadata.line_columns)) {
            metadata.line_columns = {};
            _.each(selected, function(selectedColumn, index) {
                if (index % 2 === 1) {
                    metadata.line_columns[selectedColumn.field] = 1;
                }
            });
        }
    }

    /**
     * Helper function to sort data in chart widgets
     *
     * @param json
     * @param metadata
     * @returns json
     */
    function sortData(json, metadata, options) {
        _.forEach(metadata.sort_by, function(sortBy) {
            /**
             * isNaN returns false for empty strings too, due to this
             * it was failing when there is empty string for grouped columns (TA-39855)
             */
            if(json && json.data && json.data[0] && !isNaN(json.data[0][sortBy]) && json.data[0][sortBy] !== '') {
                _.forEach(json.data, function(value, key) {
                    if (!isNaN(value[sortBy])) {
                      json.data[key][sortBy] = _.toNumber(value[sortBy]);
                    }
                });
            }
        });

        if (metadata.time_grouping !== TimeGrouping.GROUPING_DAY_OF_WEEK && (json.data && json.data[0]) &&
            !(metadata.time_grouping === TimeGrouping.GROUPING_HOUR_OF_DAY && metadata.sort_by[0] === 'log_date')
        ) {
                json.data = isComparisonEnabled(options)
                    ? WidgetSortUtilService.applyComparisonSort(json.data, metadata)
                    : WidgetSortUtilService.applySort(json.data, metadata);

                if(metadata.sort_by && metadata.sort_by[0] === 'group_by_grand_total'){
                    metadata.sort_by = metadata.sort_by_temp;
                    metadata.sort_by_temp = [];
                }

        }
        return json;
    }
    function getBackButtonConfig(chartId, widgetType) {
        return [{
            text: '< Back',
            id: 'back',
            size: 12,
            bold: true,
            x: 20,
            y: 15,
            url: "javascript:amplify.publish('drillBack-" + chartId + '-' + widgetType + "');void(0);"
        }];
    }
    return {
        setOrder: setOrder,
        getChartData: getChartData,
        getPaletteColor: getPaletteColor,
        getDefaultPlotType: getDefaultPlotType,
        hexToRGBA: hexToRGBA,
        hexToRGBWithTransparency: hexToRGBWithTransparency,
        updateColorPalette: updateColorPalette,
        toolTipHelper: toolTipHelper,
        formatLegendValue: formatLegendValue,
        getOptions: getOptions,
        isComparisonEnabled: isComparisonEnabled,
        makeChart: makeChart,
        setDefaultLineColumns: setDefaultLineColumns,
        getBackButtonConfig: getBackButtonConfig
    }
}

/**
 * @param AppFactory
 * @returns {{resetSelectedCountry: resetSelectedCountry, getSelectedState: (function(): null), getSelectedCountry: (function()), isCountrySelected: (function()), setSelectedState: setSelectedState, isStateSelected: (function()), setMapColumnName: setMapColumnName, hasPermission: (function(): boolean|*), canApplyStateFilter: (function()), getCountryColumnName: (function(): null), getStateColumnName: (function(): null), setSelectedCountry: setSelectedCountry, resetSelectedState: resetSelectedState, canApplyCountryFilter: (function())}}
 * @constructor
 */
function ChartUtilFactory(AppFactory) {
    /**
     * @type {{US: string}}
     */
    var countryMapper = {
        'US': 'USA'
    };

    // selected state is used for filtering geo map api response
    var selectedState = null;

    // state column for filtering geo map api response
    var stateColumn = null;

    // country column for filtering geo map api response
    var countryColumn = null;

    // selected country is used for filtering geo map api response
    var selectedCountry = null;

    /**
     * reset selected state when home icon or geo widget is initialized
     */
    function resetSelectedState() {
        selectedState = null;
    }

    /**
     * reset state and country columns
     */
    function resetColumns() {
        stateColumn = null;
        countryColumn = null;
    }

    /**
     * reset selected country when home icon or geo widget is initialized
     */
    function resetSelectedCountry() {
        selectedCountry = null;
    }

    /**
     * set selected state when US is clicked
     * @param state
     */
    function setSelectedState(state = null) {
        selectedState = state ;
    }

    /**
     * set selected country when US is clicked
     * @param country
     */
    function setSelectedCountry(country = null) {
        selectedCountry = country;
    }

    /**
     * @returns {*}
     */
    function getSelectedCountry() {
        return countryMapper[selectedCountry] || selectedCountry;
    }

    /**
     * @returns {*}
     */
    function getSelectedState() {
        return selectedState;
    }

    /**
     * @returns {boolean}
     */
    function isStateSelected() {
        return !_.isNull(selectedState);
    }

    /**
     * @returns {boolean}
     */
    function isCountrySelected() {
        return !_.isNull(selectedCountry);
    }

    /**
     * @returns {boolean}
     */
    function canApplyStateFilter() {
        return isStateSelected() && !_.isNull(stateColumn);
    }

    /**
     * @returns {boolean}
     */
    function canApplyCountryFilter() {
        return isCountrySelected() && !_.isNull(countryColumn);
    }

    /**
     * @param widget
     */
    function setMapColumnName(widget = {}) {
        const geoConfig = _.get(widget, 'metadata.data_source.geo_columns');
        if(_.has(geoConfig, 'state')) {
            stateColumn = geoConfig.state;
        }
        if(_.has(geoConfig, 'country')) {
            countryColumn = geoConfig.country;
        }
    }

    /**
     * @returns {string}
     */
    function getStateColumnName() {
        return stateColumn;
    }

    /**
     * @returns {string}
     */
    function getCountryColumnName() {
        return countryColumn;
    }

    /**
     * reset all
     */
    function resetAll() {
        resetColumns();
        resetSelectedCountry();
        resetSelectedState();
    }

    return {
        setMapColumnName: setMapColumnName,
        getStateColumnName: getStateColumnName,
        setSelectedState: setSelectedState,
        getSelectedState: getSelectedState,
        resetSelectedState: resetSelectedState,
        isStateSelected: isStateSelected,
        getCountryColumnName: getCountryColumnName,
        setSelectedCountry: setSelectedCountry,
        getSelectedCountry: getSelectedCountry,
        resetSelectedCountry: resetSelectedCountry,
        canApplyCountryFilter: canApplyCountryFilter,
        canApplyStateFilter: canApplyStateFilter,
        isCountrySelected: isCountrySelected,
        resetAll: resetAll
    }
}

/**
 * @ngInject
 */
function ChartAxisService(
    DrawOption,
    WidgetCreateFactory,
    WidgetBuilderUIService,
    WidgetType
) {
    this.setYAxisPosition = setYAxisPosition;
    this.removeYAxisPosition = removeYAxisPosition;
    this.setYAxisPositionForAllColumns = setYAxisPositionForAllColumns;
    this.moveSingleYAxis = moveSingleYAxis;
    this.moveAllYAxes = moveAllYAxes;
    this.displayYAxisButton = displayYAxisButton;
    this.getYAxisPosition = getYAxisPosition;

    /**
     * Set y-axis position for single column
     * @param metadata
     * @param column
     */
    function setYAxisPosition(metadata, column) {
        var moveYAxesObj = metadata.move_y_axes;
        if (!_.isNil(moveYAxesObj)) {
            if (column.moving_y_axis) {
                moveYAxesObj[column.field] = true;
            } else {
                delete moveYAxesObj[column.field];
            }
        }
    }

    /**
     * Remove y-axis position for single column
     * @param metadata
     * @param column
     */
    function removeYAxisPosition(metadata, column) {
        var moveYAxesObj = metadata.move_y_axes;
        if (_.isEmpty(moveYAxesObj)) {
            return;
        }
        if (column.moving_y_axis) {
            delete column.moving_y_axis;
            delete moveYAxesObj[column.field];
        }
    }

    /**
     * Set y-axis position for all columns
     * @param metadata
     */
    function setYAxisPositionForAllColumns(metadata) {
        var drawOptions = metadata.draw_options;
        if (!moveAllYAxes(drawOptions)) {
            return;
        }
        var selected = _.filter(metadata.data_columns.selected, {is_metric: true});
        // By default, if new widget, always alternate y axis columns
        if (WidgetBuilderUIService.isActive() && _.isNil(metadata.move_y_axes)) {
            metadata.move_y_axes = {};
            _.each(selected, function(selectedColumn, index) {
                if (index % 2 === 1) {
                    metadata.move_y_axes[selectedColumn.field] = true;
                }
            });
        }

        _.each(selected, function(selectedColumn) {
            if (metadata.move_y_axes[selectedColumn.field]) {
                selectedColumn.moving_y_axis = true;
            } else {
                delete selectedColumn.moving_y_axis;
            }
        });
    }

    /**
     * Tell us if we should move single y-axis
     * @param options
     * @returns {*|boolean}
     */
    function moveSingleYAxis(options) {
        return options[DrawOption.IS_Y_AXIS_MOVED] && !options[DrawOption.IS_NORMALIZED];
    }

    /**
     * Tell us if we should move all y-axes
     * @param options
     * @returns {*}
     */
    function moveAllYAxes(options) {
        return options[DrawOption.IS_NORMALIZED];
    }

    /**
     * Tell us if we should display y-axis button
     * @param options
     * @returns {*}
     */
    function displayYAxisButton(column, groupedColumns, chartType, drawOptions) {
        return (chartType === WidgetType.BARCHART
            || chartType === WidgetType.LINECHART
            || chartType === WidgetType.COMBINATIONCHART)
            && moveAllYAxes(drawOptions)
            && !_.filter(groupedColumns, {label: column.label}).length;
    }

    /**
     * Helper function to get y axis position
     * @param options
     * @param moveYAxis
     * @returns {string}
     */
    function getYAxisPosition(options, moveYAxis) {
        if (moveYAxis) {
            return options[DrawOption.IS_ROTATED] ? 'top' : 'right';
        }
        return options[DrawOption.IS_ROTATED] ? 'bottom' : 'left';
    }
}

