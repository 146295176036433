import { minBy, maxBy } from 'lodash';
import { LineSeries } from '@amcharts/amcharts5/xy';
import {
  Bullet,
  Circle,
  DataProcessor,
  Template,
  Container,
  p50,
  Label,
  NumberFormatter,
  Star,
  percent,
  Triangle,
} from '@amcharts/amcharts5';
import { color, getShadowSettings, invertColor } from '@/modules/core/charts/am5/charts.helper';
import {
  BackgroundGradientType,
  BubbleShapes,
  ValueFormatters,
  Constant,
  OversizedBehaviors,
  WidgetEvents,
} from '@/modules/core/charts/am5/charts.constants';
import { useGradient } from '@/modules/core/charts/am5/base/composables/fills/useGradient';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';

export function useBubbles(context) {
  const { root, config, chart } = context();
  const { createLinearGradient, createRadialGradient } = useGradient(context);
  const SHAPE_LABEL_ZOOM_PERCENT = {
    [BubbleShapes.CIRCLE]: 0.95,
    [BubbleShapes.STAR]: 0.8,
    [BubbleShapes.DIAMOND]: 0.9,
    [BubbleShapes.TRIANGLE]: 0.6,
  };

  function createBubbles() {
    if (config.value?.data?.length) {
      const [minBubbleSizeValue, maxBubbleSizeValue] = calculateMinAndMixBubbleSize();
      config.value.series?.forEach((seriesProps) => {
        const series = createBubbleSeries(seriesProps);
        drawBubbleCircles(series, seriesProps, minBubbleSizeValue, maxBubbleSizeValue);
        series.data.setAll(config.value.data);
      });
    }
  }

  function calculateMinAndMixBubbleSize() {
    const bubbleFields = config.value.series?.map((bubble) => bubble.value) || [];
    const minValuesOfBubbleSeries = bubbleFields.map(
      (field) => minBy(config.value.data, field)[field]
    );
    const maxValuesOfBubbleSeries = bubbleFields.map(
      (field) => maxBy(config.value.data, field)[field]
    );
    return [Math.min(...minValuesOfBubbleSeries), Math.max(...maxValuesOfBubbleSeries)];
  }

  function createBubbleSeries(seriesProps) {
    const series = chart.value.series.push(
      LineSeries.new(root.value, {
        calculateAggregates: true,
        xAxis: chart.value.xAxes.getIndex(0),
        yAxis: chart.value.yAxes.getIndex(0),
        valueXField: seriesProps.valueX,
        valueYField: seriesProps.valueY,
        valueField: seriesProps.value,
        fill: color(seriesProps.fill),
        connect: false,
        name: seriesProps.name,
        legendLabelText: seriesProps.name,
      })
    );
    series.set(Constant.USER_DATA, seriesProps);
    series.data.processor = DataProcessor.new(root.value, {
      numericFields: [seriesProps.value, seriesProps.valueX, seriesProps.valueY],
    });
    series.strokes.template.set(Constant.VISIBLE, false);
    return series;
  }

  function drawBubbleCircles(series, seriesProps, minBubbleSizeValue, maxBubbleSizeValue) {
    const shapeTemplate = Template.new({
      fill: series.get(Constant.FILL),
    });
    const { gradientColor } = config.value;

    switch (config.value.backgroundGradient) {
      case BackgroundGradientType.LINEAR_GRADIENT:
      case BackgroundGradientType.RADIAL_GRADIENT:
        shapeTemplate.set(
          Constant.FILL_GRADIENT,
          config.value.backgroundGradient === BackgroundGradientType.LINEAR_GRADIENT
            ? createLinearGradient([series.get(Constant.FILL), gradientColor], 90)
            : createRadialGradient([gradientColor, series.get(Constant.FILL)])
        );
        break;

      default:
        break;
    }

    let shadowOptions = {};
    if (seriesProps.showShapeShadow) {
      shadowOptions = getShadowSettings(config.value.isExporting);
    }

    series.bullets.push((_root, _lineSeries, dataItem) => {
      const data = dataItem?.dataContext;
      if (!data) {
        return;
      }

      const container = Container.new(root.value, {
        centerX: p50,
        centerY: p50,
        width: 0,
        height: 0,
      });

      let shape;
      switch (seriesProps.shape) {
        case BubbleShapes.STAR:
        case BubbleShapes.DIAMOND:
          shape = Star.new(
            root.value,
            {
              fillOpacity: seriesProps.fillOpacity,
              innerRadius: seriesProps.shape === BubbleShapes.STAR ? percent(55) : percent(70),
              spikes: seriesProps.shape === BubbleShapes.STAR ? 5 : 4,
              ...shadowOptions,
            },
            shapeTemplate
          );
          break;
        case BubbleShapes.TRIANGLE:
          shape = Triangle.new(
            root.value,
            {
              fillOpacity: seriesProps.fillOpacity,
              height: 16,
              width: 16,
              ...shadowOptions,
            },
            shapeTemplate
          );
          break;
        default:
          shape = Circle.new(
            root.value,
            {
              fillOpacity: seriesProps.fillOpacity,
              ...shadowOptions,
            },
            shapeTemplate
          );
          break;
      }

      container.children.push(shape);

      if (seriesProps.showValuesOnShapes && data[seriesProps.value]) {
        const label = Label.new(root.value, {
          centerX: p50,
          centerY: p50,
          parent: shape,
          text: NumberFormatter.new(root.value, {
            ...ValueFormatters[ColumnFormat.FORMAT_INTEGER],
          }).format(data[seriesProps.value]),
          fill: invertColor(series.get(Constant.FILL)),
          oversizedBehavior: OversizedBehaviors.FIT,
          fontSize: 100,
          width: percent(100),
        });

        shape.events.on(WidgetEvents.BOUNDSCHANGED, () => {
          label.set(
            Constant.MAX_WIDTH,
            shape.width() * SHAPE_LABEL_ZOOM_PERCENT[seriesProps.shape]
          );
        });

        container.children.push(label);
      }

      return Bullet.new(root.value, {
        sprite: container,
      });
    });

    const bubbleZoomFactor = (seriesProps.shapeSize / 100) * 2;

    const heatRules = [];

    switch (seriesProps.shape) {
      case BubbleShapes.CIRCLE:
      case BubbleShapes.STAR:
      case BubbleShapes.DIAMOND:
        heatRules.push({
          target: shapeTemplate,
          min: 20 * bubbleZoomFactor,
          max: 60 * bubbleZoomFactor,
          minValue: minBubbleSizeValue,
          maxValue: maxBubbleSizeValue,
          dataField: Constant.VALUE,
          key: Constant.RADIUS,
        });
        break;
      case BubbleShapes.TRIANGLE:
        heatRules.push({
          target: shapeTemplate,
          min: 40 * bubbleZoomFactor,
          max: 120 * bubbleZoomFactor,
          minValue: minBubbleSizeValue,
          maxValue: maxBubbleSizeValue,
          dataField: Constant.VALUE,
          key: Constant.WIDTH,
        });
        heatRules.push({
          target: shapeTemplate,
          min: 40 * bubbleZoomFactor,
          max: 120 * bubbleZoomFactor,
          minValue: minBubbleSizeValue,
          maxValue: maxBubbleSizeValue,
          dataField: Constant.VALUE,
          key: Constant.HEIGHT,
        });
        break;
      default:
        break;
    }

    series.set(Constant.HEAT_RULES, heatRules);
  }

  return {
    createBubbles,
  };
}
