import VueXHelper from '@/modules/core/app/helpers/VueXHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { getters } from './ui.getters';
import { mutations } from './ui.mutations';
import { actions } from './ui.actions';
import { UIState } from './ui.state';

VueXHelper.setStoreModule(Module.UI, { actions, mutations, getters });

export const store = {
  namespaced: true,
  state: new UIState(),
  getters,
  mutations,
  actions,
};
