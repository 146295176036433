import { Label, p50, color } from '@amcharts/amcharts5';
import { useValueAxis } from '@/modules/core/charts/am5/base/composables/axis/useValueAxis';
import { AxisRendererX, AxisRendererY } from '@amcharts/amcharts5/xy';
import { useXYAxesTooltip } from '@/modules/core/charts/am5/base/composables/axis/useXYAxesTooltip';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { useToolTips } from '@/modules/core/charts/am5/pie/composables/useTooltips';

export function useBubbleAxes(context) {
  const { chart, config, root, isDarkTheme } = context();
  const { createValueAxis } = useValueAxis(context);
  const { createAxisTooltip } = useXYAxesTooltip(context);
  const { assignThemeColor } = useToolTips(context);

  function createBubbleAxes() {
    const xAxisProps = config.value.xAxis;
    const yAxisProps = config.value.yAxis;

    const xAxis = chart.value.xAxes.push(
      createValueAxis(xAxisProps, AxisRendererX.new(root.value, {}), true)
    );
    xAxis.set(Constant.USER_DATA, xAxisProps);

    const yAxis = chart.value.yAxes.push(
      createValueAxis(yAxisProps, AxisRendererY.new(root.value, {}), true)
    );
    yAxis.set(Constant.USER_DATA, yAxisProps);

    applyAxisSettings(xAxis, xAxisProps);
    applyAxisSettings(yAxis, yAxisProps);

    if (xAxisProps.showLabels) {
      addXAxisLabel(xAxis, xAxisProps.titleText);
    }

    if (yAxisProps.showLabels) {
      addYAxisLabel(yAxis, yAxisProps.titleText);
    }

    createAxisTooltip(xAxis, {
      dy: xAxisProps.showLabels ? 0 : -25,
    });
    createAxisTooltip(yAxis, {
      dx: xAxisProps.showLabels ? 0 : 50,
    });
  }

  function applyAxisSettings(axis, props) {
    const renderer = axis.get('renderer');
    renderer.labels.template.setAll({
      fontSize: 12,
      visible: props.showLabels,
    });
    renderer.grid.template.set('visible', !props.hideGridLines);
  }

  function addXAxisLabel(axis, text) {
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);
    axis.children.moveValue(
      Label.new(root.value, {
        text: `[bold]${text}`,
        centerX: p50,
        x: p50,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: 12,
        fill: color(fontColorPicker),
      }),
      axis.children.length - 1
    );
  }

  function addYAxisLabel(axis, text) {
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);
    axis.children.moveValue(
      Label.new(root.value, {
        text: `[bold]${text}`,
        centerX: p50,
        y: p50,
        rotation: -90,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: 12,
        fill: color(fontColorPicker),
      }),
      0
    );
  }

  return {
    createBubbleAxes,
  };
}
