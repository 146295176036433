'use strict';
import angular from 'angular';
import _ from 'lodash';
import {DataSourceType} from "../../../../shared/scripts/app.constants";
import $ from "jquery";

angular.module('dashboardfilterheader.services', [])

    .constant('$DashboardFilterHeaderEvents', $EventConstants())
    .factory('DashboardFilterHeaderFactory', DashboardFilterHeaderFactory);

function $EventConstants() {
    return {
        UPDATE: 'dashboardfilterheader:UPDATE'
    };
}

/**
 * @ngInject
 */
function DashboardFilterHeaderFactory(
    DataSourceFactory,
    DashboardFilterPanelFactory,
    DesignFactory
) {
    var sharedFactory = DashboardFilterPanelFactory.shared;

    /**
     * Property to specify filter is widget exposed
     * @type {string}
     */
    var widgetSourcedKey = 'widget_sourced';

    /**
     * Property to specify filter is widget exposed
     * @type {string}
     */
    var widgetDataWarehouseKey = 'widget_data_warehouse';

    /**
     * Exposed view model object for controller
     * @type {{}}
     */
    var shared = {
        isActive: isActive,
        activeFilters: {dash: [], widgets: [], widget_data_warehouse: []},
        resetFilters: resetFilters
    };
    

    function listenToDashboardFilterUpdateInNewUI() {
        import(
            '../../../../../../../grok/src/modules/core/app/helpers/EventBus'
            ).then(module => {
            const eventBus = module.default;
            eventBus.listen('DashboardFilterPanelOldUIReset', () => {
                resetFilterValues();
                DashboardFilterPanelFactory.setFilters();
                DashboardFilterPanelFactory.resetFilterOptionList();
            });
        });
    }

    if (window.isNUI) {
        listenToDashboardFilterUpdateInNewUI()
    }

    return {
        shared: shared,
        setActiveFilters: setActiveFilters,
        resetFilterValues: resetFilterValues
    };

    function isActive() {
        var $rightFrame = angular.element('div#right-frame');
        var isActive = shared.activeFilters.dash.length || shared.activeFilters.widgets.length || shared.activeFilters.widget_data_warehouse.length;
        if (isActive) {
            $rightFrame.addClass('has-dashboard-filter');
        }
        else {
            $rightFrame.removeClass('has-dashboard-filter');
        }
        return isActive;
    }

    /**
     * Reset currently selected header filter values
     */
    function resetFilterValues() {
        shared.activeFilters = {dash: [], widgets: [], widget_data_warehouse: []};
    }

    /**
     * Reset currently selected filters and the dashboard level as well
     */
    function resetFilters() {
        resetFilterValues();
        sharedFactory.resetFilterValues(true, true);
    }

    /**
     * Sets the filters to display that have actual values
     */
    function setActiveFilters() {
      var activeFilters = { dash: [], widgets: [], widget_data_warehouse: []};
      var pageId = DesignFactory.getCurrentPage().id;
      var filters = sharedFactory.filters;
      const { metadata } = DesignFactory.getCurrentPage();
      _.each(filters, function (filter, key) {
        if (key === widgetDataWarehouseKey && !_.isUndefined(filter[pageId])) {
          _.each(filter[pageId], function (item, key) {
            if (item.values && item.values.length) {
              activeFilters.widget_data_warehouse =
                activeFilters.widget_data_warehouse.concat(item);
            }
          });
        } else if (key === widgetSourcedKey && !_.isUndefined(filter[pageId])) {
          _.each(filter[pageId], function (item, key) {
            var ds = resolveDataSource(key);

            if (ds.type !== DataSourceType.GOAL_DATA) {
              item.dataSource = DataSourceFactory.getDataSourceDetails(
                ds.type,
                ds.id,
                true,
              );
              item.dataSource.data_view = ds.data_view;
            }

            if (item.values && item.values.length) {
              activeFilters.widgets = activeFilters.widgets.concat(item);
            }
          });
        } else {
          if (filter.values && filter.values.length) {
            if (metadata?.selected_entity && filter.label === "Client") {
              activeFilters.dash.push(filter);
            } else if (_.isNil(metadata?.selected_entity)) {
              activeFilters.dash.push(filter);
            }
          }
        }
      });

      if (
        activeFilters.dash &&
        activeFilters.dash.length > 1 &&
        metadata &&
        !_.isNull(metadata.selected_entity)
      ) {
        activeFilters.dash.splice(1, 1);
        $.core.main.notify(DataSourceType.MULTIPLE_FILTER_ERROR_MESSAGE, null, {
          autoHideDelay: 10000,
        });
      }
      if (
        !_.isNil(metadata) &&
        !_.isNil(metadata.groupedClientIds) &&
        activeFilters.dash.length === 1 &&
        !_.includes(
          metadata.groupedClientIds,
          activeFilters.dash[0].values[0].id
        )
      ) {
        activeFilters.dash.splice(0, 1);
      }
      shared.activeFilters = activeFilters;
    }

    /**
     * Determines data source info based on a widget filter data source key (ex: service|55|cgn)
     * @param dataSourceKey
     * @returns {{type: (string), id: (string), data_view: (string)}}
     */
    function resolveDataSource(dataSourceKey) {
        var parts = dataSourceKey.split('|');
        return {
            type: parts[0],
            id: parts[1],
            data_view: parts[2]
        };
    }
}