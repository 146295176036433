export const FormCypressLocatorMixin = {
  props: {
    // data-cy attribute overwritten from the default
    cy: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    cypressLocator() {
      if (this.cy) {
        return this.cy;
      }

      return this.defaultCYLocator;
    },
    // override in component to apply a sensible default
    defaultCYLocator() {
      return 'input-element';
    },
  },
};
