export const ROTATE_X = 'rotateX';
export const HEAT_V2 = 'heat(v2)';
export const BUBBLE_V2 = 'bubble(v2)';
export const HOVER = 'hover';
// export const NAME = '{name}';
export const WORLD = 'world';
export const STATE = 'state';
export const VALUE = 'value';
export const FILL = 'fill';
export const CLICK = 'click';
export const DRILLDOWN = 'drilldown';
export const BACKGROUND = 'background';
export const HEAT_RULES = 'heatRules';
export const COUNTRY = 'country';
export const ZOOM_CONTROL = 'zoomControl';
export const GEO_LATITUDE = 'geo_latitude';
export const GEO_LONGITUDE = 'geo_longitude';
export const WORLD_MAP = 'world map';
export const BUBBLE_WORLD_MAP = 'bubble world map';
export const YELLOW = '#FFFF00';
export const DARK_YELLOW = '#E6CC00';
export const DEFAULT_MAP_COLOR = '#FAD25A';
export const NAME = 'name';
export const AM5_COUNTRY_DRILLDOWN = '$WidgetEvents:am5updatecountrydrilldown';
export const VISIBLE = 'visible';
export const HORIZONTAL = 'horizontal';
export const VALUE_LOW = 'valueLow';
export const VALUE_HIGH = 'valueHigh';
export const START_COLOR = 'startColor';
export const END_COLOR = 'endColor';
export const DATAVALIDATED = 'datavalidated';
export const START_VALUE = 'startValue';
export const END_VALUE = 'endValue';
export const POINT_OVER = 'pointerover';
export const FILL_OPACITY = 'fillOpacity';
export const GeoConfigurationType = {
  COUNTY: 'county',
  STATE: 'state',
  COUNTRY: 'country',
  PIN: 'pin',
};
export const LoadingState = {
  GEO_PLOT_CHANGE_HEAT_MAP: 'geoPlotChangeHeatMap',
  GEO_PLOT_CHANGE_BUBBLE_MAP: 'geoPlotChangeBubbleMap',
  BUBBLE_CHART_REQUIRED_METRICS: 'bubbleChartRequiredMetrics',
  HAS_GLOBAL_FILTERS: 'hasGlobalFilters',
  DONE: false,
};
export const Countries = {
  AQ: 'AQ',
  US: 'US',
  USA_LOW: 'usaLow',
  USA_TERRITORIES_LOW: 'usaTerritoriesLow',
};
