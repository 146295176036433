/* eslint import/no-cycle: [0, { maxDepth: 1 }] */
import { isEmpty, values, isPlainObject } from 'lodash';
import axios from 'axios';
import DevTools from '@/modules/core/devtools/DevTools';
import { ApiError } from '@/modules/core/app/models/api/ApiError';
import ApiMetadata from '@/modules/core/app/models/api/ApiMetadata';
import DocumentUIService from '@/modules/core/app/services/DocumentUIService';
import { v4 as uuid } from 'uuid';
import UuidService from '@/modules/core/app/services/UuidService';

export const API_BASE_URL = '/server/api';
export const LEGACY_API_BASE_URL = '/app/dash/';

// eslint-disable-next-line tap/no-raw-text-js
export const LEGACY_IO_TOOL_BASE_URL = '/app/iotool/';

export const getBaseUrlRoot = () => process.env.VUE_APP_LOCAL_ORIGIN || window.location.origin;

export const getBaseUrl = () => `${getBaseUrlRoot()}${API_BASE_URL}`;

export const apiAxios = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
});

export const apiLegacyAxios = axios.create({
  baseURL: `${getBaseUrlRoot()}${LEGACY_API_BASE_URL}`,
  withCredentials: true,
});

export const apiLegacyIoToolAxios = axios.create({
  baseURL: `${getBaseUrlRoot()}${LEGACY_IO_TOOL_BASE_URL}`,
  withCredentials: true,
});

export const apiAxiosExternal = (config = {}) => axios.create(config);

export const apiRequestInterceptor = (request) => {
  if (request.method === 'post' || request.method === 'put') {
    if (!isEmpty(request.data) && !(request.data instanceof FormData)) {
      const formData = {};
      formData.model = JSON.stringify(request.data);
      request.data = formData;
    }
    if (request.data instanceof FormData) {
      request.method = 'post';
    }
  }
  if (request.method === 'get' && request.params) {
    const id = uuid();
    UuidService.add(id);
    request.params.query_id = id;
  }
  // DEV ONLY - Store all requests to be able to expose them later and replay them
  if (DevTools.isEnabled()) {
    if (request.method === 'get') {
      if (request.params) {
        request.params.sql = 2;
      }
      DevTools.logRequest(request);
    }
  }
  return request;
};

export const apiErrorInterceptor = async (response) => {
  const { data } = response.response;
  if (DevTools.isEnabled() && response) {
    DevTools.logError(response, data);
  }
  if (data instanceof Blob) {
    const error = await _handleBlobError(data);
    throw new ApiError(error);
  } else if (
    data.status === 401 &&
    data.data?.length > 0 &&
    data.data[0] ===
      i18n.$t('You’ve been logged out due to inactivity. Please log in again to continue.')
  ) {
    DocumentUIService.reloadApp();
  } else {
    throw new ApiError(data);
  }
};

export const apiLegacyResponseInterceptor = (response) => {
  let result = null;

  if (response.data.error || response.data.status === 'error') {
    throw new ApiError(response.data);
  } else if (response.data instanceof Blob) {
    result = response;
  } else {
    result = response.data;
  }
  if (response.data?.query_id) {
    UuidService.remove(response.data.query_id);
  }
  return result;
};

export const apiResponseInterceptor = (response) => {
  const { data } = response;
  let result = null;

  if (DevTools.isEnabled()) {
    const { config } = response;
    if (data.error || DevTools.isErrorString(data)) {
      DevTools.logError(config, data);
    } else if (config.params && 'sql' in config.params) {
      DevTools.logResponse(config, data);
    }
  }

  if (data.error) {
    throw new ApiError(data);
  } else if (data.metadata) {
    result = new ApiMetadata(data.metadata, response.config.params.summary).columns;
  } else if (data instanceof Blob) {
    result = response;
  } else if (data instanceof Array) {
    result = 'sEcho' in data.data ? [data.data] : values(data.data);
  } else if (isPlainObject(data) && ('total_data' in data || 'extra_data' in data)) {
    result = data;
  } else if (isPlainObject(data) && 'data' in data) {
    result = data.data;
  } else {
    result = data;
  }

  if (data?.query_id) {
    UuidService.remove(data.query_id);
  }
  return result;
};

/**
 * Handle blob errors.
 * @param data
 * @returns {Promise}
 * @private
 */
export const _handleBlobError = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(data);
    reader.onload = (event) => {
      try {
        const response = JSON.parse(event.target.result);
        if (response.status === 401) {
          DocumentUIService.reloadApp();
        }
        resolve(response);
      } catch {
        reject(new Error(i18n.$t('Something went wrong. Please try again.')));
      }
    };
  });
