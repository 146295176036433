import { BaseEntityResource } from '@/modules/core/app/resources/BaseEntityResource';
import { RouteGroup, LegacyRouteGroup } from '@/modules/core/app/constants/api.constants';
import { Client } from '@/modules/ta/client/models/Client';
import {
  apiAxios,
  apiLegacyAxios,
  apiLegacyIoToolAxios,
} from '@/modules/core/app/helpers/AxiosHelper';
import { Module } from '@/modules/core/app/constants/app.constants';
import { FacebookAdsServiceId } from '@/modules/ta/service/service.constants';

export class ClientResource extends BaseEntityResource {
  constructor() {
    super(RouteGroup.CLIENTS, Client);
  }

  async getAccountManagerOptions(params = {}) {
    return apiLegacyAxios.get(`${LegacyRouteGroup.CLIENT}/get_possible_account_managers`, {
      params,
    });
  }

  async getCustomClientFormGroups(params = {}) {
    return apiLegacyAxios.get(`${LegacyRouteGroup.CLIENT}/clientFormFieldGroups`, {
      params,
    });
  }

  async getCustomClientFormFields(params = {}) {
    return apiLegacyAxios.get(`${LegacyRouteGroup.CLIENT}/clientFormFields`, {
      params,
    });
  }

  async getCustomClientFormFieldValues(params = {}) {
    return apiLegacyAxios.get(`${LegacyRouteGroup.CLIENT}/getCustomFields`, {
      params,
    });
  }

  async getIotoolStatusByClusterId(params = {}) {
    return apiLegacyAxios.get(`${LegacyRouteGroup.CLIENT}/getIotoolStatusByClusterId`, {
      params,
    });
  }

  async getLogo(website, name) {
    return apiAxios.get(`${this.routeGroup}/getlogo?name=${name}&website=${website}`);
  }

  async canActivateTapLeads() {
    return apiAxios.get(`${this.routeGroup}/canActivateTapLeads`);
  }

  async getCompanyNameSuggestions(input) {
    return apiAxios.get(`${this.routeGroup}/getcompanynamesuggestions?input=${input}`);
  }

  async getPlaceDetails(placeId) {
    return apiAxios.get(`${this.routeGroup}/getplacedetails?place_id=${placeId}`);
  }

  /* saves selected column preferences
   * @param data
   * @returns {Promise<*>}
   */
  async saveColumnPreferences(data) {
    return apiAxios.post(`/savecolumnpreferences/${Module.CLIENT}`, data);
  }

  /**
   *
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  async getFbAdsAccounts() {
    return apiLegacyIoToolAxios.get(
      `/api/getFacebookAccountList?serviceId=${FacebookAdsServiceId}` // eslint-disable-line tap/no-raw-text-js
    );
  }

  /**
   *
   * @param clientId
   * @param clusterId
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  async getFbAdsPages(clientId, clusterId) {
    return apiLegacyIoToolAxios.get(
      `/api/getFbPagesForClient?clientId=${clientId}&clusterId=${clusterId}` // eslint-disable-line tap/no-raw-text-js
    );
  }

  saveNaicsDownloadAuditLog(clientId) {
    return apiAxios.post(`${this.routeGroup}/savenaicsdownloadlog/${clientId}`);
  }
}

export default new ClientResource();
