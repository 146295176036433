import { DatasourcedWidgetMetadata } from '@/modules/ta/widget/models/metadata/DataSourcedWidgetMetadata';

export class ChartWidgetMetadata extends DatasourcedWidgetMetadata {
  /**
   * Color of palette of series in a chart.
   *
   * @var {Array}
   */
  chart_palette;

  /**
   * Is actively grouped by multiple columns (NOT drilldown).
   *
   * @var {boolean}
   */
  is_multi_grouped;

  /**
   * Contains y-axis positions.
   *
   * @var {Object}
   */
  move_y_axes;

  /**
   * @var {Array}
   */
  sort_by;

  /**
   * @var {Array}
   */
  sort_order;

  /**
   * @var {Array}
   */
  cumulative_columns;

  /**
   * @var {boolean}
   */
  currency_discrepancy;

  /**
   * @var {boolean}
   */
  show_currency;

  /**
   * @var {Object}
   */
  bullets_per_column;

  /**
   * @var {Object}
   */
  bullets_per_benchmark;

  /**
   * @var {Array}
   */
  comparison_overwrite;

  constructor(model = {}) {
    super(model);

    this.chart_palette = model.chart_palette || null;
    this.is_multi_grouped = model.is_multi_grouped || false;
    this.move_y_axes = model.move_y_axes || [];
    this.sort_by = model.sort_by || [];
    this.sort_order = model.sort_order || [];
    this.cumulative_columns = model.cumulative_columns || [];
    this.currency_discrepancy = model.currency_discrepancy;
    this.show_currency = model.show_currency;
    this.bullets_per_column = model.bullets_per_column || {};
    this.bullets_per_benchmark = model.bullets_per_benchmark || {};
    this.comparison_overwrite = model.comparison_overwrite || [];
  }
}
