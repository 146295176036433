'use strict';
import angular from 'angular';
import _ from 'lodash';
import './scripts/category.ctrls';
import './scripts/category.directives';
import './scripts/category.services';

angular.module('core.category', [
  'core.category.ctrls',
  'core.category.directives',
  'core.category.services',
]);
