import moment from 'moment';
import { commits } from '@/modules/core/app/helpers/store';
import CacheHelper from '@/modules/core/app/helpers/CacheHelper';
import { OAUTH_PARAMS_KEY } from '@/modules/core/oauth/oauth.constants';

export const actions = {
  /**
   * @param {OAuthState} state
   * @param {Object} params
   */
  setOAuthParams(state, params) {
    commits.oauth.setOAuthParams(params);
    CacheHelper.set(OAUTH_PARAMS_KEY, params, moment().add(15, 'minutes').unix());
  },
  resetOAuthParams() {
    commits.oauth.resetOAuthParams();
    CacheHelper.remove(OAUTH_PARAMS_KEY);
  },
};
