import { getters } from '@/modules/core/app/helpers/store';
import UserResource from '@/modules/ta/user/resources/UserResource';
import { BaseService } from '@/modules/core/app/services/abstracts/BaseService';
import { UserStatus, UserType } from '@/modules/ta/user/user.constants';
import { AppModule } from '@/modules/core/app/constants/app.constants';

export class UserService extends BaseService {
  /**
   * @param id
   * @param value
   * @returns {Promise<*>}
   */
  async setShowWelcome(id, value) {
    return UserResource.setShowWelcome(id, value);
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  getActiveUsers(params = {}) {
    return UserResource.getItems({ ...params, status: UserStatus.ACTIVE });
  }

  /**
   * @param params
   * @returns {Promise<*>}
   */
  getNoneClientActiveUsers(params = {}) {
    return this.getActiveUsers({ ...params, user_type: `!${UserType.CLIENT}` });
  }

  /**
   * @returns {Promise<*>}
   */
  getAvatars() {
    return UserResource.getAvatars();
  }

  /**
   *
   * @param userId
   * @returns {Promise<*>}
   */
  async getUserListSettings(userId) {
    return UserResource.getUserListSettings(userId);
  }

  /**
   *
   * @param listSettings
   * @returns {Promise<Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *>}
   */
  async setUserListSettings(listSettings) {
    return UserResource.setUserListSettings(listSettings);
  }

  getOrderAndWorkflowUsers(params = {}) {
    params = {
      ...{ params },
      ...{ all: true, status: UserStatus.ACTIVE, enable_stellar_module: true },
    };
    const userSettings = getters.session.getUserSettings();
    if (userSettings.isModuleAvailable(AppModule.IO_TOOL_STELLAR_SYNC)) {
      params = { ...params, ...{ enable_io_tool_module: true } };
    }
    return UserResource.getItems(params);
  }

  /**
   *
   * @param userId
   * @returns {Promise<*>}
   */
  async inviteTeam(userToInviteCredentials) {
    return UserResource.inviteTeam(userToInviteCredentials);
  }
}

export default new UserService();
