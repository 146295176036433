import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { ListColumn } from '@/modules/core/list/models/ListColumn';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import NumeralService from '@/modules/core/app/services/NumeralService';
import moment from 'moment';
import {
  Container,
  Triangle,
  Label,
  p50,
  color,
  Bullet,
  RoundedRectangle,
  p100,
} from '@amcharts/amcharts5';

export function useComparison(context) {
  const { chart, root } = context();
  function calculateDeltaValue(value, comparisonValue, column) {
    if (!value || !comparisonValue) {
      return 0;
    }
    const columnFormatCheck = new ListColumn(column);

    if (columnFormatCheck.isFormatNumerical()) {
      return calculateNumericalDelta(value, comparisonValue, column);
    }
    if (columnFormatCheck.isFormatTime()) {
      return calculateTimeDelta(value, comparisonValue, column);
    }
    if (columnFormatCheck.isFormatDateTime()) {
      return calculateDateTimeDelta(value, comparisonValue, column);
    }
    return 0;
  }

  /**
   * @param value
   * @param comparisonValue
   * @param column
   */
  function calculateNumericalDelta(value, comparisonValue, column) {
    const deltaValue = NumeralService.calculateDelta(
      value,
      comparisonValue,
      column.compare_as_value
    );
    return NumeralService.formatValue(Math.abs(deltaValue), ColumnFormat.FORMAT_INTEGER, 0);
  }

  /**
   * @param value
   * @param comparisonValue
   * @param column
   * @private
   */
  function calculateTimeDelta(value, comparisonValue, column) {
    // get seconds of each time (HH:mm:ss) as the unit of measure since seconds is the smallest measure
    if (value.toString().includes(':')) {
      value = value.split(':').reduce((acc, time) => 60 * acc + +time);
    }
    if (comparisonValue.toString().includes(':')) {
      comparisonValue = comparisonValue.split(':').reduce((acc, time) => 60 * acc + +time);
    }

    const deltaValue = NumeralService.calculateDelta(
      value,
      comparisonValue,
      column.compare_as_value
    );
    return NumeralService.formatValue(Math.abs(deltaValue), ColumnFormat.FORMAT_INTEGER, 0);
  }

  /**
   * @param value
   * @param comparisonValue
   * @param column
   */
  function calculateDateTimeDelta(value, comparisonValue, column) {
    const momentValue = moment(value).unix() / 1000;
    const momentComparisonValue = moment(comparisonValue).unix() / 1000;

    const deltaValue = NumeralService.calculateDelta(
      momentValue,
      momentComparisonValue,
      column.compare_as_value
    );
    return NumeralService.formatValue(Math.abs(deltaValue), ColumnFormat.FORMAT_INTEGER, 0);
  }

  /**
   * @param percentArray
   */
  function createBulletsForComparison(percentArray = []) {
    const { series } = chart.value;

    if (percentArray.length) {
      series.each((itemSeries, index) => {
        const lastIndex = series.length - 1;
        const item = itemSeries.dataItems[itemSeries.dataItems.length - 1];
        const { dataContext } = item;

        itemSeries.events.on(Constant.DATA_VALIDATED, () => {
          const {
            percentValue,
            currentLastValue,
            comparisonLastValue,
            field,
            comparisonField,
            status,
            statusLocation,
          } = percentArray[index];

          if (dataContext) {
            const isMatchingValues =
              dataContext[field] === currentLastValue &&
              dataContext[comparisonField] === comparisonLastValue;
            if (isMatchingValues) {
              const container = createContainer(
                percentValue,
                itemSeries.get(Constant.FILL),
                status
              );
              const locationValue = statusLocation ? 0.5 + (index / lastIndex) * 0.5 : 0.9;

              itemSeries.addBullet(
                item,
                Bullet.new(root.value, {
                  sprite: container,
                  locationX: locationValue,
                })
              );
            }
          }
        });
      });
    }
  }

  function createContainer(percentValue, fill, status) {
    const bullet = Container.new(root.value, {
      layout: root.horizontalLayout,
      cornerRadius: 15,
      background: createBackground(fill),
    });

    bullet.children.push(createLabel(percentValue));
    bullet.children.push(createTriangle(status));

    return bullet;
  }

  function createBackground(fill) {
    return RoundedRectangle.new(root.value, {
      fill,
      width: 50,
      height: 50,
      opacity: 0.3,
      cornerRadius: 15,
    });
  }

  function createLabel(percentValue) {
    return Label.new(root.value, {
      text: `${percentValue}%`,
      centerX: p50,
      fontSize: 11,
      marginBottom: 500,
    });
  }

  function createTriangle(status) {
    return Triangle.new(root.value, {
      width: 8,
      height: 8,
      fill: status ? color(0xff0000) : color(0x00cc00),
      rotation: status ? 180 : 360,
      centerY: p100,
    });
  }

  return {
    calculateDeltaValue,
    calculateNumericalDelta,
    calculateTimeDelta,
    calculateDateTimeDelta,
    createBulletsForComparison,
  };
}
