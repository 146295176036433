'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';
import DevTools from '../../../devtools';
import oAuth from '../../../oauth';
import initAngularTemplatedDirectives from '../../shared/scripts/app.services';
import { AppModule } from 'coreModules/shared/scripts/app.constants';
import { LoginRedirect } from 'coreModules/login/login.constants';

var Main = $.core.main;

angular.module('core.login.services', [])
    .service('LoginFactory', LoginFactory);

/**
 * @ngInject
 */
function LoginFactory(
    $rootScope,
    $q,
    $http,
    $state,
    $templateCache,
    AppFactory,
    UIFactory,
    CoreFactory,
    SlidePanelFactory,
    CategoryFactory,
    DashboardFilterPanelFactory,
    ExecutiveSummaryFactory,
    Restangular,
    $window,
    UserLoginFactory,
    ClusterService,
    UserIntegrationResource,
    ThemeUIService,
    LanguageService,
    AppConfig,
    WindowUtilUIService
) {
    let initUserPromise;
    let userVarsSet = false;
    return {
        login: login,
        remoteLogin: remoteLogin,
        impersonateUser: impersonateUser,
        unimpersonateUser: unimpersonateUser,
        refreshLegacyView: refreshLegacyView,
        setDefaultUserVars: setDefaultUserVars,
        initUser: initUser,
        getUserSession: getUserSession,
        isLoggedIn: isLoggedIn,
        areUserVarsSet: areUserVarsSet,
        postLoginReroute: postLoginReroute,
        logout: logout,
        sendResetPasswordLink: sendResetPasswordLink,
        resetPassword: resetPassword,
        getOAuthAuthorizationCode: getOAuthAuthorizationCode,
        isRemoteLogin: isRemoteLogin,
        ezLogin: ezLogin,
        googleLogin: googleLogin,
        goToDefaultHome: goToDefaultHome,
    };

    /**
     *
     * @param code
     * @param from_google_login_redirect_state
     * @param from_google_login_redirect_params
     * @returns {*}
     */
    function googleLogin(code, from_google_login_redirect_state, from_google_login_redirect_params) {
        return $http.get($rootScope.util.apiUrl('session/googleLogin'), {
            params: {
                'code': code,
                'from_google_login_redirect_state': from_google_login_redirect_state,
                'from_google_login_redirect_params': from_google_login_redirect_params
            }
        });
    }


    /**
     *
     * @param id
     * @param verificationCode
     * @param isLoggedOut
     * @returns {*}
     */
    function ezLogin(id, verificationCode, isLoggedOut) {
        return $http.get($rootScope.util.apiUrl('session/ezLogin'), {
            params: {
                'id': id,
                'verification_code': verificationCode,
                'is_logged_out': isLoggedOut
            }
        });
    }

    /**
     * Checks if remote login key is valid or not.
     * @param remoteLoginKey
     * @param isDirectSignup
     * @returns {*}
     */
    function isRemoteLogin(remoteLoginKey, isDirectSignup) {
        return $http.get($rootScope.util.apiUrl('session/isRemoteLogin'), {
            params: {
                remote_login_key: remoteLoginKey,
                is_direct_signup: isDirectSignup
            }
        });
    }

    /**
     *
     * @param model
     */
    function login(model) {
        return $http.post($rootScope.util.apiUrl('session/login'), model).success(function (response) {
            var data = response.data;
            if (data.type === 'expired_password') {
                $state.go('resetpassword');
            } else if (data.type === 'external_url') {
                $state.go('overview');
            } else {
                Main.hideAllNotifies();
                $('input#email').tooltip('hide');
                // Show any login messages if any
                if (data.message != null) {
                    Main.notify(data.message, $.globals.notify.warning);
                }
                if (data.settings.isImpersonationMode) {
                    _logOutImpersonatingUser();
                } else {
                    setPostLoginUserVars(data.settings);
                    postLoginReroute(data.settings);
                }
            }
        });
    }

    /**
     * Logged in through DCP or OAuth
     */
    function remoteLogin() {
        return this.initUser().then(function (response) {
            let data = response.data;
            if (data.settings && data.settings.isLoggedIn) {
                setPostLoginUserVars(data.settings);
                postLoginReroute(data.settings);
            } else {
                $state.go('login');
            }
        }).catch((response) => {
            Main.formErrorNotify(response);
        });
    }

    /**
     * View app as a specific user (login as that user but have an exit to relogin as yourself)
     * @param user
     */
    function impersonateUser(user) {
        // Used to store current user in session and logs in backend
        user.impersonator_user = AppFactory.getUser();
        return $http.get($rootScope.util.apiUrl('session/impersonate/' + user.id)).success(function (json) {
            if (!json.error) {
                var settings = json.data;
                if (AppFactory.mustGoToNewApp(settings)) {
                    WindowUtilUIService.navigateToNewApp();
                } else {
                    UserLoginFactory.setIntendedState();
                    setPostLoginUserVars(settings);
                    initializeIOCluster(settings);
                    LanguageService.setApplicationLanguage(settings).then(function () {
                        window.location = window.location.origin;
                        UIFactory.hideModal('user-impersonation-modal');
                    });
                }

            } else {
                Main.formErrorNotify(json);
            }
        });
    }

    function unimpersonateUser() {
        $.core.main.showAjaxMainLoading({top: 0, left: 0, text: 'Logging Back In As Yourself...'});
        _logOutImpersonatingUser();
    }

    /**
     * Resets everything needed for legacy view to be back to an original state
     */
    function refreshLegacyView() {
        AppFactory.refreshServices();
        $.core.main.reEnableServiceNavItems();
        $templateCache.removeAll();
        initAngularTemplatedDirectives($templateCache);
        $state.reload($state.current);
        DashboardFilterPanelFactory.shared.resetFilterValues(false);
        DashboardFilterPanelFactory.resetFilterOptionList();
    }

    /**
     * @param settings
     * @param isLegacyInit
     */
    function setDefaultUserVars(settings, isLegacyInit = false) {

        var $html = $('html');
        if (!isLegacyInit && !$html.hasClass(settings.theme + settings.themeType) || ThemeUIService.getForceReload()) {
            ThemeUIService.update(settings.theme, settings.themeType, settings.primaryThemeColor, settings.secondaryThemeColor, true);
            ThemeUIService.setForceReload(false);
            $('#start-app-loader').removeClass('hidden');
        }

        if (settings.isFrameMode) {
            $('body').addClass('frame-mode');
        }

        // Vars to set only once logged in
        if (settings.isLoggedIn) {
            if (!settings.isLocalInstance) {
                //TODO: refactor into own resource.js files
                // Only add Google Tag Manager dataLayer if logged in
                if (window.dataLayer === undefined) {
                    (function (w, d, s, l, i) {
                        w[l] = w[l] || [];
                        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                        var f = d.getElementsByTagName(s)[0],
                            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                        j.async = true;
                        j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
                        f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', settings.GTMkey);

                    dataLayer.push({
                        userType: settings.type,
                        userRole: settings.roleId,
                        userCompany: settings.company
                    });
                }
            }

            if (settings.vendastaAccountId) {
                UserIntegrationResource.integrateVendasta(
                    settings.vendastaAppId,
                    settings.vendastaAccountId,
                    settings.vendastaNavbarDataUrl
                );
            }

            // Set currency symbol
            if (settings.currencyCode != null) {
                $.globals.utils.currencySymbol = settings.currencyCode;
            }
            $.globals.utils.currencyCode = settings.currencyCodeValue;

        }

        // Set and update user objects pre and post login
        // NOTE: postLogin sets the logged user specific properties
        $rootScope.user = settings;
        AppFactory.setUser(settings);
        DevTools.setAsEnabled(settings.hasDevTools);
        Main.setGlobalUser(settings);
        userVarsSet = true;

        // Unlike resolved resources in app.modules, we need to resolve this here since we need to
        // be logged in and see available features to first to decide if we need to fetch categories
        if ($rootScope.util.isFeatureAvailable('categories')) {
            CategoryFactory.getCategories().then(function (json) {
                AppFactory.setCategories(json.plain());
            });
        }
    }

    function areUserVarsSet() {
        return userVarsSet;
    }

    /**
     *
     * @param redirectToLogin
     */
    function isLoggedIn(redirectToLogin) {
        return $http.get($rootScope.util.mageUrl('session/isUserLoggedIn')).then(function (res) {
            if (res.data.status == 'error') {
                if (redirectToLogin !== false) {
                    return $state.go('login');
                }
                return false;
            }
            return true;
        });
    }

    function logout() {
        var self = this;
        var isIORoute = AppFactory.getUser().isIORoute;

        $http.get($rootScope.util.apiUrl('session/logout')).success(function () {
            // Reset user to pre login state
            self.initUser();
            $templateCache.removeAll();
            initAngularTemplatedDirectives($templateCache);
            SlidePanelFactory.closeAll();

            // Redirect user only after he has been logged out because we are calling initUser in login
            UserLoginFactory.setUrl(null);
            $state.go('login').then(function () {
                Main.notify('You have successfully logged out', $.globals.notify.success);
                // Reset filters before logging out
                UserLoginFactory.setIntendedState();
                DashboardFilterPanelFactory.shared.resetFilterValues(false, true);

                // NOT needed for TA
                if (isIORoute) {
                    $window.location.reload();
                }
            });
        });
    }

    function setPostLoginUserVars(settings) {
        ExecutiveSummaryFactory.resetClientSelect();

        setDefaultUserVars(settings);

        $templateCache.removeAll();
        initAngularTemplatedDirectives($templateCache);
    }

    /**
     * Handling Cluster Selector for IOTool while impersonating the user
     * @param settings
     */
    function initializeIOCluster(settings) {
        if (settings && $rootScope.userRole && ClusterService && ClusterService.pageProperties) {
            ClusterService.clusterIds = null;
            ClusterService.pageProperties.defaultClusters = null;
            ClusterService.pageProperties.customClusters = null;
            ClusterService.pageProperties.selectedClusters = [];
            $rootScope.userRole.cluster_id = settings.clusterId;
            $rootScope.userRole.cluster_ids = settings.clusterIds;
            $rootScope.userRole.role_name = settings.ioRoleName;
            $rootScope.userRole.permissions = settings.ioRolePermissions;
            $rootScope.userRole.id = settings.userId;
            $rootScope.userRole.first_name = settings.firstName;
            $rootScope.userRole.last_name = settings.lastName;
            $rootScope.userRole.user_type = settings.type;
        }
    }

    function goToDefaultHome(settings) {
        const route = UserLoginFactory.getDefaultHomeRoute(settings);
        return $state.go(route.state, route.params, route.options);
    }

    function postLoginReroute(settings) {
        if (sessionStorage.getItem(LoginRedirect.REDIRECT_URL_KEY)) {
            const redirectUrl = sessionStorage.getItem(LoginRedirect.REDIRECT_URL_KEY);
            sessionStorage.removeItem(LoginRedirect.REDIRECT_URL_KEY);
            if (!redirectUrl.endsWith('#/login')) {
            if (AppFactory.mustGoToNewApp(settings) && _.isNull(oAuth.clientId)) {
                if (settings.isIOHomePage) {
                    return goToDefaultHome(settings);
                } else {
                    WindowUtilUIService.navigateToNewApp();
                }
            } else {
                window.location.href = redirectUrl;
            }
            return; }
        } else if (window.location.href.contains('redirectUrl=')) {
            window.location = window.location.href.split('redirectUrl=')[1].replace(/%2F/g, '/');
            return;
        }
        if (!AppFactory.mustGoToNewApp(settings) || !_.isNull(oAuth.clientId) || settings.isIOHomePage) {
            return goToDefaultHome(settings);
        } else {
            WindowUtilUIService.navigateToNewApp();
        }
    }

    function initUser(isLegacyInit = false) {
        if (initUserPromise) {
            return initUserPromise;
        }
        initUserPromise = getUserSession();
        initUserPromise.then(function (json) {
            setDefaultUserVars(json.data.settings, isLegacyInit);
            initUserPromise = null;
            return json;
        });
        return initUserPromise;
    }

    function getUserSession() {
        var defer = $q.defer();
        $http.get($rootScope.util.apiUrl('session/initUser'))
            .success(function (json) {
                defer.resolve(json);
            })
            .error(function () {
                defer.reject();
            });
        return defer.promise;
    }

    /**
     *
     * @param model
     */
    function sendResetPasswordLink(model) {
        return $http.post($rootScope.util.apiUrl('session/resetPasswordLink'), model).success(function () {
            Main.hideAllNotifies();
            $state.go('login', {message_id: 1});
        }).error(function (response) {
            Main.formErrorNotify(response.data);
        });
    }

    function resetPassword(model) {
        return $http.post($rootScope.util.apiUrl('session/processResetPassword'), model).success(function (response) {
            var data = response.data;
            Main.hideAllNotifies();
            if (data.type === 'success_login') {
                $state.go('remote_login');
            } else if (data.type === 'passwordLinkExpired') {
                $state.go('forgotpassword', {message_id: 1});
            } else {
                $state.go('login', {message_id: 2});
            }
        });
    }

    /**
     * Get an OAuth authorization code
     */
    function getOAuthAuthorizationCode() {
        if ($rootScope.util.isOauthRedirected) {
            return;
        }

        Restangular.one('oauth').one('clients')
            .get({"client_id": oAuth.clientId})
            .then(function (oauth_client) {
                if (oauth_client.length > 0) {

                    var getAuthorizationCode = function () {
                        var params = {
                            "response_type": "code",
                            "client_id": oAuth.clientId,
                            "redirect_uri": oAuth.redirect
                        };

                        if (oAuth.state) {
                            params.state = oAuth.state;
                        }

                        Restangular.one('oauth').one('authorize').get(params).then(function (response) {
                            $rootScope.util.isOauthRedirected = true;
                            $window.location.href = response.redirect;
                        });
                    };

                    oauth_client = oauth_client[0];
                    if (!oauth_client.authorized) {

                        // Show a modal dialog box to authorize an OAuth access
                        var clientName = oauth_client.name,
                            imageUrl = (!oAuth.icon) ? '' : oAuth.icon;
                        swal({
                            title: '',
                            text: '<b>' + clientName + '</b> requires your authorization to get access to your data',
                            html: true,
                            type: false,
                            showCancelButton: true,
                            confirmButtonColor: '#d9534f',
                            confirmButtonText: 'Accept',
                            closeOnConfirm: true,
                            imageUrl: imageUrl
                        }, function (isConfirm) {
                            if (isConfirm) {
                                // The user agreed to authorize the OAuth client, mark it as authorized in th DB
                                // And redirect to the OAuth callback
                                Restangular.one('oauth').one('authorizationusers').one('clients', oauth_client.id)
                                    .post()
                                    .then(function () {
                                        getAuthorizationCode();
                                    });
                            } else {
                                $rootScope.util.isOauthRedirected = true;
                                $window.location.href = oAuth.redirect;
                            }
                        });
                    } else {
                        // The OAuth client is already authorized, return an authorization code
                        getAuthorizationCode();
                    }
                }
            });
    }

    /**
     * Re-usable internal action to unimpersonate user
     * @private
     */
    function _logOutImpersonatingUser() {
        $http.get($rootScope.util.apiUrl('session/unimpersonate')).success(function (json) {
            if (!json.error) {
                var settings = json.data;
                if (AppFactory.mustGoToNewApp(settings)) {
                    window.location = AppConfig.NUI_PATH;
                } else {
                    setPostLoginUserVars(settings);
                    UserLoginFactory.setIntendedState();
                    initializeIOCluster(settings);
                    LanguageService.setApplicationLanguage(settings).then(function () {
                        refreshLegacyView();
                        window.location = window.location.origin;
                        UIFactory.hideModal('user-impersonation-modal');
                    });
                }

            } else {
                Main.formErrorNotify(json);
            }
        });
    }
}
