import { includes } from 'lodash';
import { UserType, UserThemeType, SupportLinks } from '@/modules/ta/user/user.constants';
import { User } from '@/modules/ta/user/models/User';
import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';
import { WeeklyStartDay } from '@/modules/ta/widget/widget.constants';
import { RouteGroup } from '@/modules/core/app/constants/api.constants';
import {
  RestrictUserTypeRolePermissionValue,
  RolePermissionKey,
  RolePermissionValue,
} from '@/modules/ta/role/role.constants';
import {
  FeaturePackage,
  InstanceStatus,
  InvoiceHandling,
} from '@/modules/core/session/session.constants';
import { ONBOARDING_STEPS } from '@/modules/core/onboarding/onboarding.constants';
import { Module } from '@/modules/core/app/constants/app.constants';

export class UserSettings extends BaseModel {
  /**
   * @var {UserThemeType}
   */
  themeType;

  /**
   * @var string{}
   */
  availableFeatures;

  /**
   * @var string{}
   */
  availableModules;

  /**
   * @var {string}
   */
  GTMkey;

  /**
   * @var {string}
   */
  accountManagerUserId;

  /**
   * @var {string}
   */
  agencySupportLink;

  /**
   * @var {string}
   */
  bigNumberWidgetsUseThemeColor;

  /**
   * @var {boolean}
   */
  canManageDashboards;

  /**
   * @var {boolean}
   */
  canManageServices;

  /**
   * @var {boolean}
   */
  canBUAdminManageServices;

  /**
   * @var {string}
   */
  clientSupportLink;

  /**
   * @var {string}
   */
  clusterAdminSupportLink;

  /**
   * @var {string}
   */
  clusterId;

  /**
   * @var {string[]}
   */
  clusterIds;

  /**
   * @var {string}
   */
  clusterName;

  /**
   * @var {string}
   */
  company;

  /**
   * @var {string}
   */
  currencyCode;

  /**
   * @var {string}
   */
  currencyCodeValue;

  /**
   * @var {string}
   */
  currentLogoUrl;

  /**
   * @var {string}
   */
  currentThemeColor;

  /**
   * @var {string}
   */
  featurePackageName;

  /**
   * @var {string|null}
   */
  platformSource;

  /**
   * @var {boolean}
   */
  hasTapReportsFreemiumPlatformSourceLimitation;

  /**
   * @var {boolean}
   */
  feedbackActive;

  /**
   * @var {array}
   */
  guides;

  /**
   * @var {boolean}
   */
  hasDevTools;

  /**
   * @var {boolean}
   */
  hideInactiveServicesLeftNav;

  /**
   * @var {boolean}
   */
  hideSuperAdminSupportLink;

  /**
   * @var {string}
   */
  homePage;

  /**
   * @var {User}
   */
  user;

  /**
   * @var {User}
   */
  impersonatorUser;

  /**
   * @var {string}
   */
  instanceStatus;

  /**
   * @var {string}
   */
  instanceURL;

  /**
   * @var {string}
   */
  ioRoleName;

  /**
   * @var {array}
   */
  ioRolePermissions;

  /**
   * @var {boolean}
   */
  isAdtaxiInstance;

  /**
   * @var {boolean}
   */
  isAnalyticsRoute;

  /**
   * @var {boolean}
   */
  isDevelopmentServer;

  /**
   * @var {boolean}
   */
  isFrameMode;

  /**
   * @var {boolean}
   */
  isFreeTrial;

  /**
   * @var {boolean}
   */
  isHipaaInstance;

  /**
   * @var {boolean}
   */
  isIOAuthorized;

  /**
   * @var {boolean}
   */
  isIOHomePage;

  /**
   * @var {boolean}
   */
  isIORoute;

  /**
   * @var {boolean}
   */
  isImpersonationMode;

  /**
   * @var {boolean}
   */
  isLocalInstance;

  /**
   * @var {boolean}
   */
  isLoggedIn;

  /**
   * @var {boolean}
   */
  isOnboarding;

  /**
   * @var {number}
   */
  onboardingStep;

  /**
   * @var {array}
   */
  completedOnboardingSteps;

  /**
   * @var {boolean}
   */
  isRemoteLogin;

  /**
   * @var {boolean}
   */
  isRevLocalInstance;

  /**
   * @var {boolean}
   */
  isRunningDbUpdate;

  /**
   * @var {boolean}
   */
  isStagingServer;

  /**
   * @var {string}
   */
  tapCoreStack;

  /**
   * @var {string}
   */
  landingPageState;

  /**
   * @var {string}
   */
  lightLogoUrl;

  /**
   * @var {string}
   */
  locale;

  /**
   * @var string{}
   */
  localesSupported;

  /**
   * @var {string}
   */
  defaultLocale;

  /**
   * @var {string}
   */
  primaryThemeColor;

  /**
   * @var {string}
   */
  reportingProfileId;

  /**
   * @var {string}
   */
  reportingProfileName;

  /**
   * @var {Role}
   */
  role;

  /**
   * @var {string}
   */
  secondaryThemeColor;

  /**
   * @var {boolean}
   */
  showCategoriesOverview;

  /**
   * @var {boolean}
   */
  showDrilldownsWhenOverviewDisabled;

  /**
   * @var {boolean}
   */
  showComparisonArrows;

  /**
   * @var {boolean}
   */
  showDataFreshnessDate;

  /**
   * @var {boolean}
   */
  showDefaultComparisonArrowColor;

  /**
   * @var {boolean}
   */
  showScheduledReports;

  /**
   * @var {boolean}
   */
  showScores;

  /**
   * @var {boolean}
   */
  showServiceDrilldowns;

  /**
   * @var {boolean}
   */
  showServicesOverview;

  /**
   * @var {boolean}
   */
  showPredefinedCategoryDashboards;

  /**
   * @var {string}
   */
  statusMessage;

  /**
   * @var {UserTheme}
   */
  theme;

  /**
   * @var {string}
   */
  vendastaAccountId;

  /**
   * @var {string}
   */
  vendastaAppId;

  /**
   * @var {string}
   */
  vendastaNavbarDataUrl;

  /**
   * @var {boolean}
   */
  isGoogleLoginEnabled;

  /**
   * @var {boolean}
   */
  isClustersSupportEnabled;

  /**
   * @var {string}
   */
  googleLoginUrl;

  /**
   * @var {boolean}
   */
  shouldHideTapClicksBranding;

  /**
   * @var {object}
   */
  pubSubDetails;

  /**
   * @var {boolean}
   */
  weekStartsOnMonday;

  /**
   * @var {?object}
   */
  customLoginLink;

  /**
   * @var {string}
   */
  applicationName;

  /**
   * @var {boolean}
   */
  isOnSupBilling;

  /**
   * @var {boolean}
   */
  isOnSupBillingCustomPlan;

  /**
   * @var {boolean}
   */
  smartCampaignsEnabled;

  /**
   * @var {boolean}
   */
  isDashboardGenerated;

  /**
   * @var {string}
   */
  invoiceHandling;

  /**
   * @var {boolean}
   */
  canAccessNewServices;

  /**
   * @var {boolean}
   */
  canAccessOldGoals;

  /**
   * $var {boolean}
   */
  isGoalsForAgentsEnabled;

  /**
   * @var {boolean}
   */
  product_images_in_catalogue;

  /**
   * @var {string}
   */
  timezone;

  /**
   * @var {string}
   */
  timezone_display;

  /**
   * @var {string}
   */
  instanceTimezone;

  /**
   * @var {string}
   */
  instanceTimezoneDisplay;

  /**
   * @var {string}
   */
  ui_timezone_display;

  /**
   * @var {string}
   */
  ui_timezone;

  /**
   * @var {boolean}
   */
  resellerBilling;

  /**
   * @var {string}
   */
  resellerName;

  /**
   * @var {string}
   */
  nuiLoginPageMessage;

  /**
   * @var {string}
   */
  customWelcomeMessage;

  /**
   * @var {boolean}
   */
  disableUpdateDashboardButton;

  /**
   * @var {boolean}
   */
  canAccessStellar;

  /**
   * @var {boolean}
   */
  isTestdriveInstance;

  /**
   * @var {string}
   */
  sheetsSelfServePlanId;

  /**
   * @var {boolean}
   */
  defaultNavFavorites;

  /**
   * @var {string}
   */
  passwordRequirements;

  /**
   * @var {boolean}
   */
  disableInactiveProductsFiltering;

  /**
   * @var {boolean}
   */
  overrideLoginPageFooterText;

  /**
   * @var {boolean}
   */
  isCurrentUserPredefinedSuperAdmin;

  /**
   * @var {boolean}
   */
  isTwoStepVerificationEnabledForUser;

  /**
   * @var {boolean}
   */
  hasAccessToAudience2;

  /**
   * @var {boolean}
   */
  useTwoStepRecaptcha;

  /**
   * @var {String}
   */
  customerInstanceURL;

  /**
   * @var {Boolean}
   */
  isMarketplaceCentralizedInstance;

  /**
   * @var {String}
   */
  plannerLaunchURL;

  /**
   * @var {boolean}
   */
  is_order_management_mode_enabled;

  /**
   * @var {boolean}
   */
  use_places_api_for_company_name;

  /**
   * @var {string}
   */
  whitelabelClusterId;

  /**
   * @var {array}
   */
  whitelabelClusterIdsByServerName;

  constructor(model = {}) {
    super(model);

    this.type = model.type;
    this.themeType = model.themeType;
    this.availableFeatures = model.availableFeatures;
    this.availableModules = model.availableModules;
    this.GTMkey = model.GTMkey;
    this.currentThemeColor = model.currentThemeColor;
    this.accountManagerUserId = model.account_manager_user_id;
    this.agencySupportLink = model.agencySupportLink;
    this.bigNumberWidgetsUseThemeColor = model.bigNumberWidgetsUseThemeColor;
    this.canManageDashboards = model.canManageDashboards;
    this.canManageServices = model.canManageServices;
    this.canBUAdminManageServices = model.canBUAdminManageServices;
    this.clientName = model.clientName;
    this.clientSupportLink = model.clientSupportLink;
    this.clusterAdminSupportLink = model.clusterAdminSupportLink;
    this.clusterId = model.clusterId;
    this.clusterIds = model.clusterIds;
    this.clusterName = model.clusterName;
    this.company = model.company;
    this.currencyCode = model.currencyCode;
    this.currencyCodeValue = model.currencyCodeValue;
    this.currentLogoUrl = model.currentLogoUrl;
    this.darkLogoUrl = model.darkLogoUrl;
    this.featurePackageName = model.featurePackageName;
    this.platformSource = model.platformSource;
    this.hasTapReportsFreemiumPlatformSourceLimitation =
      model.hasTapReportsFreemiumPlatformSourceLimitation;
    this.feedbackActive = model.feedbackActive;
    this.firstName = model.firstName;
    this.guides = model.guides;
    this.hasDevTools = model.hasDevTools;
    this.hasNameTest = model.hasNameTest;
    this.hideInactiveServicesLeftNav = model.hideInactiveServicesLeftNav;
    this.hideSuperAdminSupportLink = model.hideSuperAdminSupportLink;
    this.homePage = model.homePage;
    this.user = model.user ? new User(model.user) : null;
    this.impersonatorUser = model.impersonatorUser ? new User(model.impersonatorUser) : null;
    this.instanceStatus = model.instanceStatus;
    this.instanceURL = model.instanceURL;
    this.ioRoleName = model.ioRoleName;
    this.ioRolePermissions = model.ioRolePermissions;
    this.isAdtaxiInstance = model.isAdtaxiInstance;
    this.isAnalyticsRoute = model.isAnalyticsRoute;
    this.isDevelopmentServer = model.isDevelopmentServer;
    this.isFrameMode = model.isFrameMode;
    this.isFreeTrial = model.isFreeTrial;
    this.isHipaaInstance = model.isHipaaInstance;
    this.isIOAuthorized = model.isIOAuthorized;
    this.isIOHomePage = model.isIOHomePage;
    this.isIORoute = model.isIORoute;
    this.isImpersonationMode = model.isImpersonationMode;
    this.isLocalInstance = model.isLocalInstance;
    this.isLoggedIn = model.isLoggedIn;
    this.isOnboarding = model.isOnboarding;
    this.onboardingStep = model.onboardingStep;
    this.completedOnboardingSteps = model.completedOnboardingSteps;
    this.isRemoteLogin = model.isRemoteLogin;
    this.isSmartCampaignFilteringEnabled = model.isSmartCampaignFilteringEnabled;
    this.isExternalDestinationEnabled = model.isExternalDestinationEnabled;
    this.isRevLocalInstance = model.isRevLocalInstance;
    this.isRunningDbUpdate = model.isRunningDbUpdate;
    this.isStagingServer = model.isStagingServer;
    this.tapCoreStack = model.tapCoreStack;
    this.landingPageState = model.landingPageState;
    this.lastName = model.lastName;
    this.lightLogoUrl = model.lightLogoUrl;
    this.locale = model.locale;
    this.localesSupported = model.localesSupported;
    this.defaultLocale = model.defaultLocale;
    this.primaryThemeColor = model.primaryThemeColor;
    this.reportingProfileId = model.reportingProfileId;
    this.reportingProfileName = model.reportingProfileName;
    this.role = model.role;
    this.secondaryThemeColor = model.secondaryThemeColor;
    this.showCategoriesOverview = model.showCategoriesOverview;
    this.showDrilldownsWhenOverviewDisabled = model.showDrilldownsWhenOverviewDisabled;
    this.showComparisonArrows = model.showComparisonArrows;
    this.showDataFreshnessDate = model.showDataFreshnessDate;
    this.showDefaultComparisonArrowColor = model.showDefaultComparisonArrowColor;
    this.shouldHideTapClicksBranding = model.shouldHideTapClicksBranding;
    this.showScheduledReports = model.showScheduledReports;
    this.showScores = model.showScores;
    this.showServiceDrilldowns = model.showServiceDrilldowns;
    this.showServicesOverview = model.showServicesOverview;
    this.statusMessage = model.statusMessage;
    this.theme = model.theme;
    this.typeDisplayName = model.typeDisplayName;
    this.userDisplayName = model.userDisplayName;
    this.userEmail = model.userEmail;
    this.userId = model.userId;
    this.userImageId = model.userImageId;
    this.userImageMetadata = model.userImageMetadata;
    this.vendastaAccountId = model.vendastaAccountId;
    this.vendastaAppId = model.vendastaAppId;
    this.vendastaNavbarDataUrl = model.vendastaNavbarDataUrl;
    this.isGoogleLoginEnabled = model.isGoogleLoginEnabled;
    this.isClustersSupportEnabled = model.isClustersSupportEnabled;
    this.googleLoginUrl = model.googleLoginUrl;
    this.pubSubDetails = model.pubSubDetails;
    this.weekStartsOnMonday = model.weekStartsOnMonday;
    this.customLoginLink = model.customLoginLink;
    this.applicationName = model.applicationName;
    this.isOnSupBilling = model.isOnSupBilling;
    this.isOnSupBillingCustomPlan = model.isOnSupBillingCustomPlan;
    this.showPredefinedCategoryDashboards = model.showPredefinedCategoryDashboards;
    this.isDashboardGenerated = model.isDashboardGenerated;
    this.hideGuide = model.hideGuide;
    this.invoiceHandling = model.invoiceHandling;
    this.canAccessNewServices = model.canAccessNUIServices;
    this.canAccessOldGoals = model.canAccessOldGoals;
    this.isGoalsForAgentsEnabled = model.isGoalsForAgentsEnabled;
    this.smartCampaignsEnabled = model.smartCampaignsEnabled;
    this.product_images_in_catalogue = model.product_images_in_catalogue;

    // TODO: to be removed dannyyassine
    this.ez_login_link_expiration_in_hours = model.ez_login_link_expiration_in_hours;

    this.timezone = this.user ? this.user.timezone : null;
    this.timezone_display = this.user ? this.user.timezone_display : null;

    this.instanceTimezone = model.instanceTimezone;
    this.instanceTimezoneDisplay = model.instanceTimezoneDisplay;

    this.ui_timezone_display =
      this.timezone_display && !this.timezone_display.includes('default')
        ? this.timezone_display
        : this.instanceTimezoneDisplay;

    this.ui_timezone =
      this.timezone && this.timezone !== 'default' ? this.timezone : this.instanceTimezone;

    this.resellerBilling = model.resellerBilling;
    this.resellerName = model.resellerName;

    this.nuiLoginPageMessage = model.nuiLoginPageMessage;
    this.customWelcomeMessage = model.customWelcomeMessage;
    this.disableUpdateDashboardButton = model.disableUpdateDashboardButton;
    this.canAccessStellar = model.canAccessStellar;
    this.isTestdriveInstance = model.isTestdriveInstance;
    this.useRecaptcha = model.useRecaptcha;
    this.needsHipaaAcknowledgement = model.needsHipaaAcknowledgement;
    this.sheetsSelfServePlanId = model.sheetsSelfServePlanId;
    this.defaultNavFavorites = model.defaultNavFavorites;
    this.passwordRequirements = model.passwordRequirements;
    this.disableInactiveProductsFiltering = model.disableInactiveProductsFiltering;
    this.overrideLoginPageFooterText = model.overrideLoginPageFooterText;
    this.isCurrentUserPredefinedSuperAdmin = model.isCurrentUserPredefinedSuperAdmin;
    this.isTwoStepVerificationEnabledForUser = model.isTwoStepVerificationEnabledForUser;
    this.hasAccessToAudience2 = model.hasAccessToAudience2;
    this.useTwoStepRecaptcha = model.useTwoStepRecaptcha;
    this.customerInstanceURL = model.customerInstanceURL;
    this.isMarketplaceCentralizedInstance = model.isMarketplaceCentralizedInstance;
    this.plannerLaunchURL = model.plannerLaunchURL;
    this.is_order_management_mode_enabled = model.is_order_management_mode_enabled;
    this.use_places_api_for_company_name = model.use_places_api_for_company_name;
    this.whitelabelClusterId = model.whitelabelClusterId;
    this.whitelabelClusterIdsByServerName = model.whitelabelClusterIdsByServerName;
  }

  /**
   * @param moduleName
   * @param permissionKey
   * @returns {*}
   */
  getPermission(moduleName, permissionKey = RolePermissionKey.CAN_READ_WRITE) {
    if (!this.role.role_permissions[moduleName]) {
      throw new Error(`Module: '${moduleName}' does not exist`);
    }
    return this.role.role_permissions[moduleName][permissionKey];
  }

  /**
   * @param moduleName
   * @param permissionKey
   * @returns {boolean}
   */
  hasPermission(moduleName, permissionKey = RolePermissionKey.CAN_READ_WRITE) {
    return this.role && this.getPermission(moduleName, permissionKey) === true;
  }

  hasAppPermissions(permissionKeys) {
    return !!permissionKeys.some((permissionKey) => this.hasPermission(Module.APP, permissionKey));
  }

  /**
   * @param moduleName
   * @param permissionKey
   * @returns {boolean}
   */
  hasPermissionToRead(moduleName, permissionKey = RolePermissionKey.CAN_READ_WRITE) {
    const permission = this.getPermission(moduleName, permissionKey);

    return permission === RolePermissionValue.READ_ONLY || permission === RolePermissionValue.ALL;
  }

  /**
   * @param moduleName
   * @param permissionKey
   * @returns {boolean}
   */
  hasPermissionToWrite(moduleName, permissionKey = RolePermissionKey.CAN_READ_WRITE) {
    const permission = this.getPermission(moduleName, permissionKey);

    return permission === RolePermissionValue.ALL;
  }

  /**
   * @returns {boolean}
   */
  isSuperAdmin() {
    return this.type === UserType.SUPERADMIN;
  }

  /**
   * @returns {boolean}
   */
  isClusterAdmin() {
    return this.type === UserType.CLUSTERADMIN;
  }

  /**
   * @returns {boolean}
   */
  isInstanceSuspended() {
    return (
      this.instanceStatus === InstanceStatus.SUSPENDED_UNPAID ||
      this.instanceStatus === InstanceStatus.SUSPENDED_INACTIVE
    );
  }

  /**
   * @returns {boolean}
   */
  isAdmin() {
    return this.type === UserType.SUPERADMIN || this.type === UserType.CLUSTERADMIN;
  }

  /**
   * @returns {boolean}
   */
  isAgent() {
    return this.type === UserType.AGENT;
  }

  /**
   * @returns {boolean}
   */
  isClient() {
    return this.type === UserType.CLIENT;
  }

  /**
   * @param {string} featureName
   * @returns {boolean}
   */
  isFeatureAvailable(featureName) {
    return includes(this.availableFeatures, featureName);
  }

  /**
   * @param {string} moduleName
   * @returns {boolean}
   */
  isModuleAvailable(moduleName) {
    return includes(this.availableModules, moduleName);
  }

  /**
   * Returns whether current theme is a dark or light theme
   * @returns {string}
   */
  getThemeType() {
    return this.themeType === UserThemeType.TYPE_DARK
      ? UserThemeType.TYPE_DARK
      : UserThemeType.TYPE_LIGHT;
  }

  /**
   * @returns {string}
   */
  getDefaultWeeklyStartDay() {
    return this.weekStartsOnMonday ? WeeklyStartDay.MONDAY : WeeklyStartDay.SUNDAY;
  }

  getSupportLink() {
    if (this.isSuperAdmin() && !this.hideSuperAdminSupportLink) {
      return SupportLinks.SUPERADMIN_SUPPORT_LINK;
    }
    if ((this.isSuperAdmin() && this.hideSuperAdminSupportLink) || this.isAgent()) {
      return this.agencySupportLink;
    }
    if (this.isClusterAdmin()) {
      return this.clusterAdminSupportLink;
    }
    if (this.isClient()) {
      return this.clientSupportLink;
    }
    return '';
  }

  /**
   * @returns {number}
   */
  getInCompletedOnboardingSteps() {
    return ONBOARDING_STEPS - this.completedOnboardingSteps.length;
  }

  /**
   * Update Onboarding steps with the given step. Returns true if the update was performed or false otherwise
   *
   * @param step
   * @returns {boolean}
   */
  updateOnboardingSteps(step) {
    if (this.isOnboarding && !this.completedOnboardingSteps.includes(step)) {
      this.completedOnboardingSteps.push(step);
      return true;
    }
    return false;
  }

  /**
   * @returns {boolean}
   */
  canCreateUsersBelowOnly() {
    return (
      this.hasPermissionToWrite(Module.USER) &&
      this.role.role_permissions.user.can_create_modify_users ===
        RestrictUserTypeRolePermissionValue.BELOW
    );
  }

  /**
   * @returns {boolean}
   */
  canCreateUsersAtAndBelow() {
    return (
      this.hasPermissionToWrite(Module.USER) &&
      this.role.role_permissions.user.can_create_modify_users ===
        RestrictUserTypeRolePermissionValue.AT_AND_BELOW
    );
  }

  /**
   * @returns {boolean}
   */
  canImpersonateUserBelowOnly() {
    return (
      this.role.role_permissions.user.can_impersonate_users ===
      RestrictUserTypeRolePermissionValue.BELOW
    );
  }

  /**
   * @returns {boolean}
   */
  canImpersonateUserAtAndBelow() {
    return (
      this.role.role_permissions.user.can_impersonate_users ===
      RestrictUserTypeRolePermissionValue.AT_AND_BELOW
    );
  }

  /**
   * Returns the landing page state. Number's are converted to a /dash/ path.
   * @returns {string}
   */
  getLandingPageState() {
    if (Number.isNaN(Number(this.landingPageState))) {
      return `/${this.landingPageState}`;
    }
    return `/${RouteGroup.DASH}/${this.landingPageState}`;
  }

  /**
   *
   * @returns {boolean}
   */
  isGcpBilling() {
    return this.invoiceHandling === InvoiceHandling.GCP;
  }

  /**
   *
   * @param value
   */
  setNuiServices(value) {
    this.canAccessNewServices = value;
  }

  /**
   *
   * @param value
   */
  setSupGoals(value) {
    this.user.use_sup_goals = value;
  }

  /**
   *
   * @returns {boolean}
   */
  isFeaturePackageStandard() {
    return this.featurePackageName === FeaturePackage.STANDARD;
  }
}
