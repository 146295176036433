import { Label, p50, color, Rectangle } from '@amcharts/amcharts5';
import { Constant, YAxisPosition } from '@/modules/core/charts/am5/charts.constants';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { useXYTooltip } from '@/modules/core/charts/am5/base/composables/useXYTooltip';

export function useAxesLabels(context) {
  const { root, chart, config } = context();
  const { getNewDefaultTooltip } = useXYTooltip(context);

  function createAxesLabels() {
    if (config.value.isNormalizedData) {
      if (config.value.isRotated) {
        createXAxesLabels();
      } else {
        createYAxesLabels();
      }
    }

    createRankingMetricLabels();
  }

  function createRankingMetricLabels() {
    // will already have been done
    if (config.value.isNormalizedData || config.value.isSparkLine) {
      return;
    }

    const axesDirection = config.value.isRotated ? Constant.X_AXES : Constant.Y_AXES;
    chart.value[axesDirection].each((axis) => {
      // add the label if it's a ranking metric OR if we've moved everything onto the same side
      const userData = axis.get(Constant.USER_DATA);
      const isRankingMetric = userData && userData.isRankingMetric;
      const isYAxisOverride = [YAxisPosition.LEFT, YAxisPosition.RIGHT].includes(
        config.value.yAxisOverride
      );
      if (isRankingMetric || isYAxisOverride) {
        axesDirection === Constant.X_AXES ? addLabelToXAxis(axis) : addLabelToYAxis(axis);
      }
    });
  }

  function createXAxesLabels() {
    chart.value.xAxes.each((axis) => {
      addLabelToXAxis(axis);
    });
  }

  function createYAxesLabels() {
    chart.value.yAxes.each((axis) => {
      addLabelToYAxis(axis);
    });
  }

  function addLabelToXAxis(axis) {
    const props = axis.get(Constant.USER_DATA);
    if (!props.showLabels || props.hideAxisText) {
      return;
    }

    const labelProps = omitNils({
      // eslint-disable-next-line tap/no-raw-text-js
      text: `[bold]${props.titleText}`,
      centerX: p50,
      x: p50,
      paddingTop: 0,
      paddingBottom: 0,
      fill: color(props.titleColor),
      tooltipText: props.tooltipText,
    });

    addLabelToAxis(axis, labelProps, props.opposite ? 0 : axis.children.length - 1);
  }

  function addLabelToYAxis(axis) {
    const props = axis.get(Constant.USER_DATA);
    if (!props.showLabels || props.hideAxisText) {
      return;
    }

    const labelProps = omitNils({
      // eslint-disable-next-line tap/no-raw-text-js
      text: `[bold]${props.titleText}`,
      centerX: p50,
      y: p50,
      paddingLeft: 0,
      paddingRight: 0,
      rotation: -90,
      fill: color(props.titleColor),
      tooltipText: props.tooltipText,
    });

    addLabelToAxis(axis, labelProps, props.opposite ? axis.children.length - 1 : 0);
  }

  function addLabelToAxis(axis, labelProps, position) {
    // if the label has tooltip text then display it
    if (labelProps.tooltipText) {
      labelProps = {
        background: Rectangle.new(root.value, {
          fill: color(0x000000),
          fillOpacity: 0,
        }),
        ...labelProps,
      };

      // set the tooltip to either the existing or create a new one
      const axisLabelsTooltip = axis.labelsContainer.get(Constant.TOOLTIP, getNewDefaultTooltip());

      axis.labelsContainer.set(Constant.TOOLTIP, axisLabelsTooltip);

      const axisRenderer = axis.get(Constant.RENDERER);
      axisRenderer.labels.template.setAll({
        tooltipText: '',
        oversizedBehavior: Constant.TRUNCATE,
        maxWidth: 50,
      });
    }

    axis.children.moveValue(Label.new(root.value, labelProps), position);
  }

  /**
   * From a given axis return the text label (which will be a child element)
   * @param axis
   * @returns {*}
   */
  function getTextLabelFromAxis(axis) {
    let label;

    axis.children.each((child) => {
      if (child.constructor.name === Label.name) {
        label = child;
      }
    });

    return label;
  }

  return {
    addLabelToXAxis,
    addLabelToYAxis,
    createAxesLabels,
    getTextLabelFromAxis,
  };
}
