import { BaseEntityModel } from '@/modules/core/app/models/abstracts/BaseEntityModel';

export class DataView extends BaseEntityModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  name;

  /**
   * @var {boolean}
   */
  is_geo;

  /**
   * @var {string}
   */
  extended_id;

  /**
   * @var {string}
   */
  grid_id;

  constructor(model = {}) {
    super(model);

    this.id = model.id;
    this.name = model.name || model.text;
    this.is_geo = model.is_geo;
    this.extended_id = model.extended_id;
    this.grid_id = model.grid_id;
  }
}
